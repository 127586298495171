import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Container, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import RateReviewIcon from '@mui/icons-material/RateReview';
import InterestsIcon from '@mui/icons-material/Interests';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { HeaderToolbar } from '../../components/header/header_toolbar';

// import WarningIcon from '@mui/icons-material/Warning';

// import { MyWarning } from './screen/warnings';
import ViewIncidents from './screen/ViewIncidents';
import ViewComplaints from './screen/ViewComplaints';
import { MyServices } from './screen/my_services';
import ReimbursementList from './screen/ReimbursementList';
import { KilometerClaims } from './screen/kilometer_claim';
import MyOvertimeRequests from './screen/MyOvertimeRequests';
import KilometersClaimList from './screen/KilometersClaimList';
import { MyFeedBack } from './screen/my_feedbacks';
import { ConflictInterest } from './screen/conflict_interest';
import { MyTrainings } from './screen/trainings';
import { LeaveApplication } from './screen/leave_application';
import { MyServiceDashboard } from './screen/my_dashboard_v1';
import { GetStaffWithId } from '../../services/staff_service';

const drawerWidth = 70;

const ServiceDashboard = (props) => {
    const [GetStaffWithIdFunc, GetStaffWithIdLoading, GetStaffWithIdError] = GetStaffWithId();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [staffID, setStaffID] = useState(null);

    const getMyDetails = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const fetchStaffDetails = await GetStaffWithIdFunc({
                variables: {
                    filters: {
                        email: {
                            eq: user?.email
                        }
                    }
                },
            })

            if (fetchStaffDetails?.data.staffDetails.data.length > 0) {
                let staffId = fetchStaffDetails?.data.staffDetails.data[0].id;
                setStaffID(staffId);
            }

        } catch (error) {
            console.error("error")
        }
    }

    useEffect(() => {
        getMyDetails()
    }, [])
    useEffect(() => {
        if (staffID !== null) {
            setOptions([
                {
                    option: "My Services",
                    icon: <VolunteerActivismIcon fontSize={"large"} />,
                    active: true,
                    screen: <MyServiceDashboard />
                },                
                // {
                //     option: "Training / Certification",
                //     icon: <ModelTrainingIcon fontSize={"large"} />,
                //     active: false,
                //     screen: <MyTrainings />
                // },
                // {
                //     option: "My Conflict Of Interest",
                //     icon: <InterestsIcon fontSize={"large"} />,
                //     active: false,
                //     screen: <ConflictInterest />
                // },
                // {
                //     option: "Leave Application",
                //     icon: <DocumentScannerIcon fontSize={"large"} />,
                //     active: false,
                //     screen: <LeaveApplication />
                // },
                {
                    option: "My Feedback",
                    icon: <RateReviewIcon fontSize={"large"} />,
                    active: false,
                    screen: <MyFeedBack />
                },
                {
                    option: "My Incidents",
                    icon: <GppMaybeOutlinedIcon fontSize={"large"} />,
                    active: false,
                    screen: <ViewIncidents staffID={staffID} />,
                },
                {
                    option: "My Complaints",
                    icon: <ThumbDownOffAltIcon fontSize={"large"} />,
                    active: false,
                    screen: <ViewComplaints staffID={staffID} />,
                },
                {
                    option: "My Reimbursements",
                    icon: <DescriptionIcon fontSize={"large"} />,
                    active: false,
                    screen: <ReimbursementList view={"staff"} staffID={staffID}/>
                },
                {
                    option: "My KM's Claimed",
                    icon: <ReceiptIcon fontSize={"large"} />,
                    active: false,
                    screen: <KilometersClaimList staffID={staffID} />
                },
                {
                    option: "My Overtime Requests",
                    icon: <MoreTimeIcon fontSize={"large"} />,
                    active: false,
                    screen: <MyOvertimeRequests staffID={staffID} />
                },
                // {
                //     option: "Warnings",
                //     icon: <WarningIcon fontSize={"large"} />,
                //     active: false,
                //     screen: <MyWarning />
                // },
            ])
        }

    }, [staffID])

    const navigate = useNavigate()
    const { window } = props;


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const drawer = (
        <div style={{ overflow: 'hidden' }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 40, objectFit: 'fitt' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => (
                    <ListItem key={i} disablePadding sx={{ backgroundColor: v.active ? "#013447" : "#ffffff", }}>
                        <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                            <Tooltip title={v.option} placement="right">
                                <ListItemIcon style={{ color: v.active ? "#ffffff" : "#013447", }}>
                                    {v.icon}
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.length > 0 && options.find((e) => e.active)

    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                position="fixed"
                elevation={0}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab.option}
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(97vh)",
                }}
            >
                <Toolbar />
                {!staffID ?
                    <Box sx={{ display: 'flex', marginTop: '50px', justifyContent: 'center', backgroundColor: '#F5F7F6' }}>
                        <Typography color={"text.primary"} variant="h5">
                            Staff not found
                        </Typography>

                    </Box>
                    : activeTab.screen}
            </Box>
        </Box>
    );
}

ServiceDashboard.propTypes = {
    window: PropTypes.func,
};

export default ServiceDashboard;