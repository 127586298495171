import React, { useEffect, useState } from "react";
import { Box, Button, Chip, CircularProgress, Grid, IconButton, Modal, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import ListItems from "../list_items";
import SearchAndFilters from "../search_filters";
import { ClientDetailsWithSteps } from "../../screens/view_details";
import { useDispatch, useSelector } from "react-redux";
import { RegisterClinetsList } from "../pagination_view";
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { ClientDetailList, ClientDetailsRelationlData } from "../../services/client_service";

const ListOfClients = () => {
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F5F7F6',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 3,
        p: 4,
    });
    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4.5
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // const dispatch = useDispatch();
    const [loadingCliets, setLoadingCliets] = useState(false)
    const _filters = useSelector((state) => state._search.filters);
    const _history = useSelector((state) => state._history.client_details_id);
    const [userSelected, setUserSelected] = useState();
    const [clients, loading] = ClientDetailList();
    const [getClientRelationlDataId, loading_data] = ClientDetailsRelationlData()
    const [clientList, setClientList] = useState(null)
    const [listOfClientDetailsByID, setListOfClientDetailsByID] = useState([]);

    useEffect(() => {
        if (loading) {
            setLoadingCliets(true)
        }
    }, [loading, loading_data])

    useEffect(() => {
        if (clients?.length) {
            setClientList(clients)
            getDataByPromise()
        }
    }, [clients]);

    const getDataByPromise = async () => {
        const copy = [...listOfClientDetailsByID];
        try {
            for (const key in clients) {
                const respons = await getSelectedDetailsById(clients[key]?.id);
                copy.push({ ...respons, client_id: clients[key]?.id })
            }
        } catch (error) {
            console.log(error)
        }
        setListOfClientDetailsByID(copy)
        setLoadingCliets(false)
    }

    const getSelectedDetailsById = async (id) => {
        try {
            const respons = await getClientRelationlDataId({
                variables: {
                    "filters": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filter": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filterP": {
                        "client_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            })
            if (respons?.data) {
                const data = respons?.data;
                return data;
            }
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setUserSelected(_history);
    }, [_history]);

    const [list, setList] = useState([
        {
            id: 1,
            name: "Rameez Khan",
            profileImg: "patient-flat-symbol-vector-7895275.jpg",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Male",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: false,
        },
        {
            id: 2,
            name: "Rameez Khan",
            profileImg: "patient1.png",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Female",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 3,
            name: "Rameez Khan",
            profileImg: "patient-flat-symbol-vector-7895275.jpg",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Male",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 4,
            name: "Rameez Khan",
            profileImg: "patient1.png",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Female",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 5,
            name: "Rameez Khan",
            profileImg: "patient-flat-symbol-vector-7895275.jpg",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Male",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 6,
            name: "Rameez Khan",
            profileImg: "patient1.png",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Female",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 7,
            name: "Rameez Khan",
            profileImg: "patient-flat-symbol-vector-7895275.jpg",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Male",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 8,
            name: "Rameez Khan",
            profileImg: "patient1.png",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Female",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },
        {
            id: 9,
            name: "Rameez Khan",
            profileImg: "patient-flat-symbol-vector-7895275.jpg",
            primarydignostic: "Epileptic",
            careNote: "Please note that he has to be monitored on an hourly basis",
            NDIS: 431596754,
            mobileNumber: "036589741",
            gender: "Male",
            typeOfService: "abc",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "client",
            active: true,
        },

    ]);

    const [dataList, setDataList] = useState([]);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [displayMessage, setDisplayMessage] = useState("");
    const [viewExpose, setViewExpose] = useState("grid");

    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    // const [archive, setArchive] = useState(false);

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    useEffect(() => {
        if (_filters?.length) {
            const array = [...dataList];
            for (const key in _filters) {
                const genderData = list.filter((e) => e.gender.toLocaleLowerCase() === _filters[key].toLocaleLowerCase());
                if (genderData) {
                    for (const a in genderData) {
                        array.push(genderData[a]);
                    }
                }
            }
            setDataList(array)
        }
    }, [_filters]);

    const clientData = (id, Name, Gender, Age, IsNdisFunded, IsPackageFunded, DivisionName, PrimaryPhone, SecondaryPhone, PrimaryEmailAddress, State, IsActive, ContactId, ContactCode) => {
        return {
            id,
            Name,
            Gender,
            Age,
            IsNdisFunded,
            IsPackageFunded,
            DivisionName,
            PrimaryPhone,
            SecondaryPhone,
            PrimaryEmailAddress,
            State,
            IsActive,
            ContactId,
            ContactCode
        };
    };

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'Age',
            numeric: true,
            disablePadding: false,
            label: 'Age',
        },
        {
            id: 'NDIS Number',
            numeric: true,
            disablePadding: false,
            label: 'NDIS Number',
        },
        {
            id: 'Medicare Number',
            numeric: true,
            disablePadding: false,
            label: 'Medicare Number',
        },
        {
            id: 'Medicare Expiry Date',
            numeric: true,
            disablePadding: false,
            label: 'Medicare Expiry Date',
        },
        {
            id: 'Email Address',
            numeric: true,
            disablePadding: false,
            label: 'Email Address',
        },
        {
            id: 'Status',
            numeric: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: "Options",
            numeric: false,
            disablePadding: false,
            label: 'Options',
        }
    ];

    const rows = [
        clientData(1, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(2, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(3, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(4, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(5, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(6, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(7, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(8, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(9, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
        clientData(10, "Clark", "Male", 23, "false", "false", "abc", "0365898989", "0365898989", "Acd asd arg", "State", "true", "abc543", "FxTaP"),
    ];

    return (
        <>
            {userSelected ? (
                <ClientDetailsWithSteps />
            ) : (
                <Grid container spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item={true} xs={11} md={11} lg={11.5} sx={{ marginTop: { xs: "13%", lg: "0%" }, display: "flex", flexDirection: { xs: "column", md: "row", lg: "row" } }}>
                        <Grid item={true} xs={12} md={5} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                            <Stack direction="row" spacing={2}>
                                {/* <Button disabled={clientList?.length ? false : true} onClick={() => {
                                    handleOpen();
                                    setDisplayMessage("Sent Bulk Email");
                                }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Bulk Email
                                </Button>
                                <Button disabled={clientList?.length ? false : true} onClick={() => {
                                    handleOpen();
                                    setDisplayMessage("Bulk Messages");
                                }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Bulk Message
                                </Button> */}
                            </Stack>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={7} >
                            {/* <SearchAndFilters topMargin={2} inputWidth={6} userType={"client"} /> */}
                        </Grid>
                        <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center",  marginTop: 5 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={viewExpose}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton disabled={clientList?.length ? false : true} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                                <ToggleButton disabled={clientList?.length ? false : true} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {loadingCliets ? (
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress color="secondary" size={50} />
                        </Box>
                    ) : (
                        <>
                            {viewExpose === "grid" ?
                                <Grid item={true} xs={12} md={12} lg={12}>
                                    <ListItems list={clientList} details={listOfClientDetailsByID} setUserSelected={setUserSelected} />
                                </Grid>
                                :
                                <Grid item={true} xs={12} md={12} lg={11.5}>
                                    <RegisterClinetsList list={clientList} details={listOfClientDetailsByID} headCells={headCells} screenWidth={screenWidth} />
                                </Grid>
                            }
                        </>
                    )}
                </Grid>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            {displayMessage}
                        </Typography>
                    </Box>
                    <Box sx={{ py: 1, my: 2, px: 2, bgcolor: "#fff", boxShadow: "0px 1px 1px 1px lightgrey", borderRadius: 2 }}>
                        <Box sx={{ my: 2, display: "flex" }}>
                            <Box sx={{ width: "5%" }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                    To:
                                </Typography>
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Chip label="All Staff" onDelete={handleDelete} />
                            </Box>
                        </Box>
                        <Box sx={{ my: 2, }}>
                            <TextField
                                multiline={false}
                                type={"text"}
                                label={"Subject"}
                                value={subject}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    setSubject(e.target.value)
                                }}
                            />
                        </Box>
                        <Box sx={{ my: 2, }}>
                            <TextField
                                label="Description"
                                multiline={true}
                                rows={4}
                                sx={{ width: "100%" }}
                                value={description}
                                onClick={(e) => {
                                    setDescription(e.target.value)
                                }}
                            />
                        </Box>
                        <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end", }}>
                            <Stack direction={"row"} spacing={2}>
                                <Button onClick={handleClose} variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                                <Button onClick={handleClose} variant="outlined" sx={{ color: "#000", fontFamily: "Roboto-Medium", textTransform: "capitalize", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default ListOfClients;