import * as Yup from 'yup'

export const FeatureGroupManagementSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
});

export const FeaturClientGroupManagementSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    client_detail_ids: Yup.array()
        .of(Yup.string().required('Each client ID must be a string'))
        .required('Client is required')
        .min(1, 'At least one client is required'),
});
export const AdminManagementSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required').email('Invalid email format'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#^]/, 'Password must contain at least one special character'),
    feature_group_management_id: Yup.string().required('Feature Group Management is required'),
});