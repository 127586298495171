import * as Yup from 'yup';

const organizationValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    registration_number: Yup.string().required('Registration number is required'),
    company_name: Yup.string().required('Organization name is required'),
    phone: Yup.string().required('Phone number is required'),
    website_url: Yup.string().required('Website URL is required'),
    abn: Yup.string().required('ABN is required'),
    invoicing_email: Yup.string().email('Invalid email address').required('Invoicing email is required'),
    organization_type: Yup.string().required('Organization type is required'),
    ndis_registration: Yup.boolean().required('NDIS registration is required')
});

export default organizationValidationSchema;
