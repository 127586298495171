import { Box, Grid, TextField, CircularProgress, Button, Typography, Slide, Alert, AlertTitle, Snackbar, MenuItem } from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Formik } from 'formik';
import React, { useState } from 'react';
import CustomErrorText from '../../../components/custom-error-text';
import { CreateKiloMetersClaim } from '../../../services/finance_service'
import * as Yup from 'yup';
import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/duration'));

const KilometerClaimForm = ({ shiftDate, setChildModalOpen, shiftID, staffId }) => {
    
    const [CreateKiloMetersClaimFunc, loading] = CreateKiloMetersClaim();

    // Snackbar code
    const SlideTransition = (props) => {
        return <Slide {...props} direction="left" />;
    };

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleSnacksAppear = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
            autoHideDuration: 5000
        });

        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        });
    };

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const submitKilometerForm = async (values) => {

        const data = {
            date_of_travel: values.dateOfTravel.format("YYYY-MM-DD"),
            start_time: values.startTime.format("HH:mm:ss"),
            end_time: values.endTime.format("HH:mm:ss"),
            start_location: values.startLocation,
            end_location: values.endLocation,
            total_kilometers: values.totalKilometers,
            purpose_of_travel: values.purposeOfTravel,
            vehicle_used: values.vehicleUsed,
            service_shift_id: shiftID,
            staff_id: staffId,
        };       

        const response = await CreateKiloMetersClaimFunc({
            variables: {
                data: data,
            }
        })
                
        if (response.data.createKilometersClaim.data.id) {
            handleSnacksAppear("Kilometer Claim", "Kilometer claim submitted successfully and sent for review", "success");
            setTimeout(() => {
                setChildModalOpen(false);
            }, 2500);
        }
    };

    const validationSchema = Yup.object().shape({
        dateOfTravel: Yup.date().required('Date of Travel is required'),
        startTime: Yup.date().required('Start Time is required'),
        endTime: Yup.date()
            .required('End Time is required')
            .test('is-after-start', 'End Time must be after Start Time', function (value) {
                const { startTime } = this.parent;
                return dayjs(value).isAfter(dayjs(startTime));
            }),
        startLocation: Yup.string().required('Start Location is required'),
        endLocation: Yup.string().required('End Location is required'),
        totalKilometers: Yup.number()
            .positive('Total Kilometers must be a positive number')
            .required('Total Kilometers is required'),
        purposeOfTravel: Yup.string()
            .min(10, 'Purpose of Travel must be at least 10 characters')
            .required('Purpose of Travel is required'),
        vehicleUsed: Yup.string().required('Vehicle Used is required'),
    });

    return (
        <Box>
            <Formik
                initialValues={{
                    dateOfTravel: dayjs(shiftDate, "YYYY-MM-DD"),
                    startTime: null,
                    endTime: null,
                    startLocation: '',
                    endLocation: '',
                    totalKilometers: '',
                    purposeOfTravel: '',
                    vehicleUsed: '',
                }}
                validationSchema={validationSchema}
                onSubmit={submitKilometerForm}
            >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container sx={{ flexDirection: 'column', gap: 2 }}>
                            <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: 24, textAlign: 'center' }}>
                                Kilometer Claim Form
                            </Typography>

                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                    display: 'flex',
                                    gap: '20px',
                                    flexDirection: 'column',
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date of Travel"
                                        value={values.dateOfTravel}
                                        onChange={(newValue) => setFieldValue('dateOfTravel', newValue)}
                                        disabled={true}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider>
                                {errors.dateOfTravel && <CustomErrorText errorMessage={errors.dateOfTravel} />}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TimePicker
                                                label="Start Time"
                                                value={values.startTime}
                                                onChange={(newValue) => setFieldValue('startTime', newValue)}
                                                sx={{ width: '100%' }}
                                            />
                                            {errors.startTime && <CustomErrorText errorMessage={errors.startTime} />}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TimePicker
                                                label="End Time"
                                                value={values.endTime}
                                                onChange={(newValue) => setFieldValue('endTime', newValue)}
                                                sx={{ width: '100%' }}
                                            />
                                            {errors.endTime && <CustomErrorText errorMessage={errors.endTime} />}
                                        </Grid>
                                    </Grid>
                                </LocalizationProvider>

                                <TextField
                                    label="Start Location"
                                    value={values.startLocation}
                                    onChange={(e) => setFieldValue('startLocation', e.target.value)}
                                    fullWidth
                                />
                                {errors.startLocation && <CustomErrorText errorMessage={errors.startLocation} />}

                                <TextField
                                    label="End Location"
                                    value={values.endLocation}
                                    onChange={(e) => setFieldValue('endLocation', e.target.value)}
                                    fullWidth
                                />
                                {errors.endLocation && <CustomErrorText errorMessage={errors.endLocation} />}

                                <TextField
                                    label="Total Kilometers"
                                    type="number"
                                    value={values.totalKilometers}
                                    onChange={(e) => setFieldValue('totalKilometers', e.target.value)}
                                    fullWidth
                                />
                                {errors.totalKilometers && <CustomErrorText errorMessage={errors.totalKilometers} />}

                                <TextField
                                    label="Purpose of Travel"
                                    multiline
                                    rows={3}
                                    value={values.purposeOfTravel}
                                    onChange={(e) => setFieldValue('purposeOfTravel', e.target.value)}
                                    fullWidth
                                />
                                {errors.purposeOfTravel && <CustomErrorText errorMessage={errors.purposeOfTravel} />}

                                <TextField
                                    select
                                    label="Vehicle Used"
                                    value={values.vehicleUsed}
                                    onChange={(e) => setFieldValue('vehicleUsed', e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="Personal Car">Personal Car</MenuItem>
                                    <MenuItem value="Company Vehicle">Company Vehicle</MenuItem>
                                    <MenuItem value="Public Transport">Public Transport</MenuItem>
                                </TextField>
                                {errors.vehicleUsed && <CustomErrorText errorMessage={errors.vehicleUsed} />}
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        border: "2px solid #6ECA35",
                                        ":hover": {
                                            border: "2px solid #6ECA35",
                                        },
                                    }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    {!loading ? 'Submit Claim' : <CircularProgress color="secondary" size={26} />}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                )}
            </Formik>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default KilometerClaimForm;
