import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from "react";
import { CreateChargeBandDetail, CreateChargeBandRate, NdisPriceListCategory } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';
import { ChargeBandsCreateSchema } from './schema';
import CustomErrorText from '../../../components/custom-error-text';
import dayjs from 'dayjs';

const ChargeBandTypes = [
    {
        label: 'B: Brokerage',
        value: 'B'
    },
    {
        label: 'P: Private',
        value: 'P'
    },
    {
        label: 'N: NDIS',
        value: 'N'
    },
    {
        label: 'A: Aged Care Package',
        value: 'A'
    },
]

const initialValues = {
    charge_band_name: '',
    charge_band_type: '',
    ndis_price_list_id: null,
    charge_band_year: null,
}

const ChargeBandsModal = ({ open, setOpen, ndisPriceListQuery, getChargeBandsRates }) => {
    const [ndisPriceList, setNdisPriceList] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [selectedChargeBandYear, setSelectedChargeBandYear] = useState(null);
    const [ndisPriceListCategories, setNdisPriceListCategories] = useState(null);

    const [createChargeBand, loading] = CreateChargeBandDetail();
    const [createChargeBandRates] = CreateChargeBandRate();
    const [ndis_price_list_category] = NdisPriceListCategory();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {
        const updatedValues = {
            ...values,
            charge_band_year: ndisPriceListCategories.find(val => val.id === values.charge_band_year)?.label
        }

        const [startYear, endYear] = updatedValues.charge_band_year.split('-').map(Number);
        const currentYear = dayjs().year();
        const isCurrentYearInRange = currentYear >= startYear && currentYear <= endYear;

        if (isCurrentYearInRange) {
            const response = await createChargeBand({ variables: updatedValues });

            if (response?.data) {
                const fixedRateValue = response.data.createChargeBandDetail.data.attributes.ndis_price_list_id.data.attributes.WA ?? 0;

                const result = await createChargeBandRates({
                    variables: {
                        'charge_band_detail_id': response.data.createChargeBandDetail.data.id,
                        'day_rate': fixedRateValue,
                        'evening_rate': fixedRateValue,
                        'night_rate': fixedRateValue,
                        'saturday_rate': fixedRateValue,
                        'sunday_rate': fixedRateValue,
                        'public_holiday_rate': fixedRateValue
                    }
                })
                if (result) {
                    setSnackOpen(true);
                    resetForm();
                    await getChargeBandsRates();
                    setOpen(false)
                }
            }
        }
        else {
            alert('Please select charge band of current year')
        }
    }

    const getNdisPriceListCategory = async () => {
        try {
            const response = await ndis_price_list_category();
            if (response.data) {
                const filteredCategoryList = response.data.ndisPriceListCategories.data.map(val => {
                    return (
                        {
                            label: val.attributes.Ndis_price_list_category,
                            id: val.id
                        }
                    )
                });
                setNdisPriceListCategories(filteredCategoryList)
            }
        } catch (error) {
            console.error("ndis_price_list_category", error)
        }
    }

    const getNDISPriceList = async () => {
        const response = await ndisPriceListQuery(selectedChargeBandYear && {
            variables: {
                "filter": {
                    "ndis_price_list_category_id": {
                        "id": {
                            "eq": selectedChargeBandYear
                        }
                    }
                },
            }
        });
        if (response) {
            const filteredNdisPriceList = response?.data.ndisPriceLists.data.map(val => {
                return ({
                    title: `${val.attributes.support_item_number}-${val.attributes.support_item_name}`,
                    value: val.id
                })
            });
            setNdisPriceList(filteredNdisPriceList)
        }
    }

    useEffect(() => {
        getNdisPriceListCategory();
        getNDISPriceList();
    }, [selectedChargeBandYear])

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message="Created Successfully"
                title="Success"
                severity="success"
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Modal
                aria-labelledby="charge-bands-modal-title"
                aria-describedby="charge-bands-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={ChargeBandsCreateSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                    children={({ errors, getFieldProps, setFieldValue, values }) => (
                        <Form>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: '#F5F7F6',
                                maxWidth: "calc(70%)",
                                minWidth: "calc(60%)",
                                boxShadow: 12,
                                borderRadius: 3,
                                p: 4,
                            }}>
                                <Box>
                                    <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                                        Create New Charge Band
                                    </Typography>
                                </Box>
                                <Box sx={{ py: 2, }}>
                                    <Grid sx={{ mb: 3 }}>
                                        <TextField
                                            id="charge-band-name"
                                            label="Charge Band Name"
                                            variant="outlined"
                                            placeholder='Enter the charge band name'
                                            fullWidth
                                            {...getFieldProps('charge_band_name')}
                                            error={errors?.charge_band_name ? true : false}
                                        />
                                        {errors?.charge_band_name && (
                                            <CustomErrorText errorMessage={errors?.charge_band_name} />
                                        )}
                                    </Grid>
                                    <Grid sx={{ mb: 3 }}>
                                        <FormControl fullWidth >
                                            <InputLabel id="charge-band-type">Charge Band Type</InputLabel>
                                            <Select
                                                labelId="charge-band-type"
                                                id="demo-simple-select"
                                                value={values.charge_band_type}
                                                label="Charge Band Type"
                                                onChange={(event) => setFieldValue("charge_band_type", event.target.value)}
                                            >
                                                {ChargeBandTypes.map(list => {
                                                    return (
                                                        <MenuItem value={list.value}>{list.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        {errors?.charge_band_type && (
                                            <CustomErrorText errorMessage={errors?.charge_band_type} />
                                        )}
                                    </Grid>
                                    <Grid sx={{ mb: 3 }}>
                                        <FormControl fullWidth>
                                            <InputLabel>{"Charge Band Year"}</InputLabel>
                                            <Select
                                                value={values.charge_band_year}
                                                label={"Charge Band Year"}
                                                onChange={(e) => {
                                                    setSelectedChargeBandYear(e.target.value);
                                                    setFieldValue("charge_band_year", e.target.value)
                                                }}
                                            >
                                                {
                                                    ndisPriceListCategories.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                        {errors?.charge_band_year && (
                                            <CustomErrorText errorMessage={errors?.charge_band_year} />
                                        )}
                                    </Grid>
                                    {selectedChargeBandYear && (
                                        <Grid>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="support-item-name-autocomplete"
                                                    options={ndisPriceList}
                                                    getOptionLabel={(option) => option.title}
                                                    value={values.id}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue("ndis_price_list_id", newValue ? newValue.value : '');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Support Item Name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            {errors?.ndis_price_list_id && (
                                                <CustomErrorText errorMessage={errors?.ndis_price_list_id} />
                                            )}
                                        </Grid>
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": {
                                                    border: "2px solid #6ECA35",
                                                }
                                            }}
                                            type='submit'
                                        >
                                            {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                        </Button>
                                        <Button
                                            onClick={() => setOpen(false)}
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35", }
                                            }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )}
                />
            </Modal>
        </>
    )
}

export default ChargeBandsModal
