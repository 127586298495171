import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomSnackBar from '../../../../components/custom_snack_bar'
import CustomErrorText from '../../../../components/custom-error-text'
import { CreateNewOrganization } from '../../../../services/admin_service';
import organizationValidationSchema from './schema';
import { CircularProgress } from '@mui/material';

const OrganizationTypes = [
    {
        label: 'Private',
        value: 'Private'
    },
    {
        label: 'Public',
        value: 'Public'
    },
    {
        label: 'Non-Profit',
        value: 'Non-Profit'
    }
]

const initialValues = {
    email: '',
    registration_number: '',
    company_name: '',
    phone: '',
    website_url: '',
    abn: '',
    invoicing_email: '',
    organization_type: '',
    ndis_registration: false
}

const CreateOrganizationModal = ({ modal, setModal }) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');

    const [create_organization, loading, error] = CreateNewOrganization();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {
      
        try {
            const result = await create_organization({
                variables: values
            });
            if (result) {
                setSnackSeverity('success')
                setSnackMessage('Organization created successfully!')
                setSnackOpen(true);
                resetForm();
                setModal(false);
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
    }

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={organizationValidationSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                    children={({ errors, getFieldProps, setFieldValue, values }) => (
                        <Form>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                maxWidth: "calc(70%)",
                                maxHeight: "calc(90%)",
                                minWidth: "calc(60%)",
                                boxShadow: 12,
                                borderRadius: 1,
                                p: 4,
                                overflowY: "auto"
                            }}>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize", mb: 2 }}>
                                        Create Organization
                                    </Typography>
                                </Box>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="registration_number"
                                        label="Registration Number"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('registration_number')}
                                        error={errors?.registration_number ? true : false}
                                    />
                                    {errors?.registration_number && (
                                        <CustomErrorText errorMessage={errors?.registration_number} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="company_name"
                                        label="Organization Name"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('company_name')}
                                        error={errors?.company_name ? true : false}
                                    />
                                    {errors?.company_name && (
                                        <CustomErrorText errorMessage={errors?.company_name} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <FormControl fullWidth >
                                        <InputLabel id="organization_type">Organization Type</InputLabel>
                                        <Select
                                            labelId="organization_type"
                                            id="organization-select"
                                            label="Organization Type"
                                            value={values.organization_type}
                                            onChange={(event) => setFieldValue("organization_type", event.target.value)}
                                        >
                                            {OrganizationTypes.map(list => {
                                                return (
                                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {errors?.organization_type && (
                                        <CustomErrorText errorMessage={errors?.organization_type} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('email')}
                                        error={errors?.email ? true : false}
                                    />
                                    {errors?.email && (
                                        <CustomErrorText errorMessage={errors?.email} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="phone"
                                        label="Phone"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('phone')}
                                        error={errors?.phone ? true : false}
                                    />
                                    {errors?.phone && (
                                        <CustomErrorText errorMessage={errors?.phone} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="website_url"
                                        label="Website URL"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('website_url')}
                                        error={errors?.website_url ? true : false}
                                    />
                                    {errors?.website_url && (
                                        <CustomErrorText errorMessage={errors?.website_url} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="abn"
                                        label="ABN/ACN Number (Australian Business/Company Number)"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('abn')}
                                        error={errors?.abn ? true : false}
                                    />
                                    {errors?.abn && (
                                        <CustomErrorText errorMessage={errors?.abn} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <FormControl fullWidth >
                                        <InputLabel id="ndis_registration">NDIS Registered</InputLabel>
                                        <Select
                                            labelId="ndis_registration"
                                            id="ndis-registration-select"
                                            label="NDIS Registered"
                                            value={values.ndis_registration}
                                            onChange={(event) => setFieldValue("ndis_registration", event.target.value)}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {errors?.ndis_registration && (
                                        <CustomErrorText errorMessage={errors?.ndis_registration} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="invoicing_email"
                                        label="Invoicing Email"
                                        variant="outlined"
                                        fullWidth
                                        {...getFieldProps('invoicing_email')}
                                        error={errors?.invoicing_email ? true : false}
                                    />
                                    {errors?.invoicing_email && (
                                        <CustomErrorText errorMessage={errors?.invoicing_email} />
                                    )}
                                </Grid>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": {
                                                    border: "2px solid #6ECA35",
                                                }
                                            }}
                                            type='submit'
                                        >
                                            {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                        </Button>
                                        <Button
                                            onClick={() => setModal(false)}
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35", }
                                            }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )}
                />
            </Modal>
        </>
    )
}

export default CreateOrganizationModal
