import { Box, CircularProgress, FormControlLabel, MenuItem, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assign_staff_worker } from "../../../middleware/reducers/service_request";
import { GetStaffDetailsWithId } from "../../../services/staff_service";
import { GetStaffList, WorkingDaysByFilters } from "../../../services/schedule_service";
import { ServicePlansFilter } from "../../../services/finance_service";
import _ from "lodash";

export const StaffPreference = ({ screenWidth }) => {
    const dispatch = useDispatch()
    const _information = useSelector((state) => state._service_request.information);
    const _visit_days = useSelector((state) => state._service_request.visitDaysList);
    const _assign_staff_worker = useSelector((state) => state._service_request.assignStaffWorker);
    const [description, setDescription] = useState("");
    const [toggleToVisibaleAllStaff, setToggleToVisibaleAllStaff] = useState(true);
    const [getPlansFiltersById, loading, errors] = ServicePlansFilter();
    const [getWorkingDaysByShiftPref, loading_w] = WorkingDaysByFilters()
    const [getStaffLists, loading_staff] = GetStaffList();
    const [getStaffDetailsByID, loading_details] = GetStaffDetailsWithId()
    // const [staffList, loading_staff] = StaffDetailsList();
    const [modifiedStaffData, setModifiedStaffData] = useState(null)
    const [preferenceStaffData, setPreferenceStaffData] = useState([])
    const [loadingData, setLoadingData] = useState(false)

    useEffect(() => {
        if (loading || loading_w || loading_staff || loading_details) {
            setLoadingData(true)
        }
    }, [loading, loading_w, loading_staff, loading_details])

    const [selectStaff, setSelectStaff] = useState({
        title: "Available Staff Workers For the Service",
        value: {
            title: "Please Select Staff",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Staff",
                id: "D1"
            }
        ]
    })

    const [selectPreferenceStaff, setSelectPreferenceStaff] = useState({
        title: "Available Staff Workers For the Service",
        value: {
            title: "Please Select Preference Staff",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Preference Staff",
                id: "D1"
            }
        ]
    })

    const handleLovsDataById = async (staffList) => {
        try {
            const staffData = _.cloneDeep(staffList)
            let count = 0;
            // const response_list = await getPlansFiltersById()
            // if (response_list?.data) {
            // const list = response_list?.data?.servicePlans?.data;
            // const unmatchedStaff = staffData.filter(staffMember =>
            //     !list.some(plan => plan.attributes.staff_id.data?.id === staffMember.id)
            // );
            for (const key in staffData) {
                const copy = { ...staffData[key] }
                const respons = await getStaffDetailsByID({
                    variables: {
                        "filter": {
                            "staff_detail_id": {
                                "id": {
                                    "eq": staffData[key]?.id
                                }
                            }
                        },
                        "filters": {
                            "staff_detail_id": {
                                "id": {
                                    "eq": staffData[key]?.id
                                }
                            }
                        }
                    }
                })
                if (respons?.data) {
                    copy.attributes.lovs_answers = respons?.data?.staffAnswerLovsReferences?.data;
                    const respons_work = await getWorkingDaysByShiftPref({
                        variables: {
                            "filter": {
                                "staff_detail_id": {
                                    "id": {
                                        "eq": staffData[key]?.id
                                    }
                                }
                            }
                        }
                    })
                    if (respons_work?.data) {
                        copy.attributes.working_days = respons_work.data.workingDays.data;
                    }
                    setModifiedStaffData(staffData);
                    count++
                }

                // }
            }
            if (staffData.length === count) {
                setLoadingData(false);
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const handleStaffsData = async () => {
        try {
            const respons_staff = await getStaffLists();
            if (respons_staff?.data) {
                const staffList = respons_staff?.data?.staffDetails?.data;
                handleLovsDataById(staffList)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        handleStaffsData()
    }, [])

    useEffect(() => {
        if (modifiedStaffData?.length) {
            const copy = { ...selectStaff }
            for (const key in modifiedStaffData) {
                const data = modifiedStaffData[key]?.attributes
                const find = copy.options.find((e) => e?.id === modifiedStaffData[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.first_name + " " + data?.last_name,
                        id: modifiedStaffData[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setSelectStaff(copy)
        }
    }, [modifiedStaffData])

    const timeToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return hours * 60 + minutes;
    }

    const isSlotWithinExactRange = (startTime, endTime, slotStartTime, slotEndTime) => {
        const start = timeToMinutes(startTime);
        const end = timeToMinutes(endTime);
        const slotStart = timeToMinutes(slotStartTime);
        const slotEnd = timeToMinutes(slotEndTime);
        // Check if slot is fully within the time range
        return slotStart >= start && slotEnd <= end;
    }

    const handlePreferanceStaffWorker = async () => {
        const copy = []
        const clientPreferencesForStaff = _information?.preference;

        try {
            for (const key in modifiedStaffData) {
                const age = await modifiedStaffData[key].attributes?.age;
                const gender = await modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "gender")?.attributes?.selected_option_value;
                const language_spoken = await modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "language spoken")?.attributes?.selected_option_value;
                const religion = await modifiedStaffData[key].attributes.lovs_answers?.find((e) => e.attributes.input_name.toLowerCase() === "religion")?.attributes?.selected_option_value;

                for (const index in clientPreferencesForStaff) {
                    if (age && gender && language_spoken && religion && clientPreferencesForStaff[index]?.attributes?.age === Number(age) && clientPreferencesForStaff[index]?.attributes?.gender === gender && clientPreferencesForStaff[index]?.attributes?.language_spoken === language_spoken && clientPreferencesForStaff[index]?.attributes?.religion === religion) {
                        const days = modifiedStaffData[key].attributes?.working_days;

                        const matchedClientActualDates = days?.find(days =>
                            _visit_days?.some(plan =>
                                plan?.recurring_type.includes("Actual Dates") && plan?.selected_days.some(day =>
                                    day.weekDay === days.attributes.select_day && (
                                        day.time_slots.map(timeSlot => (
                                            isSlotWithinExactRange(
                                                days.attributes.day_start_time.slice(0, 8),
                                                days.attributes.day_end_time.slice(0, 8),
                                                timeSlot.startTime, timeSlot.endTime
                                            )
                                        ))
                                    )
                                )
                            )
                        );

                        const matchedClientWeeklyRecurring = days?.find(days =>
                            _visit_days?.some(plan =>
                                plan?.recurring_type.includes("Weekly Recurring") && plan?.week_days.some(day =>
                                    day.day === days.attributes.select_day &&
                                    isSlotWithinExactRange(
                                        days.attributes.day_start_time.slice(0, 8),
                                        days.attributes.day_end_time.slice(0, 8),
                                        day.start_time, day.end_time
                                    )
                                )
                            )
                        );

                        const matchedClientXDays = days?.find(days =>
                            _visit_days?.some(plan =>
                                plan?.recurring_type.includes("X Days") && plan?.x_days.some(day =>
                                    day.dayOfWeek === days.attributes.select_day &&
                                    isSlotWithinExactRange(
                                        days.attributes.day_start_time.slice(0, 8),
                                        days.attributes.day_end_time.slice(0, 8),
                                        day.startTime, day.endTime
                                    )
                                )
                            )
                        );

                        if (matchedClientActualDates || matchedClientWeeklyRecurring || matchedClientXDays) {
                            const find = copy.find((e) => e?.id === modifiedStaffData[key]?.id)
                            if (!find) {
                                copy.push(modifiedStaffData[key])
                            }
                        }
                    }
                }
            }
            setPreferenceStaffData(copy)
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (preferenceStaffData) {
            const copy = { ...selectPreferenceStaff }
            for (const key in preferenceStaffData) {
                const data = preferenceStaffData[key]?.attributes
                const find = copy.options.find((e) => e?.id === preferenceStaffData[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.first_name + " " + data?.last_name,
                        id: preferenceStaffData[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setSelectPreferenceStaff(copy)
        }
    }, [preferenceStaffData])

    useEffect(() => {
        if (!toggleToVisibaleAllStaff && selectStaff.value.id !== "D1") {
            const copy = {
                title: selectStaff.value.title,
                id: selectStaff.value.id,
                description: description
            }
            dispatch(assign_staff_worker(copy))
        }
        if (toggleToVisibaleAllStaff && selectPreferenceStaff.value.id !== "D1") {
            const copy = {
                title: selectPreferenceStaff.value.title,
                id: selectPreferenceStaff.value.id,
                description: description
            }
            dispatch(assign_staff_worker(copy))
        }
    }, [description, selectStaff, selectPreferenceStaff])


    useEffect(() => {
        if (toggleToVisibaleAllStaff) {
            handlePreferanceStaffWorker()
        }
    }, [toggleToVisibaleAllStaff])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* handle toggle to visibale all staff otherwise preferences */}
            <Box sx={{ width: "100%", py: 1 }}>
                <FormControlLabel
                    sx={{ px: 1 }}
                    control={
                        <Switch checked={toggleToVisibaleAllStaff} onChange={(e) => setToggleToVisibaleAllStaff(e.target.checked)} name="all staff" />
                    }
                    label={"Display Preference Staff Worker"}
                />
            </Box>
            {/* render available staff list */}
            {loadingData ? (
                <Box sx={{
                    background: "rgba(255,255,255,0.5)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="secondary" size={45} />
                </Box>
            ) : (
                <Box sx={{ width: "100%", py: 1 }}>
                    {!toggleToVisibaleAllStaff && (<TextField
                        select={true}
                        label={selectStaff.title}
                        sx={{ m: 1, width: screenWidth, background: "#fff", }}
                        value={selectStaff.value.title}
                        onChange={(e) => {
                            const copy = { ...selectStaff };
                            const find = copy?.options?.find((f) => f.title === e.target.value)
                            if (find.id !== "D1") {
                                copy.value = find;
                                setSelectStaff(copy);
                            }
                        }}
                    >
                        {selectStaff.options?.map((value) => (
                            <MenuItem key={value?.title} value={value?.title}>
                                <Typography textAlign="left">{value?.title}</Typography>
                            </MenuItem>
                        ))}
                    </TextField>)}

                    {toggleToVisibaleAllStaff && (<TextField
                        select={true}
                        label={selectPreferenceStaff.title}
                        sx={{ m: 1, width: screenWidth, background: "#fff", }}
                        value={selectPreferenceStaff.value.title}
                        onChange={(e) => {
                            const copy = { ...selectPreferenceStaff };
                            const find = copy?.options?.find((f) => f.title === e.target.value)
                            if (find.id !== "D1") {
                                copy.value = find;
                                setSelectPreferenceStaff(copy);
                            }
                        }}
                    >
                        {selectPreferenceStaff.options?.map((value) => (
                            <MenuItem key={value?.title} value={value?.title}>
                                <Typography textAlign="left">{value?.title}</Typography>
                            </MenuItem>
                        ))}
                    </TextField>)}
                </Box>
            )}
        </Box>
    )
}
