import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GetAllClientGroupManagement, UpdateClientGroupStatus } from '../../services/admin_service';
import { Slide, Switch } from '@mui/material';
import dayjs from 'dayjs';
import CustomSnackBar from '../../components/custom_snack_bar';

export default function ClientGroupManagementList() {
    const [rows, setRows] = React.useState([]);
    const [snackOpen, setSnackOpen] = React.useState(false);

    const [ClientGroupManagementData, loading_users] = GetAllClientGroupManagement();
    const [updateClientGroupStatus, statusLoading] = UpdateClientGroupStatus();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'name',
            headerName: 'Group Name',
            width: 200,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 200,
            editable: false,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 200,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Enabled / Disabled',
            width: 200,
            editable: false,
            renderCell: (params) => {
                const handleToggle = () => {
                    updateRowStatus(params.row.id, !params.row.active);
                };

                return (
                    <Switch
                        checked={params.row.active}
                        disabled={statusLoading}
                        onChange={handleToggle}
                    />
                );
            },
        }
    ];

    const updateRowStatus = async (rowId, newStatus) => {
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, active: newStatus } : row
            )
        );
        setSnackOpen(true);
        const response = await updateClientGroupStatus({
            variables: {
                id: Number(rowId),
                data: {
                    active: newStatus,
                },
            },
        });

        if (response) {
            setTimeout(() => { setSnackOpen(false) }, 3000);
        }
    };

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    React.useEffect(() => {
        if (ClientGroupManagementData?.clientGroupManagements?.data) {
            const formattedData = ClientGroupManagementData.clientGroupManagements.data.map(val => ({
                id: val.id,
                name: val.attributes.name,
                description: val.attributes.description,
                created_at: dayjs(val.attributes.createdAt).format('YYYY-MM-DD'),
                active: val.attributes.active
            }));
            setRows(formattedData);
        }
    }, [ClientGroupManagementData]);

    return (
        <Box sx={{ width: '100%' }}>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={"Updated Successfully!"}
                title={'success'}
                severity={'success'}
                variant="filled"
                autoHideDuration={8000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
            />
        </Box>
    );
}
