import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, CloseIcon } from './Icons.js'
import { red } from '@mui/material/colors';


const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xl: '50vw', md: '75vw' },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NotificationModal({ content, openModalVariable, setOpenModalVariable, style }) {
  return (
    <div>
      <Modal
        open={openModalVariable}
        onClose={() => {
          // setOpenModalVariable(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, ...style }}>
          <IconButton
            sx={{
              alignSelf: 'center',
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={() => {
              setOpenModalVariable(false)
            }}
          >
            <CloseIcon sx={{ color: red[500] }} />
          </IconButton>
          {content}
        </Box>
      </Modal>
    </div>
  );
}