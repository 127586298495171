import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GetAllUserDetails, UpdateClientDetail, UpdateStaffDetail } from '../../services/admin_service';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, Slide } from '@mui/material';
import CustomSnackBar from '../../components/custom_snack_bar';
import { useDispatch } from 'react-redux';
import { clientEditId, staffEditId } from '../../middleware/reducers/history_reducers';
import { useNavigate } from 'react-router-dom';

export default function UserManagementList() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [rows, setRows] = React.useState([]);    
    const [snackOpen, setSnackOpen] = React.useState(false);

    const [UserManagementData, loading_users] = GetAllUserDetails();
    const [apiUpdateStaffDetails, loading_staff, error_staff] = UpdateStaffDetail();
    const [apiUpdateClientDetail, loading_client, error_client] = UpdateClientDetail();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'first_name',
            headerName: 'First name',
            width: 200,
            editable: false,
        },
        {
            field: 'last_name',
            headerName: 'Last name',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'user_type',
            headerName: 'Role',
            width: 200,
            editable: false,
        },
        {
            field: 'approved_status',
            headerName: 'Approved Status',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return (
                    <Chip
                        label={params.row.approved_status ? "Approved" : "Not Approved"}
                        color={params.row.approved_status ? "success" : "error"}
                        variant="outlined"
                    />
                )
            }
        },
        {
            field: 'options',
            headerName: 'Options',
            width: 110,
            editable: false,
            renderCell: (params) => <OptionsMenu row={params.row} />
        },
    ];

    function OptionsMenu({ row }) {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleAction = async (row) => {
            if (row?.user_type?.toLowerCase() === "staff") {
                const response = await apiUpdateStaffDetails({
                    variables: {
                        id: row.id,
                        data: {
                            approved_status: !row.approved_status
                        }
                    }
                });

                if (response) {
                    setSnackOpen(true);
                    setTimeout(() => { setSnackOpen(false) }, 3000);
                }
            }
            if (row?.user_type?.toLowerCase() === "client") {
                const response = await apiUpdateClientDetail({
                    variables: {
                        id: row.id,
                        data: {
                            approved_status: !row.approved_status
                        }
                    }
                });

                if (response) {
                    setSnackOpen(true);
                    setTimeout(() => { setSnackOpen(false) }, 3000);
                }
            }
        };

        const handleEdit = (row) => {
            dispatch(row?.user_type === "Staff" ? staffEditId(row?.id) : clientEditId(row?.id))
            navigate(row?.user_type === "Staff" ? '/staff-dashboard' : '/client-dashboard', {
                state: { activeOption: 1 }
            });
        }

        return (
            <>
                <IconButton
                    aria-label="options"
                    aria-controls={`menu-${row.id}`}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id={`menu-${row.id}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleEdit(row)}> Edit </MenuItem>
                    <MenuItem onClick={() => handleAction(row)}>{!row.approved_status ? 'Approve' : ' Cancel Approval'}</MenuItem>
                </Menu>
            </>
        );
    }

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    React.useEffect(() => {
        if (UserManagementData) {
            const array = [];
            const staff = UserManagementData.staffDetails.data;
            const client = UserManagementData.clientDetails.data;
            const contact = UserManagementData.contactDetails.data;
            for (const key in staff) {
                const data = staff[key].attributes;
                array.push({
                    id: staff[key]?.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    user_type: data.user_type_id,
                    approved_status: data.approved_status
                });
            }
            for (const key in client) {
                const data = client[key].attributes;
                const contactInfo = contact?.find((e) => e?.attributes?.client_detail_id?.data?.id === client[key]?.id);
                array.push({
                    id: client[key]?.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: contactInfo?.attributes?.email,
                    user_type: data.user_type_id,
                    approved_status: data.approved_status
                });
            }
            setRows(array);
        }
    }, [UserManagementData]);

    return (
        <Box sx={{ width: '100%' }}>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={"Updated Successfully!"}
                title={'success'}
                severity={'success'}
                variant="filled"
                autoHideDuration={8000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
            />
        </Box>
    );
}
