import * as React from 'react';
// import styled from '@emotion/styled';
import { Paper, Avatar, Typography, Grid, Stack, Button, IconButton, MenuItem, Menu, Switch, Checkbox, styled } from '@mui/material';
// import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
// import MailRoundedIcon from '@mui/icons-material/MailRounded';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
// import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
// import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
// import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { clientDetailsId, staffDetailsId } from '../../middleware/reducers/history_reducers';
import { useSelector } from 'react-redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { IMAGE } from '../../config/constant';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15.75,
    height: 15.75,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ListItems = ({ list, details }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const _filters = useSelector((state) => state._search.filters);

  const open = Boolean(anchorEl);

  const handleClickz = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const activeTag = _filters.includes("Active")
  const inactiveTag = _filters.includes("Inactive")
  return (
    <Grid container sx={{ justifyContent: { xs: "center", md: "center", lg: "center" }, marginBottom: { xs: "20%", sm: "2%", lg: "2%" }, }}  >
      {list?.map((data, i) => {
        const v = data?.attributes;
        let find, fGender;
        if (v?.user_type_id === "Client") {
          find = details?.find((e) => e?.client_id === data?.id)
          const genderC = find?.clientAnswerLovsReferences?.data.find((e) => e?.attributes?.input_name?.toLowerCase() === "gender");
          if (genderC) {
            fGender = genderC?.attributes?.selected_option_value;
          }
        }
        if (v?.user_type_id === "Staff") {
          find = details?.find((e) => e?.staff_id === data?.id)
          const genderS = find?.staffAnswerLovsReferences?.data.find((e) => e?.attributes?.input_name?.toLowerCase() === "gender");
          if (genderS) {
            fGender = genderS?.attributes?.selected_option_value;
          }
        }
        if ((activeTag && v?.active) || (inactiveTag && !v?.active) || (!activeTag && !inactiveTag && v?.active)) {
          return (
            <Grid item={true} key={v?.first_name + i} xs={10} md={3.2} lg={window.screen.width > 1600 ? 2.2 : 2.8} sx={{ bgcolor: "#fff", overflow: "hidden", m: 1, p: 1.5, flexDirection: 'row', boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.14)", borderRadius: 5 }}>
              <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                <Grid item={true} xs={2} sm={2} lg={2}>
                  {/* <Checkbox aria-label='Select for action' color='secondary' sx={{ color: "#3A89CC" }} /> */}
                </Grid>
                <Grid item={true} xs={8} sm={8} lg={8} >
                  <Grid item={true} xs={12} sm={12} lg={12} sx={{ my: 2, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar sx={{ width: 70, height: 70, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={v.first_name} src={`${IMAGE}${v?.profile_picture?.data?.attributes?.url}`} />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} lg={12} >
                    <Grid item={true} xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: "1.2em", fontFamily: "Roboto-Regular" }}>
                        {v?.first_name}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sx={{ mt: -0.5, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                      <Typography color={"secondary"} sx={{ fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                        {v?.user_type_id} Id: {data?.id}
                      </Typography>
                    </Grid>
                    {/* <Grid item={true} xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                      <IOSSwitch checked={v?.active} />
                      {v?.active ? (
                        <Typography color={"primary"} sx={{ fontSize: "0.9em", fontFamily: "Roboto-Regular" }}>
                          Active
                        </Typography>
                      ) : (
                        <Typography color={"secondary.text3"} sx={{ fontSize: "0.9em", fontFamily: "Roboto-Regular" }}>
                          Inactive
                        </Typography>
                      )}
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item={true} xs={2} sm={2} lg={2}>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickz}
                  >
                    <MoreVertIcon color="primary" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    elevation={1}
                  >
                    <MenuItem onClick={() => {
                      navigate("/reports-dashboard", {
                        state: {
                          activeOption: 0
                        }
                      })
                    }}>Incident</MenuItem>
                    <MenuItem onClick={() => {
                      navigate("/reports-dashboard", {
                        state: {
                          activeOption: 1
                        }
                      })
                    }}>Complaint</MenuItem>
                    {/* <MenuItem onClick={() => {
                    // navigate("/admin-dashboard", {
                    //   state: {
                    //     activeOption: 1
                    //   }
                    // })
                  }}>Service History</MenuItem>
                  <MenuItem onClick={() => {
                    // navigate("/admin-dashboard", {
                    //   state: {
                    //     activeOption: 1
                    //   }
                    // })
                  }}>Service Schedule</MenuItem> */}
                    {v?.user_type_id.toLowerCase() === "client" && (
                      <MenuItem onClick={() => {
                        navigate("/admin-dashboard", {
                          state: {
                            activeOption: 1
                          }
                        })
                      }}>Service Request</MenuItem>
                    )}
                    {v?.user_type_id.toLowerCase() === "staff" && (
                      <MenuItem onClick={() => {
                      }}>Staff Warning</MenuItem>
                    )}
                    {v?.user_type_id.toLowerCase() === "staff" && (
                      <MenuItem onClick={() => {
                      }}>Leave Application</MenuItem>
                    )}
                  </Menu>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sx={{ py: 1, display: "flex", justifyContent: 'center', flexWrap: "wrap" }}>
                <Stack direction={'row'} spacing={1}>
                  <Button onClick={() => {
                    // setUserSelected(v?.id)
                    if (v?.user_type_id?.toLowerCase() === "client") {
                      dispatch(clientDetailsId(data?.id))
                    }
                    if (v?.user_type_id?.toLowerCase() === "staff") {
                      dispatch(staffDetailsId(data?.id))
                    }
                  }} variant="outlined" sx={{ fontSize: "0.7em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Details
                  </Button>
                  <Button onClick={() => {
                    // navigate("/admin-dashboard", {
                    //   state: {
                    //     activeOption: 1
                    //   }
                    // })
                  }} variant="outlined" sx={{ fontSize: "0.7em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Send Message
                  </Button>
                  <Button onClick={() => {
                    // navigate("/admin-dashboard", {
                    //   state: {
                    //     activeOption: 1
                    //   }
                    // })
                  }} variant="outlined" sx={{ fontSize: "0.7em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Send Email
                  </Button>
                </Stack>
              </Grid>
              <Grid item={true} xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', m: 0.5 }}>
                <Grid item={true} xs={6} sm={6} lg={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                  <Grid item={true} xs={4} sm={4} lg={2} sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', }}>
                    <BusinessOutlinedIcon color="secondary" />
                  </Grid>
                  <Grid item={true} xs={8} sm={8} lg={10}>
                    <Typography sx={{ fontWeight: 500, fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                      {find?.contactDetails?.data[0]?.attributes?.address || "Not Defined"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', m: 0.5 }}>
                <Grid item={true} xs={6} sm={6} lg={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                  <Grid item={true} xs={4} sm={4} lg={2} sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', }}>
                    <EmailOutlinedIcon color="secondary" />
                  </Grid>
                  <Grid item={true} xs={8} sm={8} lg={10}>
                    <Typography sx={{ fontWeight: 500, fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                      {find?.contactDetails?.data[0]?.attributes?.email || v?.email_info?.slice(0, 20) || "Not Defined"}...
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', m: 0.5 }}>
                <Grid item={true} xs={6} sm={6} lg={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                  <Grid item={true} xs={4} sm={4} lg={2} sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', }}>
                    {fGender?.toLowerCase() === "male" ? <ManIcon color="secondary" /> : <WomanIcon color="secondary" />}
                  </Grid>
                  <Grid item={true} xs={8} sm={8} lg={10}>
                    <Typography sx={{ fontWeight: 500, fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                      {fGender}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', m: 0.5 }}>
                {v?.user_type_id === "Client" ? (
                  <Grid item={true} xs={6} sm={6} lg={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                    <Grid item={true} xs={4} sm={4} lg={2} sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', }}>
                      <MonitorHeartOutlinedIcon color="secondary" />
                    </Grid>
                    <Grid item={true} xs={8} sm={8} lg={10}>
                      <Typography sx={{ fontWeight: 500, fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                        {v?.primary_diagnosis || "Not Provided"}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item={true} xs={12} sm={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', m: 0.5 }}>
                {v?.type_of_services ? (
                  <Grid item={true} xs={6} sm={6} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                    <Grid item={true} xs={4} sm={4}>
                      <MedicalServicesOutlinedIcon color="secondary" />
                    </Grid>
                    <Grid item={true} xs={8} sm={8}>
                      <Typography sx={{ fontWeight: 500, fontSize: "1em", fontFamily: "Roboto-Regular" }}>
                        {v?.type_of_services}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )
        }
      })}
    </Grid>
  );
}

export default ListItems;