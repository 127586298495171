import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GetAllSubAdmins, UpdateSubAdminStatus } from '../../services/admin_service';
import { Slide, Switch } from '@mui/material';
import CustomSnackBar from '../../components/custom_snack_bar';

export default function SubAdminList() {
    const [rows, setRows] = React.useState([]);
    const [snackOpen, setSnackOpen] = React.useState(false);

    const [getsubAdminsData] = GetAllSubAdmins();
    const [updateSubAdminStatus, statusLoading] = UpdateSubAdminStatus();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'first_name',
            headerName: 'First Name',
            width: 200,
            editable: false,
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'feature_group',
            headerName: 'Feature Group',
            width: 200,
            editable: false,
        },
        {
            field: 'client_group',
            headerName: 'Client Group',
            width: 200,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Enabled / Disabled',
            width: 200,
            editable: false,
            renderCell: (params) => {
                const handleToggle = () => {
                    updateRowStatus(params.row.id, !params.row.status);
                };

                return (
                    <Switch
                        checked={params.row.status}
                        disabled={statusLoading}
                        onChange={handleToggle}
                    />
                );
            },
        }
    ];

    const updateRowStatus = async (rowId, newStatus) => {
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, status: newStatus } : row
            )
        );
        setSnackOpen(true);
        const response = await updateSubAdminStatus({
            variables: {
                id: Number(rowId),
                data: {
                    status: newStatus,
                },
            },
        });

        if (response) {
            setTimeout(() => { setSnackOpen(false) }, 3000);
        }
    };

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const getSubAdmins = async () => {
        const response = await getsubAdminsData({
            variables: {
                "filters": {
                    "user_type": {
                        "id": {
                            "eq": 4
                        }
                    }
                },
            }
        });

        if (response) {
            setRows(response.data?.usersPermissionsUsers?.data?.map(val => {
                return (
                    {
                        id: val.id,
                        first_name: val.attributes.first_name,
                        last_name: val.attributes.last_name,
                        email: val.attributes.email,
                        feature_group: val.attributes.feature_group_management_id.data?.attributes.name,
                        client_group: val.attributes?.client_group_management_id.data?.attributes.name ?? '-',
                        status: val.attributes.status,
                    }
                )
            }))
        }
    }

    React.useEffect(() => {
        getSubAdmins();
    }, [])

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={"Updated Successfully!"}
                title={'success'}
                severity={'success'}
                variant="filled"
                autoHideDuration={8000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                />
            </Box>
        </>
    );
}
