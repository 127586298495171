import { AppBar, Avatar, Badge, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ForumIcon from '@mui/icons-material/Forum';
import { selectedServiceReducer } from '../../middleware/reducers/my_services';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth_service";
import { useDispatch, useSelector } from "react-redux";

export const HeaderWithLogo = () => {
    return (
        <div className="header-with-logo">
            <div className="logo-continer">
                <img className="logo" src="/assets/icons/main-logo.png" />
            </div>
        </div>
    )
}

const pages = ['Home', 'Staff', 'Scheduling', 'Reports', 'Admin'];
// const settings = ['Profile', 'Logout'];
const settings = ['Logout'];

export const ResponsiveAppBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const _userInfo = useSelector((state) => state._authentication.auth_user)
    const { hash, pathname, search } = useLocation()
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElTime, setAnchorElTime] = useState(null);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [userType, setUserType] = useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleOpenTimeMenu = (event) => {
        setAnchorElTime(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseTimeMenu = () => {
        setAnchorElTime(null);
    };

    const urlName = pathname.replace(/^\//, '');
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const sunRise = new Date().getHours() > 12 ? "PM" : "AM";

    React.useEffect(() => {
        if (_userInfo) {
            setUserType(_userInfo?.attributes?.user_type?.data?.attributes?.user_type);
        }
    }, [_userInfo])

    return (
        <Box sx={{ flexGrow: 1, }}>
            <AppBar position="static" elevation={0} color="primaryHeader">
                <Container maxWidth="xxl" >
                    <Toolbar disableGutters>
                        {/* <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: 230, height: 55, }} variant="square" alt="Logo" src="/assets/icons/logo-landscap.png" /> */}
                        <img style={{ width: 230, height: 55, }} alt="Logo" src="/assets/icons/logo-landscap.png" />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <Avatar sx={{
                                display: { xs: 'flex', md: 'none' },
                                mr: 1,
                                width: 37,
                                height: 42
                            }} variant="square" alt="Logo" src="/assets/icons/favico_ white.png" />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                            {/* <Typography variant="h5" component="div" sx={{ fontFamily: "Roboto-Medium" }}>
                                Cenna Supports
                            </Typography> */}
                            {/* {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: page?.toLocaleLowerCase() === urlName ? "#8dcc56" : 'white', display: 'block' }}
                                > */}
                            {/* <IconButton
                                        size="smal"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <HomeIcon />
                                    </IconButton> */}
                            {/* {page}
                                </Button>
                            ))} */}
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                            {userType?.toLowerCase() === "staff" && (
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-haspopup="true"
                                    // onClick={handleOpenTimeMenu}
                                    onClick={() => {
                                        // navigate to the service page
                                        navigate('/service-dashboard');
                                        // dispatch active visit day
                                        dispatch(selectedServiceReducer({ objId: 0, serv_code: "ABC123" }))
                                    }}
                                >
                                    <Badge badgeContent={"1"} color="success">
                                        <EventRepeatIcon color="secondary" />
                                    </Badge>
                                </IconButton>
                            )}
                            {/* <IconButton size="large" aria-label="show 3 new aerts" color="inherit">
                                <Badge badgeContent={3} color="success">
                                    <ForumIcon color="secondary" />
                                </Badge>
                            </IconButton>
                            <IconButton size="large" aria-label="show 4 new aerts" color="inherit">
                                <Badge badgeContent={4} color="success">
                                    <NotificationsActiveIcon color="secondary" />
                                </Badge>
                            </IconButton> */}
                            <IconButton
                                size="large"
                                edge="end"
                                // aria-label="account of current user"
                                // aria-controls={menuId}
                                // aria-haspopup="true"
                                onClick={handleOpenUserMenu}
                            >
                                <Avatar sx={{ fontFamily: "Roboto-Medium", fontSize: "1em", backgroundColor: "#6ECA35" }} >
                                    {user?.username.slice(0, 1)}
                                </Avatar>
                            </IconButton>
                        </Box>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => {
                                    if (setting === "Logout") {
                                        AuthService.logout();
                                        navigate('/');
                                    }
                                    if (setting === "Profile") {
                                        navigate('/my-profile');
                                    }
                                }}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        <Menu
                            sx={{ mt: '45px', }}
                            id="menu-time"
                            anchorEl={anchorElTime}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElTime)}
                            onClose={handleCloseTimeMenu}
                        >
                            <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium" }}>
                                    {hours < 10 ? "0" + hours : hours} {" : "} {minutes < 10 ? "0" + minutes : minutes} {sunRise}
                                </Typography>
                            </Box>
                            <Box sx={{ px: 2 }}>
                                <Button variant="contained">
                                    End Time
                                </Button>
                            </Box>
                        </Menu>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: "flex-end" } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {settings.map((page) => (
                                    <MenuItem selected={page === 'Home'} key={page} onClick={() => navigate("/")}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}