import { Box, Button, Chip, Grid, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { OrganizationList } from '../../../../services/admin_service';
import CreateOrganizationModal from './create-organization-modal';

export default function OrganizationTable() {
    const [rows, setRows] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const [organizations, loading_list] = OrganizationList();

    const handleOpen = () => setOpen(true);

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'registration_number',
            headerName: 'Registration Number',
            width: 200,
            editable: false,
        },
        {
            field: 'organization_name',
            headerName: 'Organization Name',
            width: 200,
            editable: false,
        },
        {
            field: 'organization_type',
            headerName: 'Organization Type',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 200,
            editable: false,
        },
        {
            field: 'website_url',
            headerName: 'Website URL',
            width: 200,
            editable: false,
        },
        {
            field: 'acn_number',
            headerName: 'ABN/ACN Number (Australian Business/Company Number)',
            width: 200,
            editable: false,
        },
        {
            field: 'ndis_registered',
            headerName: 'NDIS Registered',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return (
                    <Chip
                        label={params?.row?.ndis_registered ? 'Yes' : 'No'}
                        color={params?.row?.ndis_registered ? 'success' : 'warning'}
                    />
                );
            },
        },
        {
            field: 'invoicing_email',
            headerName: 'Invoicing Email',
            width: 200,
            editable: false,
        },
    ];

    React.useEffect(() => {
        if (organizations) {
            setRows(
                organizations?.map(val => {
                    const organizationData = val?.attributes;

                    return (
                        {
                            id: val.id,
                            registration_number: organizationData.registration_number,
                            organization_name: organizationData.company_name,
                            organization_type: organizationData?.organization_type,
                            email: organizationData.email,
                            phone: organizationData.phone,
                            website_url: organizationData.website_url,
                            acn_number: organizationData.abn,
                            ndis_registered: organizationData?.ndis_registration,
                            invoicing_email: organizationData?.invoicing_email
                        }
                    )
                })
            )
        }
    }, [organizations])

    return (
        <Grid container sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Grid item={true} xs={11.5} sm={12} md={11} lg={11} sx={{ display: "flex", justifyContent: "flex-end", }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleOpen} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                        Create Organization
                    </Button>
                </Stack>
            </Grid>
            <Grid item={true} xs={11.5} sm={12} md={11} lg={11} sx={{ my: 1, }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                />
            </Grid>
            <CreateOrganizationModal modal={open} setModal={setOpen} />
        </Grid>
    );
}
