import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack,
    Slide,
    CircularProgress,
    Snackbar,
    Alert,
    AlertTitle,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from 'react-redux';
import { roundToNearestQuarter, createString, getCurrentWeekNumber } from '../../../components/functions';
import { Save } from '@mui/icons-material';
import { CreateInvoicing } from '../../../services/finance_service';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { CustomModalPDFFile } from '../../../components/pdf_view/pdf_view';
import dayjs from 'dayjs';
import weekOfYear from "dayjs/plugin/weekOfYear";

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.label?.length >= 10 ? 180 : 100, fontFamily: "Roboto-Medium" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, saveInvoicing, data, loading, setOpenPdf, fileName } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction="row" spacing={2} sx={{
                    width: "100%",
                    justifyContent: "flex-end"
                }}>
                    <Tooltip title="Save to proceed for payment">
                        <Button onClick={() => saveInvoicing()} startIcon={<Save color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Save {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Export to SCV">
                        <Button startIcon={<DownloadIcon color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            <CSVLink
                                data={data}
                                target="_blank"
                                filename={fileName}
                                style={{ color: "#000", textDecoration: "none", fontFamily: "Roboto-Medium" }}
                            >
                                Export to CSV
                            </CSVLink>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Export to PDF">
                        <Button onClick={() => setOpenPdf(true)} startIcon={<DownloadIcon color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            PDF View
                        </Button>
                    </Tooltip>
                    {/* <Tooltip title="Email">
                        <Button startIcon={<EmailIcon color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Email
                        </Button>
                    </Tooltip> */}
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const OrganizationClaim = ({ organizationFunded }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [visibleRows, setVisibleRows] = React.useState([]);

    const _kilometers_claim_list = useSelector((state) => state._payment_processing_reducer.kilometers_claim_list);
    const _list_of_reimbursment = useSelector((state) => state._payment_processing_reducer.reimbursement_list);
    const _process_state = useSelector((state) => state._payment_processing_reducer.process_state);
    const [finalInvoice, setFinalInvoice] = React.useState(null)
    const [createNewInvoice, loading, error_i] = CreateInvoicing();
    const [openPdf, setOpenPdf] = React.useState(false);

    dayjs.extend(weekOfYear);

    const handleModal = () => {
        setOpenPdf(false);
    }

    const headCells = [
        {
            id: 'ID',
            numeric: false,
            disablePadding: true,
            label: 'ID',
        },
        {
            id: 'Organization Name',
            numeric: true,
            disablePadding: false,
            label: 'Organization Name',
        },
        {
            id: 'Registration Number',
            numeric: true,
            disablePadding: false,
            label: 'Registration Number',
        },
        {
            id: 'NDIS Number',
            numeric: true,
            disablePadding: false,
            label: 'NDIS Number',
        },
        {
            id: 'Claim Reference',
            numeric: true,
            disablePadding: false,
            label: 'Claim Reference',
        },
        {
            id: 'Total Price',
            numeric: true,
            disablePadding: false,
            label: 'Total Price',
        },
        {
            id: 'Actions',
            numeric: true,
            disablePadding: false,
            label: 'Actions',
        },
    ];

    React.useEffect(() => {
        if (organizationFunded) {
            const array = []
            let numberOfApprovedKM, pay_travel_km, totalDaysAmount;

            for (const key in organizationFunded) {
                let fObject = {
                    id: Number(key) + 1,
                    reimbursement: 0,
                    pay_travel_km: 0,
                }

                const totalAmounts = [];

                for (const data in organizationFunded[key]) {
                    const eachObj = organizationFunded[key][data];
                    const chargeBandRates = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.charge_band_rate?.data?.attributes;

                    totalDaysAmount =
                        (eachObj?.attributes?.day_time_hours * chargeBandRates?.day_rate) +
                        (eachObj?.attributes?.evening_hours * chargeBandRates?.evening_rate) +
                        (eachObj?.attributes?.saturday_hours * chargeBandRates?.saturday_rate) +
                        (eachObj?.attributes?.holiday_hours * chargeBandRates?.public_holiday_rate) +
                        (eachObj?.attributes?.night_hours * chargeBandRates?.night_rate) +
                        (eachObj?.attributes?.sunday_hours * chargeBandRates?.sunday_rate);

                    const fName = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.first_name;
                    const lName = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.last_name;
                    const ndis = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.ndis_number;
                    const support_num = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_number;
                    const client_id = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.id;
                    const service_request_id = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id;

                    const service_rate = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_rate_id?.data?.attributes?.service_rate;
                    const company_name = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.company_id?.data?.attributes?.company_name;
                    const company_id = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.company_id?.data?.id;
                    const registration_number = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.company_id?.data?.attributes?.registration_number;
                    const numberOfApprovedRM = _list_of_reimbursment?.find((value) => value.attributes.approved_status && value.attributes.service_shift_id.data.id === organizationFunded[key][data].id);

                    pay_travel_km = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes.pay_travel_km;
                    numberOfApprovedKM = _kilometers_claim_list?.filter((value) => value.attributes.service_shift_id.data.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id === client_id);

                    // // // createing object
                    fObject.today = moment().format("DD-MM-YYYY");
                    fObject.shift_id = eachObj.id;
                    fObject.registration_Number = registration_number;
                    fObject.organization_name = company_name;
                    fObject.participant_name = fName + " " + lName;
                    fObject.ndis_Number = ndis;
                    fObject.supports_delivered_from = _process_state.start_date;
                    fObject.supports_delivered_to = _process_state.end_date;
                    fObject.support_number = support_num;
                    fObject.claim_referance = fObject.claim_referance = `${lName.slice(0, 3).toUpperCase()}${fName.slice(0, 2).toUpperCase()}${dayjs().format("YY")}${dayjs().week()}${ndis.slice(-3)}`;
                    fObject.quantity = organizationFunded[key].length;
                    fObject.hours = fObject.hours ? Number(fObject.hours) + Number(eachObj?.attributes?.total_shift_hours) : eachObj?.attributes?.total_shift_hours;
                    fObject.unit_price = service_rate;
                    fObject.gst_code = "nill";
                    fObject.authorised_by = "nill";
                    fObject.participant_approved = "nill";
                    fObject.in_kind_funding_program = "nill";
                    fObject.client_id = client_id;
                    fObject.service_id = service_request_id;
                    fObject.company_id = company_id;

                    if (numberOfApprovedRM) {
                        fObject.reimbursement = fObject.reimbursement + numberOfApprovedRM.attributes.amount;
                    }
                    totalAmounts.push(totalDaysAmount + fObject.pay_travel_km + fObject.reimbursement)
                }
                if (numberOfApprovedKM && pay_travel_km === "Yes") {
                    for (const kmKey in numberOfApprovedKM) {
                        const total_km = numberOfApprovedKM[kmKey].attributes.total_kilometers;
                        if (!isNaN(total_km) && total_km % 1 === 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.pay_travel_km += Number(total_km);
                        }
                        if (!isNaN(total_km) && total_km % 1 !== 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.pay_travel_km += roundToNearestQuarter(Number(total_km))
                        }
                    }
                }
                fObject.total_price = totalAmounts.reduce((sum, num) => sum + num, 0);
                array.push(fObject)
            }
            setFinalInvoice(array)
        }
    }, [organizationFunded]);

    const [snacks, setSnack] = React.useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = React.useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = finalInvoice?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - finalInvoice?.length) : 0;

    React.useEffect(() => {
        // Sort and slice the data whenever the dependencies change
        const sortedData = stableSort(finalInvoice, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        // Update the visible rows state
        setVisibleRows(sortedData);
    }, [order, orderBy, page, rowsPerPage, finalInvoice]);

    const selectedData = () => {
        const selectedItems = finalInvoice?.filter((e) =>
            selected.some((id) => id === e.id)
        );
        return selectedItems
    }

    const saveInvoicing = async (id) => {
        try {
            const items = visibleRows.filter(val=> val.id === id);
            let count = 0;
            for (const data of items) {
                // console.log("data", data)
                const object = {
                    claim_reference: data.claim_referance,
                    quantity: String(data.quantity),
                    hours: String(data.hours),
                    unit_price: String(data.unit_price),
                    total_price: String(data.total_price),
                    gst_code: data.gst_code,
                    authorised_by: data.authorised_by,
                    total_kilometers_claim: String(data.pay_travel_km),
                    total_invoice_amount: String(data.total_price),
                    // registration_number: data.registration_number,
                    client_detail_id: data.client_id,
                    service_request_id: data.service_id,
                    invoicing_start_date: data.supports_delivered_from,
                    invoicing_end_date: data.supports_delivered_to,
                    invoice_genreated_reference: data.claim_referance + data.supports_delivered_to + data.service_id + data.client_id,
                    company_id: data.company_id,
                    // csv_generated: data.,
                    // pdf_generated: data.,
                }
                // console.log("object", object)
                const response = await createNewInvoice({
                    variables: object
                })
                if (response?.data) {
                    count++
                }
            }
            if (count === items.length) {
                handleSnacksApper("Organzation Funded Invoice", "Invoice has been saved.", "success")
            }
        } catch (error) {
            let message = "";
            if (error_i || error) {
                if (error_i?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (error_i?.graphQLErrors) {
                    // console.log("error_i", error_i.graphQLErrors)
                    message = error_i.graphQLErrors[0]?.extensions.code === "INTERNAL_SERVER_ERROR" ? "The invoice already saved or required data is missing.!" : error_i.graphQLErrors[0]?.message;
                }
                if (error?.message) {
                    message = "The invoice already saved or required data is missing.!";
                }
                handleSnacksApper("Organzation Funded Invoice", message, "error")
            }
        }
    }

    return (
        <Box sx={{ mx: 2, width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }}>
            {selected.length > 0 && (
                <EnhancedTableToolbar
                    setOpenPdf={setOpenPdf}
                    numSelected={selected.length}
                    loading={loading}
                    fileName={"cennasupports" + _process_state.end_date}
                    saveInvoicing={saveInvoicing}
                    data={selectedData()}
                />
            )}
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={finalInvoice?.length}
                        headCells={headCells}
                    />
                    <TableBody>
                        {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    // hover
                                    // onClick={(event) => handleClick(event, row.id)}
                                    // role="checkbox"
                                    // aria-checked={isItemSelected}
                                    // tabIndex={-1}
                                    key={row.participant_name + row.id}
                                    // selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {/* <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </TableCell> */}
                                    <TableCell component="th" id={labelId} scope="row">{index + 1}</TableCell>
                                    <TableCell align="left">{row.organization_name}</TableCell>
                                    <TableCell align="left">{row.registration_Number}</TableCell>
                                    <TableCell align="left">{row.ndis_Number}</TableCell>
                                    <TableCell align="left">{row.claim_referance}</TableCell>
                                    <TableCell align="left">${row.total_price}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            onClick={(event) => {
                                                setSelectedRowData(row)
                                                setOpenPdf(true)
                                            }}
                                            startIcon={<DownloadIcon color='primary' />}
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                                fontSize: "1.1em",
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35" }
                                            }}
                                        >
                                            PDF View
                                        </Button>
                                        <Button onClick={() => saveInvoicing(row?.id)} startIcon={<Save color='primary' />} color="primary" variant="outlined" sx={{ marginLeft: '10px', fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                            Save {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={finalInvoice?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
            {/* display invoice */}
            <CustomModalPDFFile
                openPdf={openPdf}
                handleModal={handleModal}
                selectedData={selectedRowData}
                shifts={organizationFunded}
            />
        </Box>
    );
}

export const SelfClaim = ({ selfFunding }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [visibleRows, setVisibleRows] = React.useState([]);
    const [selectedRowData, setSelectedRowData] = React.useState(null);

    const _kilometers_claim_list = useSelector((state) => state._payment_processing_reducer.kilometers_claim_list);
    const _list_of_reimbursment = useSelector((state) => state._payment_processing_reducer.reimbursement_list);
    const _process_state = useSelector((state) => state._payment_processing_reducer.process_state);
    const [finalInvoice, setFinalInvoice] = React.useState(null)
    const [createNewInvoice, loading, error_i] = CreateInvoicing();
    const [openPdf, setOpenPdf] = React.useState(false);

    dayjs.extend(weekOfYear);

    const handleModal = () => {
        setOpenPdf(false);
    }

    const headCellsSelf = [
        {
            id: 'ID',
            numeric: false,
            disablePadding: true,
            label: 'ID',
        },
        {
            id: 'Participant Name',
            numeric: true,
            disablePadding: false,
            label: 'Participant Name',
        },
        {
            id: 'NDIS Number',
            numeric: true,
            disablePadding: false,
            label: 'NDIS Number',
        },
        {
            id: 'Claim Reference',
            numeric: true,
            disablePadding: false,
            label: 'Claim Reference',
        },
        {
            id: 'Total Price',
            numeric: true,
            disablePadding: false,
            label: 'Total Price',
        },
        {
            id: 'Actions',
            numeric: true,
            disablePadding: false,
            label: 'Actions',
        },
    ];

    React.useEffect(() => {
        if (selfFunding) {
            const array = []
            let numberOfApprovedKM, pay_travel_km, totalDaysAmount;

            for (const key in selfFunding) {

                let fObject = {
                    id: Number(key) + 1,
                    reimbursement: 0,
                    pay_travel_km: 0,
                }

                const totalAmounts = [];

                for (const data in selfFunding[key]) {

                    const eachObj = selfFunding[key][data];
                    const chargeBandRates = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.charge_band_rate?.data?.attributes;

                    totalDaysAmount =
                        (eachObj?.attributes?.day_time_hours * chargeBandRates?.day_rate) +
                        (eachObj?.attributes?.evening_hours * chargeBandRates?.evening_rate) +
                        (eachObj?.attributes?.saturday_hours * chargeBandRates?.saturday_rate) +
                        (eachObj?.attributes?.holiday_hours * chargeBandRates?.public_holiday_rate) +
                        (eachObj?.attributes?.night_hours * chargeBandRates?.night_rate) +
                        (eachObj?.attributes?.sunday_hours * chargeBandRates?.sunday_rate);

                    const fName = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.first_name;
                    const lName = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.last_name;
                    const ndis = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.ndis_number;
                    const support_num = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_number;
                    const client_id = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.id;
                    const service_request_id = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id;
                    pay_travel_km = eachObj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes.pay_travel_km;
                    numberOfApprovedKM = _kilometers_claim_list?.filter((value) => value.attributes.service_shift_id.data.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id === client_id);
                    const numberOfApprovedRM = _list_of_reimbursment?.find((value) => value.attributes.approved_status && value.attributes.service_shift_id.data.id === selfFunding[key][data].id);
                    // // // creating object
                    fObject.today = moment().format("DD-MM-YYYY");
                    fObject.shift_id = eachObj.id;
                    fObject.participant_name = fName + " " + lName;
                    fObject.ndis_Number = ndis;
                    fObject.supports_delivered_from = _process_state.start_date;
                    fObject.supports_delivered_to = _process_state.end_date;
                    fObject.support_number = support_num;

                    fObject.quantity = selfFunding[key].length;
                    fObject.hours = fObject.hours ? Number(fObject.hours) + Number(eachObj?.attributes?.total_shift_hours) : eachObj?.attributes?.total_shift_hours;
                    fObject.claim_referance = `${lName.slice(0, 3).toUpperCase()}${fName.slice(0, 2).toUpperCase()}${dayjs().format("YY")}${dayjs().week()}${ndis.slice(-3)}`;
                    fObject.gst_code = "nill";
                    fObject.authorised_by = "nill";
                    fObject.participant_approved = "nill";
                    fObject.in_kind_funding_program = "nill";
                    fObject.client_id = client_id;
                    fObject.service_id = service_request_id;
                    if (numberOfApprovedRM) {
                        fObject.reimbursement = fObject.reimbursement + numberOfApprovedRM.attributes.amount;
                    }
                    totalAmounts.push(totalDaysAmount + fObject.pay_travel_km + fObject.reimbursement)

                }
                if (numberOfApprovedKM && pay_travel_km === "Yes") {
                    for (const kmKey in numberOfApprovedKM) {
                        const total_km = numberOfApprovedKM[kmKey].attributes.total_kilometers;
                        if (!isNaN(total_km) && total_km % 1 === 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.pay_travel_km += Number(total_km);
                        }
                        if (!isNaN(total_km) && total_km % 1 !== 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.pay_travel_km += roundToNearestQuarter(Number(total_km))
                        }
                    }
                }

                fObject.total_price = totalAmounts.reduce((sum, num) => sum + num, 0);
                array.push(fObject)
            }
            setFinalInvoice(array)
        }
    }, [selfFunding])

    const [snacks, setSnack] = React.useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = React.useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = finalInvoice?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelectedRowData(newSelected)
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - finalInvoice?.length) : 0;

    React.useEffect(() => {
        // Sort and slice the data whenever the dependencies change
        const sortedData = stableSort(finalInvoice, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        // Update the visible rows state
        setVisibleRows(sortedData);
    }, [order, orderBy, page, rowsPerPage, finalInvoice]);

    const selectedData = () => {
        const selectedItems = finalInvoice?.filter((e) =>
            selected.some((id) => id === e.id)
        );
        return selectedItems
    }

    const saveInvoicing = async (id) => {

        try {
            const items = visibleRows.filter(val=> val.id === id);
            let count = 0;
            for (const data of items) {
                const object = {
                    claim_reference: data.claim_referance,
                    quantity: String(data.quantity),
                    hours: String(data.hours),
                    unit_price: String(data.unit_price),
                    total_price: String(data.total_price),
                    gst_code: data.gst_code,
                    authorised_by: data.authorised_by,
                    total_kilometers_claim: String(data.pay_travel_km),
                    total_invoice_amount: String(data.total_price),
                    client_detail_id: data.client_id,
                    service_request_id: data.service_id,
                    invoicing_start_date: data.supports_delivered_from,
                    invoicing_end_date: data.supports_delivered_to,
                    invoice_genreated_reference: data.claim_referance + data.supports_delivered_to + data.service_id + data.client_id,
                }

                const response = await createNewInvoice({
                    variables: object
                })
                if (response?.data) {
                    count++
                }
            }
            if (count === items.length) {
                handleSnacksApper("Self Funded Invoice", "Invoice has been saved.", "success")
            }
        } catch (error) {
            let message = "";
            if (error_i || error) {
                if (error_i?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (error_i?.graphQLErrors) {
                    message = error_i.graphQLErrors[0]?.extensions.code === "INTERNAL_SERVER_ERROR" ? "The invoice already saved or required data is missing.!" : error_i.graphQLErrors[0]?.message;
                }
                if (error?.message) {
                    message = "The invoice already saved or required data is missing.!";
                }
                handleSnacksApper("Self Funded Invoice", message, "error")
            }
        }
    }

    return (
        <Box sx={{ mx: 2, width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }}>
            {selected.length > 0 && (
                <EnhancedTableToolbar setOpenPdf={setOpenPdf} numSelected={selected.length} loading={loading} fileName={"cennasupports" + _process_state.end_date} saveInvoicing={saveInvoicing} data={selectedData()} />
            )}
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={finalInvoice?.length}
                        headCells={headCellsSelf}
                    />
                    <TableBody>
                        {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    // hover
                                    // onClick={(event) => handleClick(event, row.id)}
                                    // role="checkbox"
                                    // aria-checked={isItemSelected}
                                    // tabIndex={-1}
                                    key={row.participant_name + row.id}
                                    // selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell> */}
                                    <TableCell component="th" id={labelId} scope="row">{index + 1}</TableCell>
                                    <TableCell align="left">{row.participant_name}</TableCell>
                                    <TableCell align="left">{row.ndis_Number}</TableCell>
                                    <TableCell align="left">{row.claim_referance}</TableCell>
                                    <TableCell align="left">${row.total_price}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            onClick={(event) => {
                                                setSelectedRowData(row)
                                                setOpenPdf(true)
                                            }}
                                            startIcon={<DownloadIcon color='primary' />}
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                                fontSize: "1.1em",
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35" }
                                            }}
                                        >
                                            PDF View
                                        </Button>
                                        <Button onClick={() => saveInvoicing(row.id)} startIcon={<Save color='primary' />} color="primary" variant="outlined" sx={{ marginLeft: '10px', fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                            Save {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={finalInvoice?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
            {/* display invoice */}
            <CustomModalPDFFile
                openPdf={openPdf}
                handleModal={handleModal}
                selectedData={selectedRowData}
                shifts={selfFunding}
            />
        </Box>
    )
}