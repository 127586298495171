import React from 'react'
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const TermsAndCondition = () => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box
                sx={{
                    textAlign: 'center',
                    mb: 4,
                    border: '2px dashed #ccc',
                    borderRadius: '8px',
                    padding: '20px',
                    backgroundColor: '#f9f9f9',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <a href='/'> <img src={"/assets/icons/logo-landscap.png"} alt="Cenna Logo" style={{ maxHeight: '80px', maxWidth: '100%' }} /></a>
            </Box>
            <div><p><strong>This Software as a service Agreement ("Agreement") is made and effective by and between Cenna Supports ("Cenna Supports Pty Ltd") licensor and Authorised user ("Customer").</strong></p>

                <p><strong>DEFINITIONS&nbsp;</strong></p>

                <p><strong>Access</strong> means access by the Customer to the Cenna Supports Software using the security measures defined in Security.</p>

                <p><strong>Agreement</strong> means this agreement including its Schedule, Appendixes and any variations in writing signed by both Parties.</p>

                <p><strong>Authorised User</strong> means a user of the Cenna Supports Software authorised by the Customer in accordance with the terms of this Agreement.</p>

                <p><strong>Business Day</strong> means a day on which banks are open for business in New South Wales.</p>

                <p><strong>Change of Control means</strong>, in relation to a corporation, a change in control of the board of directors, a change of control of more than half the voting rights attaching to the shares in the corporation, or a change of control of more than half the issued shares to which voting rights are attached.</p>

                <p><strong>Claim</strong> means any claim, cost, damages, debt, expense, liability, loss, suit, action, demand, cause of action or proceeding of any kind irrespective of: (a) how or when it arises; (b) whether it is actual or contingent; (c) whether or not it is in respect of legal or other costs, damages, expenses, fees or losses; (d) whether or not it is in respect of a breach of trust or of a fiduciary or other duty or obligation; and (e) whether or not it arises at Law or otherwise (including by way of contribution or indemnity).</p>

                <p><strong>Common Web Application Vulnerabilities</strong> means traffic interception and the possibility that a third party through decryption may intercept encrypted traffic between Hosted Cloud Solution and an Authorised User's browser.</p>

                <p><strong>Confidential Information</strong> means all information disclosed by a Party to the other Party for or in connection with:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Terms of this Agreement;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; information that by its nature is confidential;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; information that is non-public, sensitive information disclosed by a Party;</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; all information in whatsoever form, either oral or written, that is marked or identified as ‘Confidential', ‘Secret', ‘Not to be Disclosed' or ‘Private';</p>

                <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Intellectual Property Rights;</p>

                <p>(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; trade secrets or information which is capable of protection at Law or equity as confidential information; and</p>

                <p>(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any other information however obtained, ascertained, discovered or derived by the Customer whether the information was disclosed:</p>

                <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; orally, graphically, in writing or in electronic or machine-readable form or by observation;</p>

                <p>(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; before, on or after the date of this Agreement; or</p>

                <p>(iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as a result of any discussions between the Parties but does not include any information which prior to the date of this Agreement was lawfully in the public domain or which Partners in Care has published, circulated or announced publicly.</p>

                <p><strong>Data means</strong> the data that the Customer uploads to the Hosted Cloud Solution through the Cenna Supports Software.</p>

                <p><strong>Delivery Date</strong> means the date specified in Part 2 of the Schedule for grant of access to the Cenna Supports Software.</p>

                <p><strong>Fault</strong> means a failure in the normal operation of the Cenna Supports Software.</p>

                <p><strong>GST</strong> means goods and services tax as defined in A New Tax System (Goods and Services Tax) Act 1999 (Cth).</p>

                <p><strong>GST Law</strong> means the same as in the A New Tax System (Devices and Cleaning Services Tax) Act 1999 (Cth).</p>

                <p><strong>Hosted Cloud Solution</strong> means Amazon AWS cloud server.</p>

                <p><strong>Intellectual Property (Rights)</strong> means all intellectual property rights, including all copyright, patents, trademarks, design rights, trade secrets, circuit layouts, domain names, knowhow, trade secrets and other rights of a similar nature worldwide, whether registrable or not and whether registered or not, and any applications for registration or rights to make such an application.</p>

                <p><strong>Laws</strong> means all laws including rules of common law, principles of equity, statutes, regulations, proclamations, ordinances, by-laws, rules, regulatory principles and requirements, statutory rules of an industry body, statutory mandatory codes of conduct, writs, orders, injunctions, judgments, and Australian generally accepted accounting principles.</p>

                <p><strong>Liability</strong> means any debt, obligation, cost (including legal costs, deductibles or increased premiums), expense, loss, damage, compensation, charge or liability of any kind, including those arising from third party Claims, those that are prospective or contingent and those the amount of which is not ascertained or ascertainable, and whether arising under breach of contract, in tore (including negligence), restitution, pursuant to statute or otherwise at law or in equity.&nbsp;</p>

                <p><strong>Support Services</strong> means the services supplied by Partners in Care to the Customer as specified in Appendix 3.</p>

                <p><strong>Loss</strong> means any cost, expense, loss, damage or liability whether direct, indirect or consequential (including pure economic loss), present or future, ascertained or unascertained, actual, prospective or contingent, or any fine or penalty and includes legal costs.</p>

                <p><strong>Notice</strong> means any written communication from a Party to the other Party including those sent by facsimile, letter, email and conveyed in accordance with the Terms of this Agreement.</p>

                <p><strong>Party</strong> means a party to this Agreement.</p>

                <p><strong>Permitted Use</strong> means the right of the Customer to use the Cenna Supports Software in accordance with this Agreement and any other reasonable directions issued by Partners in Care to the Customer from time to time in hard or digital form.</p>

                <p><strong>Person</strong> means any person that is recognised at law whether it be a natural person, an entity or a corporation.</p>

                <p><strong>Personal Information</strong> has the meaning as defined in the Privacy Act 1988 (Cth).</p>

                <p><strong>Privacy Laws</strong> means the Privacy Act 1988 (Cth).</p>

                <p><strong>Security</strong> means such security measures as Partners in Care may reasonably designate to the Customer from time to time having regard to known or suspected actual or future risks, as a minimum that the Customer keeps confidential and uses secure User IDs and robust passwords.</p>

                <p><strong>Specifications</strong> means any hard or electronic printed material which forms part of the Cenna Supports Software and contains technical information and training related to the use of the Cenna Supports Software.</p>

                <p><strong>Standard Support Services</strong> means the standard services supplied by Partners in Care to the Customer during the Regular Subscription Plan.</p>

                <p><strong>Subscription Fees</strong> means Partners in Care's fees in Part 4 of the Schedule for the supply of the Cenna Supports Software.</p>

                <p><strong>Subscription Plan</strong> means the plan set out in Part 3 of the Schedule.</p>

                <p><strong>Tax Invoice</strong> means an invoice that is GST compliant.</p>

                <p><strong>Term</strong> means the term of the Agreement as set out in Part 2 of the Schedule.</p>

                <p><strong>Interpretation</strong></p>

                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In these Terms, unless the context otherwise requires:</p>

                <p>(a)&nbsp; &nbsp;&nbsp;&nbsp; the singular includes the plural and vice versa;</p>

                <p>(b) &nbsp;&nbsp;&nbsp;&nbsp; a reference to an individual person includes a corporation, partnership, joint venture, association, authority, trust, state or government and vice versa;</p>

                <p>(c)&nbsp; &nbsp;&nbsp;&nbsp; a reference to a recital, clause, schedule, annexure or exhibit is to a recital, clause, schedule, annexure or exhibit of or to this document and references to this document include any such recital, clause, schedule, annexure or exhibit;</p>

                <p>(d)&nbsp; &nbsp;&nbsp;&nbsp; a recital, schedule, annexure or a description of the Parties forms part of this document;</p>

                <p>(e) &nbsp;&nbsp;&nbsp;&nbsp; a reference to any Party to this document or any other document or arrangement includes that Party's executors, administrators, substitutes, successors and permitted assigns;</p>

                <p>(f)&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; where an expression is defined, another part of speech or grammatical form of that expression has a corresponding meaning;</p>

                <p>(g)&nbsp;&nbsp; &nbsp;&nbsp; a reference to ‘dollars' or ‘$' is to Australian currency;</p>

                <p>(h)&nbsp; &nbsp;&nbsp;&nbsp; a reference to a statute, ordinance, code or other Law includes regulations and other instruments under it and consolidations, amendments, re-enactments or replacements of any of them;</p>

                <p>(2)&nbsp; &nbsp;&nbsp;&nbsp; In this document, headings are for convenience and reference only and do not affect interpretation.</p>

                <p><strong>STANDARD TERMS AND CONDITIONS (‘TERMS')</strong></p>

                <p><strong>Grant of Licence and supply of Support Services</strong></p>

                <p>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care grants a non-exclusive licence to the Customer to use the Cenna Supports Software in accordance with the Permitted Use for the Term in the Territory.</p>

                <p>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care warrants that it has the authority to grant the licence.</p>

                <p>1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The licence permits only the Authorised Users of the Customer to access and use the Cenna Supports Software.</p>

                <p>1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These rights are personal to the Customer, non-transferable, and limited by and subject to this Agreement.</p>

                <p>1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer shall have no right to distribute, sell, supply or make available the Cenna Supports Software to any third party to use or commercialise.</p>

                <p>1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer acknowledges that the Cenna Supports Software forms part of a Hosted Cloud Solution comprising the supply of software as a service (SaaS) over the Internet.</p>

                <p>1.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will provide the Limited Support Services and Standard Support Services as applicable during the Term in accordance with Appendix 3.</p>

                <p>1.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer agrees that the Cenna Supports Software provided by Partners in Care does not include any support services not specifically set out in this Agreement including the rectification of any defects, errors or faults.</p>

                <p><strong>Term</strong></p>

                <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This Agreement starts on the Start Date and continues until terminated in accordance with clause 17.</p>

                <p><strong>Subscription Plan</strong></p>

                <p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will provide the Cenna Supports Software to the Customer on the following subscription plan basis:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subscription Plan with Support Services for the Period set out in the Schedule, and</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subscription Plan in consideration of the Subscription Fees.</p>

                <p>Updates and New Releases.</p>

                <p>4.1&nbsp;&nbsp;&nbsp;&nbsp; The supply of the Cenna Supports Software will include the provision of updates and new releases, as they become available.</p>

                <p>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Updates and new releases may include:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; variations to the existing features of the Cenna Supports Software, and</p>

                <p>(b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the addition of new features to the Cenna Supports Software. &nbsp;</p>

                <p>4.3&nbsp;&nbsp;&nbsp;&nbsp; If the Customer does not accept an update or new release of the Cenna Supports Software, Partners in Care may decline to continue the Support Services.</p>

                <p>4.4&nbsp;&nbsp;&nbsp;&nbsp; Where the Customer accepts an update or new release, this Agreement applies in all respects to that update or new release to the extent that it is incorporated or replaces the Cenna Supports Software.</p>

                <p>4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When reasonably required by the Customer, Partners in Care will demonstrate the extent to which an update or new release can provide functions and performance. Partners in Care may charge a fee in respect of any such demonstration.</p>

                <p><strong>Subscription Fees and GST</strong></p>

                <p>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer will pay Partners in Care the Subscription Fees for the Cenna Supports Software in accordance with the rates, payment method, currency and time for payment set out in Part 4 of the Schedule.</p>

                <p>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care may change its Subscription Fees by giving sixty (60) calendar days' Notice in writing to the Customer</p>

                <p>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will provide the Customer with Tax Invoices in accordance with the GST Law in relation to remuneration payable under this clause.</p>

                <p>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All fees or other sums payable under this Agreement are exclusive of GST. If GST is imposed on any supply made under or in accordance with this Agreement, the Customer must pay to Partners in Care an additional amount equal to the GST payable on or for the taxable supply in accordance with the GST Law. Payment of the additional amount will be made at the same time, as payment for the taxable supply is required to be made in accordance with this Agreement.</p>

                <p>5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Payment of the Subscription Fees must be made in full without deduction or taxes, charges including any bank charges.</p>

                <p>5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will have the right to suspend the Cenna Supports Software (in whole or in part) by giving thirty (30) calendar days' Notice in writing to the Customer if the Customer fails to pay the Subscription Fees when due.</p>

                <p>5.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Parties agree that no part of any such payment under clause 5 is refundable on termination or expiry of this Agreement for any reason except in accordance with the express terms of this Agreement.</p>

                <p><strong>Delivery</strong></p>

                <p>6.1 &nbsp;&nbsp;&nbsp; In consideration of the Subscription Fees, Partners in Care will make the Cenna Supports Software available to the Customer by giving access to the Customer on the Delivery Date together with all necessary login credentials and password keys.</p>

                <p>6.2&nbsp;&nbsp;&nbsp;&nbsp; From the Delivery Date, the Customer will be responsible for ensuring that the Cenna Supports Software is used in accordance with the Specifications.</p>

                <p>6.3&nbsp;&nbsp;&nbsp;&nbsp; The Customer must inspect the Cenna Supports Software on the Delivery Date and must within fourteen (14) calendar days notify Partners in Care of any alleged defect, Fault or failure to use otherwise to the extent permitted by law, the Cenna Supports Software will be deemed free from any defect, Fault or failure to use.</p>

                <p><strong>Privacy Law and Data</strong></p>

                <p>7.1&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will take all reasonable steps to ensure the integrity and confidentiality of any Personal Information obtained through this Agreement and use or disclose it only for the purposes of this Agreement subject to any applicable exceptions in the Privacy Laws.</p>

                <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care will not by any part of this Agreement obtain any ownership interest in any of the Personal Information or Data obtained from the Customer by Partners in Care through the supply of the Cenna Supports Software during the Term.</p>

                <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Parties agree to comply with all applicable Law and regulations governing:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; data protection, and</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; practices and procedures to protect Personal Information in accordance with the Privacy laws whether or not that Party is an organisation bound by the Privacy Laws.</p>

                <p>7.4&nbsp;&nbsp;&nbsp;&nbsp; The Customer must keep backups of all Data and Personal Information uploaded to the Hosted Cloud Solution.</p>

                <p>7.5&nbsp;&nbsp;&nbsp;&nbsp; The Customer owns all right, title and interest in the Data and Personal Information. The Customer grants to Partners in Care a licence to use, copy, transmit, and store the Data and Personal Information to enable the Customer to access and use the Cenna Supports Software.</p>

                <p>7.6&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care owns all right, title and interest in any data or information that Partners in Care creates, generates or produces in connection with any supply under these Terms including data and information that is:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; new, unique, original or non-identifiable,</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; aggregate or raw or statistical relating to usage, analyses and results,</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for the purpose of optimising delivery, commercialisation and performance of the supply, and</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; samples and prototypes, conclusions, techniques, know how, methods, and undocumented findings generated in the usual course of business of Partners in Care. (collectively, the ‘Products and Services data'). For the avoidance of doubt, the Products and Services data does not include any Personal Information obtained from or inputted by the Customer or otherwise Data that is sensitive to the Customer.</p>

                <p>7.7&nbsp;&nbsp;&nbsp;&nbsp; The Customer is solely responsible for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership of the Data and Personal Information that is stored or incorporated into the Cenna Supports Software.&nbsp;</p>

                <p>7.8&nbsp;&nbsp;&nbsp;&nbsp; The Customer is responsible for maintaining the confidentiality and security of its login ID and password and must not grant it to any other person but an Authorised User.</p>

                <p>7.9&nbsp;&nbsp;&nbsp;&nbsp; Each Party must notify the other Party immediately if it becomes aware of any breach or alleged breach of that Party's obligations under this clause and comply with any reasonable direction of the other Party to remedy that breach.</p>

                <p>7.10&nbsp;&nbsp;&nbsp; Partners in Care accepts no responsibility or liability for lost, missing, or corrupted Data or Personal Information caused by viruses, worms, trojan horses, unauthorised user activity (hacking), and the like.&nbsp;</p>

                <p>7.11&nbsp;&nbsp;&nbsp; To the extent permitted by law, Partners in Care expressly excludes liability for any loss of Data or Personal Information no matter how caused.&nbsp;</p>

                <p>7.12&nbsp;&nbsp; The obligations imposed by clause 7 survive the end or termination of this Agreement.</p>

                <p><strong>Customer's Obligations</strong></p>

                <p>8.1&nbsp;&nbsp;&nbsp;&nbsp; The Customer will institute and maintain Access control mechanisms which at a minimum:</p>

                <p>(a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ensure that each User ID (unique, personal identifier) can be used to refer to the Identified User (that person defined by their User ID);</p>

                <p>(b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enable a robust password system for each Identified User;</p>

                <p>(c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ensure that only an Identified User who has the necessary authority can Access the Cenna Supports Software;</p>

                <p>(d) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;securely communicate new passwords after password is created or reset;</p>

                <p>(e) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not documenting passwords;</p>

                <p>(f) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not accessing Cenna Supports Software from unsecure networks;</p>

                <p>(g) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;logging out of Cenna Supports Software when not using it or leaving a computer or device unattended and</p>

                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; unlocked when logged into Cenna Supports Software; and</p>

                <p>(h) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not accessing Cenna Supports Software from any other unsecure locations where unauthorised Persons may be able to access or view the computer or device from which it has been accessed.</p>

                <p>8.2&nbsp;&nbsp;&nbsp;&nbsp; If at any time Partners in Care reasonably considers that there has been an unauthorised Access or that any Person (whether or not an Authorised User) is jeopardising the security or integrity of the Cenna Supports Software, Partners in Care may suspend, break, cancel or change Access to the Cenna Supports Software.</p>

                <p>8.3&nbsp;&nbsp;&nbsp;&nbsp; The Customer and its Authorised Users will:</p>

                <p>(a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;exercise reasonable care, skill and diligence in exercising its use of the Cenna Supports Software;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use the Cenna Supports Software in a responsible manner and only in accordance with its intended use;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; promptly notify Partners in Care of any issues with the Cenna Supports Software as and when they occur;</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not use the Cenna Supports Software for any activities which breach any Laws, infringe a third party's rights including Intellectual Property Rights, or are contrary to any relevant standards or codes;</p>

                <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not use the Cenna Supports Software for any activity which defames, threatens, menaces or offends any Person;</p>

                <p>(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; keep secure all User IDs and passwords;</p>

                <p>(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; implement, maintain and enforce suitable virus protection including against:</p>

                <p>(i) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any program or piece of code that is self-replicating when loaded onto a computer and is capable of transmitting itself across networks and bypassing security systems, and</p>

                <p>(ii)&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;any malicious software designed to disrupt or damage a computer system;</p>

                <p>(h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not sell, lease, publish, redistribute or sub-licence the Cenna Supports Software except as expressly permitted under this Agreement,</p>

                <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not re-produce, copy, modify, make error corrections to or otherwise modify, enhance or adapt the Cenna Supports Software or create any derivative works based upon the Cenna Supports Software;</p>

                <p>(j) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not decompile, disassemble, decrypt, reproduce, reverse engineer, or otherwise attempt to derive any of the Cenna Supports Software's code or reduce the Cenna Supports Software to human readable form or permit any third party to do so; and</p>

                <p>(k)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; not use the Cenna Supports Software in whole or in part as the basis for creating a product or service that would infringe the Intellectual Property of any of Partners in Care's products and services.</p>

                <p>8.4&nbsp;&nbsp;&nbsp;&nbsp; The Customer will only upload or enter Data and Personal Information which it creates, owns, holds Intellectual Property rights to or which it is authorised in writing by the owner or creator of the Data or Personal Information to upload or enter.</p>

                <p><strong>Warranties and Representations</strong></p>

                <p>9.1&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care warrants that it is the owner of or authorised to commercialise the Intellectual Property Rights in the Cenna Supports Software.</p>

                <p>9.2&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care warrants that it will comply with all applicable Laws in relation to the Intellectual Property Rights in the Cenna Supports Software.</p>

                <p>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save for those express warranties provided in this Agreement and to the maximum extent permitted by Law and the statutory consumer guarantees as provided in Chapter 3 Part 3.2 of the Competition and Consumer Act 2010 (Cth), Partners in Care does not give any other express warranty of any kind in relation to any goods and/or services supplied under this Agreement.</p>

                <p>9.4&nbsp;&nbsp;&nbsp;&nbsp; The Customer agrees that the Cenna Supports Software is provided "as is" and specific results cannot be guaranteed. It is the Customer's sole responsibility to determine that the Cenna Supports Software or any part of it meets the needs of its business or is otherwise suitable for the purposes for which it is used.</p>

                <p>9.5&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care makes no representations or warranties of any kind, express or implied, concerning the supply of its products and services including:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as to the satisfactory quality or fitness for a purpose;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; as to the absence of latent or other defects, whether discoverable or not;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that it is non-hazardous.</p>

                <p>9.6&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care does not warrant that:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Cenna Supports Software will meet all of the Customer's requirements,</p>

                <p>(b) &nbsp;&nbsp;&nbsp;&nbsp; the use of the Cenna Supports Software will be error or virus free, or uninterrupted or that any updates or new releases of the Cenna Supports Software will not degrade the functionality of any of the Customer's existing systems, for any purpose whatsoever,</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Support Services will be performed error-free or uninterrupted, or</p>

                <p>(d) &nbsp;&nbsp;&nbsp;&nbsp;Partners in Care will correct all Faults, defects and errors.</p>

                <p>9.7&nbsp;&nbsp;&nbsp;&nbsp; If the Cenna Supports Software is used with any third-party software or components by either Party, Partners in Case disclaims:</p>

                <p>(a) &nbsp;&nbsp;&nbsp;&nbsp;all representations and warranties express, implied or statutory with respect to all such third-party software or components including without limitation:</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any warranties, fitness for a purpose, system integration, data accuracy, title, non-infringement, and</p>

                <p>(c) &nbsp;&nbsp;&nbsp;&nbsp; responsibility for the failure of the third-party software or components.</p>

                <p>The use of any third-party software or components is at the Customer's own discretion and risk and the Customer agrees that the Customer will be solely responsible for ensuring that the third-party software or components will meet its requirements, will be uninterrupted, timely, secure or error-free, and that its use will be effective, accurate or reliable.</p>

                <p>9.8&nbsp;&nbsp;&nbsp;&nbsp; The Customer acknowledges that the Cenna Supports Software forms part of a Hosted Cloud Solution which is not in the control of Partners in Care. Partners in Care does not warrant that the Cenna Supports Software and Hosted Cloud Solutions will be available, accessible and up 100% of the time. Customer understands and accepts any inherent risks associated with Common Web Application Vulnerabilities.</p>

                <p>9.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Customer warrants to Partners in Care that it has obtained all consents for Partners in Care to interface or interoperate with Customer's internal systems.</p>

                <p>9.10&nbsp;&nbsp; Partners in Care makes no warranties other than those expressly set out in this Agreement. Partners in Care excludes from this Agreement all other warranties, conditions and terms implied by Law except for any, the exclusion of which would contravene any Law.</p>

                <p>9.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Without limiting clause 9, the Customer warrants that:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; it has not relied on any representation made by Partners in Care other than those stated expressly in this Agreement, or upon any other descriptions, illustrations or specifications contained in any document including manuals or publicity material produced by Partners in Care; and</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to the extent Partners in Care has made any representation which is not otherwise expressly stated in this Agreement, the Customer has been provided with an opportunity to independently verify the accuracy of that representation.</p>

                <p>9.12 &nbsp; Each Party warrants that:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; it has been duly organised, properly registered as a legal entity and is validly existing under the Laws of the jurisdiction of its organisation;&nbsp;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; it has full power to enter into and perform its obligations under this Agreement and has taken all necessary corporate and other action to approve and authorise the transactions contemplated by this Agreement;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; this Agreement constitutes its valid and binding obligations enforceable in accordance with its Terms; and</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; all relevant consents (if any) to its entering into this Agreement have been obtained and neither the entering into nor the performance by it of its obligations under this Agreement will constitute or result in any breach of any contractual or legal restriction binding on it.</p>

                <p><strong>Limitation of Liability</strong></p>

                <p>10.1&nbsp;&nbsp;&nbsp; To the fullest extent permissible at Law, Partners in Care is not liable for any direct, indirect, punitive, incidental, special, consequential damages including without limitation any Claims, Losses, Liability, loss of profits, revenue, business or goodwill arising out of or in any way connected with the provision of or failure to provide any products and services, or otherwise arising out of the provision of products and services, whether based on the Terms of this Agreement, negligence, strict liability or otherwise.</p>

                <p>10.2&nbsp;&nbsp;&nbsp; This Agreement is to be read subject to any legislation that prohibits or restricts the exclusion, restriction or modification of any implied warranties, conditions, guarantees or obligations. If such legislation applies, to the extent possible Partners in Care limits its liability as follows, at Partners in Care's option:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for any Claims relating to this Agreement to the fees payable under this Agreement for the preceding [Insert] months; or</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; in the case of products including any digital products:</p>

                <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the supply of the products again, or</p>

                <p>(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the replacement of the products or the supply of equivalent products, or</p>

                <p>(iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the repair of the products or the payment of having the products repaired, or</p>

                <p>(iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the payment of the cost of replacing the products or of acquiring equivalent products</p>

                <p>&nbsp; &nbsp; or</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; in the case of services including any digital services:</p>

                <p>(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;the supply of the services again,</p>

                <p>(ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;the replacement of the services or the supply of equivalent services, or</p>

                <p>(iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;the payment of the cost of having the services supplied again.</p>

                <p>10.3&nbsp;&nbsp;&nbsp; The right of Partners in Care to be indemnified under this clause is in addition to, and not exclusive of, any other right, power or remedy provided by Law.</p>

                <p>10.4&nbsp;&nbsp;&nbsp; If the Customer is not satisfied with the any of the Cenna Supports Software, its sole and exclusive remedy is to terminate the Agreement in accordance with clause 17.</p>

                <p>10.5&nbsp;&nbsp;&nbsp; In respect of any claim between the Parties under or in connection with this Agreement, the Parties agree that to the maximum extent permitted by Law, the operations of Division 8 of the Civil Liability Act 2002 (NSW) and of any Laws having a similar effect in the Commonwealth and other States and Territories of Australia are excluded and have no effect insofar as any of them would apportion liability to Partners in Care which would not have been so apportioned but for such Laws.</p>

                <p>10.6&nbsp;&nbsp;&nbsp; Without limiting clause 10, to the extent that Partners in Care is liable in respect of any matters under this Agreement, such liability will be reduced to the extent that such Claims, Liability or Loss were caused by any negligent act or omission of the Customer or by any breach of this Agreement by the Customer.</p>

                <p>10.7&nbsp;&nbsp;&nbsp; This clause survives the termination or expiry of this Agreement for whatever reason.</p>

                <p><strong>Intellectual Property</strong></p>

                <p>11.1&nbsp;&nbsp;&nbsp; The Intellectual Property Rights in or related to the Cenna Supports Software or any of its documentation vest in Partners in Care or Partners in Care has the right to use or license the use of the Cenna Supports Software. &nbsp;The Intellectual Property Rights in the Cenna Supports Software include its Specifications and the know-how for it as well as any improvements expressed in whatever form including technical information, processes, procedures, methods, formulae, protocols, software, specifications, instructions, data, documents and materials and the right of Partners in Care to have any Confidential Information kept confidential.</p>

                <p>11.2&nbsp;&nbsp;&nbsp; Partners in Care shall remain the owner or authorised user of:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Intellectual Property Rights now subsisting or conferred in respect of the Cenna Supports &nbsp;Software by the Law in force in any part of the world including all renewals and extensions;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; all future Intellectual Property Rights from time to time belonging to Partners in Care which may be conferred or may subsist in any alterations, improvements, derivative works or additions to the Cenna Supports Software and any and all renewals and extensions; and</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; all common Law rights and statutory or common Law remedies in relation to the Cenna Supports Software available to Partners in Care in Australia and world-wide.</p>

                <p>11.3&nbsp;&nbsp;&nbsp; The Customer agrees that Partners in Care will retain any Intellectual Property Rights in the Cenna Supports&nbsp; Software and any materials created as part of the Cenna Supports Software and the Customer shall not challenge, contest or deny the validity of Partners in Care's Intellectual Property Rights.</p>

                <p>11.4&nbsp;&nbsp;&nbsp; If a Party becomes aware of any infringement or threatened infringement of any of the Intellectual Property Rights under this Agreement, the Party will promptly notify the other Party in writing giving particulars of the alleged infringement.</p>

                <p>11.5&nbsp;&nbsp;&nbsp; This clause survives the termination or expiry of this Agreement for whatever reason.</p>

                <p><strong>Indemnity</strong></p>

                <p>12.1&nbsp;&nbsp; The Customer indemnifies and releases Partners in Care against any Claims, Liability or Loss, demands, proceedings, damages, cost, expense or liability brought against or sustained by Partners in Care, which is directly or indirectly caused by:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Customer's breach of this Agreement;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Customer's misuse of the Cenna Supports Software;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any negligent or wrongful act or omission of the Customer or any of its officers, employees or agents in relation to the performance of its obligations under this Agreement;</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; acts or omissions arising out of Customer's use of the Cenna Supports Software including the uploading of any unauthorised or illegal Data or Personal Information;</p>

                <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the event of a Common Web Application Vulnerability;</p>

                <p>(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any breach of any third-party rights, including in respect of any claim that infringes Intellectual Property Rights.</p>

                <p>12.2&nbsp;&nbsp; The Customer expressly acknowledges that Partners in Care does not have any control over the Customer's or its Authorised User's use of the Cenna Supports Software and the Specifications and will at all times indemnify and keep indemnified Partners in Care from and against all loss, damage, cost, charge, expense (whether in contract or in tort) or suffered by Partners in Care either directly or indirectly, as a result of the Customer's use of the Cenna Supports Software and its Specifications.</p>

                <p>12.3&nbsp;&nbsp; The Customer shall inform Partners in Care promptly in writing of any third-party actions, suits, Claims, demands, proceedings, Losses, damages, compensation, sums of money, costs, charges and expenses which may be brought against either Partners in Care or the Customer. &nbsp;&nbsp;</p>

                <p>12.4&nbsp;&nbsp; The indemnitor's obligations pursuant to this clause 12 includes retention and payment of legal fees and payment of court costs, as well as settlement at the indemnitor's expense and payment of judgments.</p>

                <p>12.5&nbsp;&nbsp;&nbsp; This clause survives the termination or expiry of this Agreement for whatever reason.</p>

                <p><strong>Force Majeure</strong></p>

                <p>13.1&nbsp;&nbsp; Notwithstanding any other provision of this Agreement, no Party need act if it is impossible to act due to force majeure, meaning any cause beyond its control (including war, riot, natural disaster or law taking effect after the date of this Agreement).</p>

                <p>13.2&nbsp;&nbsp; A Party affected by a force majeure agrees to notify the other Party promptly after it determines that it is unable to act, giving full details following the occurrence of the cause relied upon.</p>

                <p>13.3&nbsp;&nbsp; A Party has no responsibility or Liability for any loss or expense suffered or incurred by the other Party as a result of its not acting for so long as the force majeure continues.</p>

                <p>13.4&nbsp;&nbsp; Each Party will cover its own costs or Losses and neither party will have a Claim against the other.&nbsp;</p>

                <p>13.5&nbsp;&nbsp; The non-performing Party agrees to make reasonable efforts to avoid or remove the circumstances giving rise to the force majeure and agrees to continue performance under this Agreement promptly when they are removed.</p>

                <p><strong>Employees and Contractors</strong></p>

                <p>14.1&nbsp;&nbsp; The Customer will not during the Term of this Agreement and for a period of three (3) years after its termination directly or indirectly solicit or entice away any of Partners in Care's employees or subcontractors who were introduced by Partners in Care to the Customer.</p>

                <p><strong>Confidentiality</strong></p>

                <p>15.1&nbsp;&nbsp; A Party&nbsp;(Receiving Party)&nbsp;shall keep in strict confidence all Confidential Information which has been disclosed to the Receiving Party by the other Party&nbsp;(Disclosing Party), its employees, agents or subcontractors. The Receiving Party shall restrict disclosure of such Confidential Information to such of its employees, agents or subcontractors as need to know it for discharging the Receiving Party's obligations under the Agreement, and shall ensure that such employees, agents or subcontractors are subject to obligations of confidentiality corresponding to those which bind the Receiving Party.</p>

                <p>15.2&nbsp;&nbsp; The Terms of this Agreement and any subsequent amendments by the Parties in writing are confidential and may not be disclosed by either Party other than for obtaining professional legal, accounting or other specialist consulting advice.</p>

                <p>15.3&nbsp;&nbsp; The obligations in clause 15 shall not apply to any information which is or becomes publicly available otherwise than through a breach of this Agreement, is already or rightly comes into Partners in Care's possession, is independently developed by Partners in Care, or which Partners in Care is required to disclose by Law.</p>

                <p>15.4&nbsp;&nbsp; Each Party will be entitled to the benefit of this clause during the Term of this Agreement and following termination.</p>

                <p><strong>&nbsp;&nbsp;&nbsp; Insurance</strong></p>

                <p>16.1&nbsp;&nbsp; Both the Customer and Partners in Care must, during the Term at that individual Party's sole cost and expense, obtain and keep in full force and effect business insurance as a prudent person in a business would take out, including:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; public liability;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; professional indemnity / errors and omissions insurance; and</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any additional types of insurance specified in Part 9 of the Schedule.</p>

                <p>16.2&nbsp;&nbsp; These insurances must be taken out with reputable insurers and on terms that a reasonably prudent person would require.</p>

                <p>16.3&nbsp;&nbsp; Certificates of Insurance, or if each other Party requires, certified copies of each other's insurance policy must be delivered to the other Party within fourteen (14) calendar days of a written request from a Party.</p>

                <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Termination</strong></p>

                <p>17.1&nbsp;&nbsp; Either Party may terminate this Agreement by giving sixty (60) days' Notice to the other Partner.</p>

                <p>17.2&nbsp;&nbsp; This Agreement will terminate by Notice of a Party if:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the other Party breaches a term of this Agreement and fails to remedy such breach within 30 calendar days of being notified of such breach;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the other Party becomes subject to external examination or being a natural person becomes bankrupt or dies;</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Customer (if a partnership) dissolves or enters into a process of dissolution or (if a corporation) undergoes a Change of Control event; or</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Customer ceases or notifies of its intention to cease trading as a business</p>

                <p>17.3 &nbsp;&nbsp;Where there has been a termination under clause 17,</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; each Party must return to the other Party all of the other Party's property including its Intellectually Property and any Confidential Information whether written or in machine readable form,</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; except as otherwise required by Law, Partners in Care make available to the Customer its Data and Personal Information upon written request by the Customer within [Insert] months (the ‘Retention Period') of termination,</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; each Party must not record or retain any of the other's Confidential Information or Data or Personal Information unless otherwise required by Law,</p>

                <p>(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Customer must cease its use of the Cenna Supports Software including any use by Authorised Users and pay to Partners in Care any outstanding fees owed to Partners in Care under Part 4 of the Schedule, and</p>

                <p>(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Partners in Care shall have the right to immediately suspend or terminate access by the Customers and its Authorised Users to Cenna Supports Software.</p>

                <p>17.4&nbsp;&nbsp; The Customer agrees that it is the Customer's responsibility to seek the Data and Personal Information from Partners in Care in accordance with clause 17.3 (b) and that Partners in Care will not retain a backup or copy of the Data (other than as required by Law) after the Retention Period following the termination or expiry of this Agreement for any reason. The Customer agrees that the deleted Data and Personal Information will be irrecoverable after the Retention Period.</p>

                <p>17.5&nbsp;&nbsp; Termination of this Agreement is without prejudice to any accrued rights of either Party as at the date of termination.</p>

                <p><strong>Dispute Resolution</strong></p>

                <p>18.1 &nbsp; Compulsory process. A Party must not start arbitration or court proceedings (except proceedings seeking interlocutory relief) in respect of a dispute arising out of these Terms (Dispute) unless it has complied with this clause.</p>

                <p>18.2&nbsp;&nbsp; Notification. A Party claiming that a Dispute has arisen must notify each other Party to the Dispute giving details of the Dispute.</p>

                <p>18.3&nbsp;&nbsp; Initial period – efforts to resolve Dispute. During the 30-calendar day period after a notice is given (or longer period agreed in writing by the Parties to the Dispute) (Initial Period) each Party to the Dispute (Disputant) covenants with the other to cooperate and take all reasonable steps necessary to attempt to resolve the Dispute.</p>

                <p>18.4&nbsp;&nbsp; Mediation. If the Disputants are unable to resolve the Dispute within the Initial Period, each Disputant agrees that the Dispute shall be referred for mediation, at the request of any Disputant, to:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a mediator agreed on by the Disputants; or</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; if the Disputants are unable to agree on a mediator within seven days after the end of the Initial Period, then the Disputants must submit the Dispute for mediation through the Chair of Resolution Institute (ACN 008 651 232) or the Chair's designated representative and the Resolution Institute Mediation Rules shall apply to the mediation.</p>

                <p>18.5&nbsp;&nbsp; Role of mediator. The role of any mediator is to assist in negotiating a resolution of the Dispute.&nbsp; A mediator may not make a decision that is binding on a Disputant unless that Disputant has so agreed in writing.</p>

                <p>18.6&nbsp;&nbsp; Information. Any information or documents disclosed by a Disputant under this clause must be kept confidential and may not be used except to attempt to resolve the Dispute.</p>

                <p>18.7&nbsp;&nbsp; Costs of mediation. Each Disputant must pay its own costs of complying with this clause. The Disputants must pay equally the costs of any mediator engaged.</p>

                <p>18.8 &nbsp; Failure to resolve. After the Initial Period, a Disputant that has complied with this section may terminate the dispute resolution process by giving notice to each other Disputant. A Party to a dispute will only be entitled to pursue other remedies available to it at Law or otherwise, if the Parties have failed to resolve the dispute within thirty (30) calendar days after commencement of dispute resolution.</p>

                <p><strong>Notices</strong></p>

                <p>19.1&nbsp;&nbsp; Any Notice to be given to one Party by another under this Agreement:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; must be in legible writing and in English addressed in accordance with Address for Notices.</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; must be delivered to the recipient in person or by courier hand delivery, by prepaid ordinary post or by email; and</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; must be signed by an authorised officer of the Party giving or making it, or (on its behalf) by any solicitor, director, secretary or authorised agent of that Party.</p>

                <p>19.2&nbsp;&nbsp; A Notice is regarded as being given by the sender and received by the recipient:</p>

                <p>(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; if by delivery in person, when delivered to the recipient;</p>

                <p>(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; if by post, four (4) calendar days from and including the date of postage; or</p>

                <p>(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; if by email, immediately unless sender receives an automated reply that the email was not delivered by reason of the address being invalid or otherwise.</p>

                <p>19.3&nbsp;&nbsp; If a Notice is received on a day which is not a Business Day or after 5:00pm on a Business Day, that Notice is regarded as received 9:00am on the following Business Day.</p>

                <p><strong>General</strong></p>

                <p>20.1&nbsp;&nbsp; Entire Agreement. This Agreement (and any documents executed in connection with it) embodies the entire understanding and agreement between the Parties as to the subject matter of this Agreement.</p>

                <p>20.2&nbsp;&nbsp; Relationship. Nothing in this Agreement will constitute or be deemed to constitute a partnership, joint venture or agency between the Parties.</p>

                <p>20.3&nbsp;&nbsp; Variation. This Agreement may only be varied in writing by the Parties.</p>

                <p>20.4&nbsp;&nbsp; Severability. If any part of provision of this Agreement is invalid, unenforceable or in conflict with the Law, that part or provision is replaced with a provision which, as far as possible, accomplishes the original purpose of that part of the provision otherwise it is to be read down or severed to the extent necessary without affecting the validity or enforceability of the remaining provisions.</p>

                <p>20.5&nbsp;&nbsp; Waiver. No right under this Agreement is waived or deemed to be waived except by notice in writing signed by the Party waiving the right.&nbsp;</p>

                <p>20.6&nbsp;&nbsp; Implied Terms. Any implied term under law that can be excluded is expressly excluded and no term is to be implied as being a term of this Agreement unless by law it cannot be excluded.</p>

                <p>20.7&nbsp;&nbsp; Assignability/Transferability/Novation. Partners in Care may assign, sublicense, transfer, novate or otherwise deal in any other way with any of its rights under this Agreement without the prior written consent of Customer. The rights granted to the Customer under this Agreement may be assigned only with the written consent of Partners in Care.</p>

                <p>20.8&nbsp;&nbsp; Joint and several. Any warranty representation or obligation, which binds or benefits two or more persons under this Agreement binds or benefits those persons jointly and separately.</p>

                <p>20.9&nbsp;&nbsp; Further assurance. Each Party must promptly execute all documents and do all things that another Party from time to time reasonably requests to effect, perfect or complete these Terms and all transactions incidental to it.</p>

                <p>20.10&nbsp; Implied Terms. Any implied term under Law that can be excluded is expressly excluded and no term is to be implied as being a term of this Agreement unless by Law it cannot be excluded.</p>

                <p>20.11&nbsp; Successors and assigns. A person includes the trustee, executor, administrator, successor in title and permitted assign of that person. This clause must not be construed as permitting a Party to assign any right or obligation under this Agreement.</p>

                <p>20.12&nbsp; Further assurance. Each Party must promptly execute all documents and do all things that another Party from time to time reasonably requests to effect, perfect or complete this Agreement and all transactions incidental to it.</p>

                <p>20.13 Survival of Agreement. This Agreement shall enure to the benefit of and be binding upon the Parties and their respective successors, trustees, heirs, executors, administrators and permitted assigns or receivers but will not enure to the benefit of any other persons. The terms, covenants, conditions and provisions of this Agreement which are capable of having effect after the expiration of this Agreement will remain in full force and effect following the expiration of this Agreement.&nbsp;&nbsp;</p>

                <p>20.14 Law and Jurisdiction. The laws of the State of New South Wales, Australia governs this Agreement. Each of the Parties irrevocably submits to the non-exclusive jurisdiction of the courts of New South Wales, and any court that may hear appeals from any of those courts for any proceeding in connection with this Agreement, and waives any right it may have to claim that those courts are an inconvenient forum.</p>

                <p>20.15 Counterparts. This Agreement may be executed in counterparts, each of which will be deemed to be an original and all of which together will constitute one instrument and Agreement provided that those counterparts have been exchanged.</p>

            </div>
        </Container>
    )
}

export default TermsAndCondition;
