import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';

const ShiftTimerClock = ({ shiftDate, shiftEndTime, clock_in_date_time, clock_out_date_time }) => {
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        let timer;

        const shiftEndTimestamp = new Date(`${shiftDate}T${shiftEndTime}`).getTime();

        timer = setInterval(() => {
            const currentTimestamp = new Date().getTime();

            // Calculate remaining time until the shift ends
            const remainingTime = shiftEndTimestamp - currentTimestamp;
            setTimeRemaining(remainingTime > 0 ? remainingTime : 0); // Avoid negative values
        }, 1000); // Update every 1 second

        return () => clearInterval(timer);

    }, [shiftDate, shiftEndTime]);

    const formatTimeRemaining = (time) => {
        if (time === null) return 'Calculating...';

        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / 1000 / 60 / 60));

        if (time <= 0) {
            return 'Shift has ended';
        }

        let result = 'Shift ends in ';
        if (hours > 0) result += `${hours} Hour${hours > 1 ? 's' : ''}`;
        if (hours > 0 && minutes > 0) result += ' and ';
        if (minutes > 0) result += `${minutes} Min${minutes > 1 ? 's' : ''}`;
        if (seconds > 0) result += ` ${seconds} Sec${seconds > 1 ? 's' : ''}`;
        return result;
    };

    return (
        <Box>
            {
                <Box>
                    <Typography variant="body2">
                        {
                            (clock_in_date_time !== null && clock_out_date_time === null) && `Ends in ${formatTimeRemaining(timeRemaining)}`
                        }
                    </Typography>
                </Box>
            }
        </Box>
    );

};

export default ShiftTimerClock;