import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text';
import { ComplaintFormSchema } from './complaint-form-schema';
import { CreateComplaint } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { StaffDetailsList } from '../../../services/staff_service';
import { ClientDetailList } from '../../../services/client_service';

const Category = [
    { label: "Client" },
    { label: "Staff" }
];

const Yourself = [
    { label: 'Client' },
    { label: 'Family member or friend' },
    { label: 'Advocate' },
    { label: 'Carer' },
    { label: 'Staff Member' },
    { label: "Other" }
]

const initialValues = {
    first_name: null,
    last_name: null,
    category: null,
    telephone: null,
    email: null,
    help_required: null,
    complaints_detail: null,
    complaint_date: null,
    i_am_a: null,
    behalf_of: null
}

const ComplaintForm = ({ staffId }) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [categoryValue, setCategoryValue] = useState(null);
    const [usersList, setUsersList] = useState(null);

    const [createComplaint, loading] = CreateComplaint();
    const [listOfStaff] = StaffDetailsList();
    const [lisOfClients] = ClientDetailList();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {

        try {
            const updatedValues = {                
                first_name: values.first_name,
                category: values.category,
                last_name: values.last_name,
                staff_id: categoryValue === 'Staff' ? Number(values.search) : null,
                client_detail_id: categoryValue === 'Client' ? Number(values.search) : null,
                telephone: values.telephone,
                email: values.email,
                help_required: values.help_required,
                complaints_detail: values.complaints_detail,
                complaint_date: dayjs(values.complaint_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                i_am_a: values.i_am_a,
                are_you_making_this_complaint: values.behalf_of,
                submitted_by_staff_id: staffId,
            }

            const response = await createComplaint({ variables: updatedValues });

            if (response?.data) {
                setSnackOpen(true);
                resetForm();

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        if (categoryValue === 'Staff') {
            setUsersList(
                listOfStaff?.map(val => {
                    return ({
                        title: `${val.attributes.first_name} ${val.attributes.last_name}`,
                        value: val.id
                    })
                })
            )
        }
        if (categoryValue === 'Client') {
            setUsersList(
                lisOfClients?.map(val => {
                    return ({
                        title: `${val.attributes.first_name} ${val.attributes.last_name}`,
                        value: val.id
                    })
                })
            )
        }

    }, [categoryValue])

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message="Created Successfully"
                title="Success"
                severity="success"
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Formik
                initialValues={initialValues}
                validationSchema={ComplaintFormSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                children={({ errors, getFieldProps, setFieldValue, values }) => (
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
                            marginTop: { xs: "2%", sm: "2%", lg: "1%" }
                        }}
                    >
                        <Form
                            style={{
                                width: '100%',
                                padding: '0px 55px',
                            }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Complaint Classification
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                    display: 'flex',
                                    gap: '20px',
                                }}
                            >
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="category">Category</InputLabel>
                                        <Select
                                            labelId="category"
                                            id="category-select"
                                            value={values.category}
                                            label="Category"
                                            onChange={(event) => {
                                                setCategoryValue(event.target.value)
                                                setFieldValue("category", event.target.value)
                                            }}
                                        >
                                            {Category.map((val => (
                                                <MenuItem value={val.label}>{val.label}</MenuItem>
                                            )))}
                                        </Select>
                                    </FormControl>
                                    {errors?.category && (
                                        <CustomErrorText errorMessage={errors?.category} />
                                    )}
                                </Grid>
                                {categoryValue &&
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="search-autocomplete"
                                                options={usersList}
                                                getOptionLabel={(option) => option.title}
                                                value={values.search}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("search", newValue ? newValue.value : '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Search ${categoryValue}`}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        {errors?.search && (
                                            <CustomErrorText errorMessage={errors?.search} />
                                        )}
                                    </Grid>
                                }
                            </Box>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Complaint Details
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                }}
                            >
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                format="DD-MM-YYYY"
                                                label={'Complaint Date'}
                                                onChange={(dateTime) => {
                                                    setFieldValue("complaint_date", dayjs(dateTime).format('DD-MM-YYYY'));
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    error={errors?.complaint_date ? true : false}
                                                />}
                                            />
                                        </LocalizationProvider>
                                        {errors?.complaint_date && (
                                            <CustomErrorText errorMessage={errors?.complaint_date} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            placeholder='First Name'
                                            {...getFieldProps('first_name')}
                                            sx={{ flex: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            placeholder='Last Name'
                                            {...getFieldProps('last_name')}
                                            sx={{ flex: 1 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="telephone"
                                            label="Telephone"
                                            variant="outlined"
                                            placeholder='Telephone'
                                            {...getFieldProps('telephone')}
                                            error={errors?.telephone ? true : false}
                                            sx={{ flex: 1 }}
                                        />
                                        {errors?.telephone && (
                                            <CustomErrorText errorMessage={errors?.telephone} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            placeholder='Email'
                                            {...getFieldProps('email')}
                                            sx={{ flex: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="i-am-a">I am a</InputLabel>
                                            <Select
                                                labelId="i-am-a"
                                                id="i-am-a-select"
                                                value={values.i_am_a}
                                                label="I am a"
                                                onChange={(event) => setFieldValue("i_am_a", event.target.value)}
                                            >
                                                {Yourself.map((val => (
                                                    <MenuItem value={val.label}>{val.label}</MenuItem>
                                                )))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="behalf-of">Are you making this complaint on behalf of a person with disability?</InputLabel>
                                            <Select
                                                labelId="behalf-of"
                                                id="behalf-of-select"
                                                value={values.behalf_of}
                                                label="Are you making this complaint on behalf of a person with disability?"
                                                onChange={(event) => setFieldValue("behalf_of", event.target.value)}
                                            >
                                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                                <MenuItem value={'No'}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}></Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}></Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="help_required"
                                            label="If you require help, please provide details of the help you need"
                                            variant="outlined"
                                            {...getFieldProps('help_required')}
                                            sx={{ flex: 1 }}
                                            multiline
                                            rows={5}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="complaints_detail"
                                            label="Please provide the details of your complaint"
                                            variant="outlined"
                                            {...getFieldProps('complaints_detail')}
                                            sx={{ flex: 1 }}
                                            multiline
                                            rows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        border: "2px solid #6ECA35",
                                        ":hover": {
                                            border: "2px solid #6ECA35",
                                        }
                                    }}
                                    type='submit'
                                >
                                    {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                </Button>
                            </Box>
                        </Form>
                    </Grid >
                )}
            />
        </>
    )
}

export default ComplaintForm
