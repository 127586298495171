import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { GetIncidentReportsByStaffId } from '../../../services/staff_service';
import dayjs from 'dayjs';
import DataGridComp from '../../../components/common/DataGridComp';

const ViewIncidents = ({ staffID }) => {
  
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50
    },
    {
      field: 'clientName',
      headerName: 'Client Name',
      width: 200,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 100,
    },
    {
      field: 'locationOfIncident',
      headerName: 'Location of Incident',
      flex: 1,
    },
    {
      field: 'dateAndTime',
      headerName: 'Date and Time',
      width: 250,
    },
    {
      field: 'inappropriate_behaviour',
      headerName: 'Inappropriate Behaviour',
      flex: 1,
    },
    {
      field: 'happened_after',
      headerName: 'Happened After',
      flex: 1,
    },
  ];


  // GraphQL Queries
  const [getIncidentReportsByStaffIdFunc, getIncidentReportsByStaffIdLoading, getIncidentReportsByStaffIdError] = GetIncidentReportsByStaffId()

  const getIncidentReportsByStaffId = async () => {
    const response = await getIncidentReportsByStaffIdFunc({
      "variables": {
        "filters": {
          "staff_id": {
            "id": {
              "eq": staffID,
            }
          }
        }
      },
    });
    if (response?.data.incidentReports.data.length > 0) {
      const rows = response?.data.incidentReports.data.map((report, index) => {        
        return {
          id: index + 1,
          clientName: `${report.attributes.client_detail_id.data.attributes.first_name} ${report.attributes.client_detail_id.data.attributes.last_name}`,
          gender: report.attributes.clientDetail?.attributes?.gender,
          locationOfIncident: report.attributes.location_of_incident,
          dateAndTime: `${dayjs(report.attributes.date_and_time).format("DD/MM/YYYY")} ${dayjs(report.attributes.date_and_time).format("hh:mm A")}`,
          inappropriate_behaviour: report.attributes.inappropriate_behaviour,
          happened_after: report.attributes.happened_after,
        }
      })      
      setRows(rows)
    }
  }

  useEffect(() => {
    getIncidentReportsByStaffId();
  }, [])

  if (staffID === null) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        padding: '25px',
      }}
    >
      <Grid container sx={{
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Grid item xs={11}>
          <DataGridComp
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ViewIncidents;