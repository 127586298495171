import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text';
import { IncidentFormSchema } from './incident-form-schema';
import { CreateIncidentQuestion, CreateIncidentReport } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const PhysicalAggression = [
    {
        label: 'Physical aggression/Self-harm, no harm',
    },
    {
        label: 'Physical/Self harm, minor harm, such as redness or scratch',
    },
    {
        label: 'Physical/Self harm, first aid required',
    },
    {
        label: 'Physical harm/Self-harm, critical incident (hospitalization, serious injury or death)',
    },
]

const VerbalAggression = [
    {
        label: 'Yelling/Screaming at another person, one off',
    },
    {
        label: 'Direct continuous yelling at another person less than 1 minute',
    },
    {
        label: 'Direct continuous yelling greater than 1 minute',
    },
    {
        label: 'Yelling and threats to another persons safety',
    },
]

const PropertyDamage = [
    {
        label: 'No property damage',
    },
    {
        label: 'Disruption to property, mild damage, objects thrown, item does not require repair',
    },
    {
        label: 'Moderate damage to property. Damage occurs but still usable',
    },
    {
        label: 'Severe damage to property. Item requires repair and cannot be used',
    },
    {
        label: 'Critical Incident Severe damage and emergency services involved',
    },
]

const HappenedBefore = [
    {
        label: 'Asked to do something',
    },
    {
        label: 'Said No / denied access',
    },
    {
        label: 'Location (i.e. too many people, too noisy)',
    },
    {
        label: 'Not being acknowledged',
    },
    {
        label: "Transitioning between activities",
    },
    {
        label: "People talking about them",
    },
    {
        label: "Difficulties Communicating",
    },
    {
        label: "Given no choice",
    },
    {
        label: "Unwell/Physical Pain",
    },
    {
        label: "Change to routine",
    },
    {
        label: "Having to wait",
    },
    {
        label: "Loud environment",
    },
    {
        label: "Being corrected",
    },
    {
        label: "Seizure",
    },
    {
        label: "Other",
    }
]

const IncidentForm = ({ fromStaffDashboard, firstName, lastName, gender, staffId, staff_name, staffDesignation }) => {
    const [snackOpen, setSnackOpen] = useState(false);

    const [createIncidentReport, loading] = CreateIncidentReport();
    const [createIncidentQuestion, questionLoading] = CreateIncidentQuestion();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {
        try {
            const updatedValues = {
                client_first_name: values.first_name,
                client_last_name: values.last_name,
                gender: values.gender,
                reporter_name: values.name,
                reporter_position: values.position,
                location_of_incident: values.location_of_incident,
                inappropriate_behaviour: values.inappropriate_behaviour,
                happened_after: values.happened_after,
                notes: values.notes,
                date_and_time: dayjs(values.date_and_time, 'DD-MM-YYYY HH:mm').toISOString(),
                staff_id: staffId,
            };

            const response = await createIncidentReport({ variables: updatedValues });

            if (response?.data) {
                const questionPayload = [
                    { title: values.physical_aggression },
                    { title: values.verbal_aggression },
                    { title: values.property_damage },
                    { title: values.happened_before }
                ];

                for (const question of questionPayload) {
                    await createIncidentQuestion({
                        variables: {
                            title: question.title,
                            active: true,
                            incident_report_id: response?.data?.createIncidentReport?.data?.id
                        }
                    });
                }

                setSnackOpen(true);
                resetForm();
                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message="Created Successfully"
                title="Success"
                severity="success"
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Formik
                initialValues={{
                    first_name: fromStaffDashboard ? firstName : null,
                    last_name: fromStaffDashboard ? lastName : null,
                    gender: fromStaffDashboard ? gender.toLowerCase() : null,
                    name: fromStaffDashboard ? staff_name : null,
                    position: fromStaffDashboard ? staffDesignation : null,
                    date_and_time: null,
                    location_of_incident: null,
                    physical_aggression: null,
                    verbal_aggression: null,
                    property_damage: null,
                    happened_before: null,
                    inappropriate_behaviour: null,
                    happened_after: null,
                    notes: null
                }}
                validationSchema={IncidentFormSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                children={({ errors, getFieldProps, setFieldValue, values }) => (
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
                            marginTop: { xs: "2%", sm: "2%", lg: "1%" }
                        }}
                    >
                        <Form
                            style={{
                                width: '100%',
                                padding: '0px 55px',
                            }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Details of Client
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                    display: 'flex',
                                    gap: '20px',
                                }}
                            >
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="first-name"
                                        label="First Name"
                                        variant="outlined"
                                        placeholder='Enter the first name'
                                        {...getFieldProps('first_name')}
                                        error={errors?.first_name ? true : false}
                                        sx={{ flex: 1 }}
                                        disabled={fromStaffDashboard}
                                    />
                                    {errors?.first_name && (
                                        <CustomErrorText errorMessage={errors?.first_name} />
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="last-name"
                                        label="Last Name"
                                        variant="outlined"
                                        placeholder='Enter the last name'
                                        {...getFieldProps('last_name')}
                                        error={errors?.last_name ? true : false}
                                        sx={{ flex: 1 }}
                                        disabled={fromStaffDashboard}
                                    />
                                    {errors?.last_name && (
                                        <CustomErrorText errorMessage={errors?.last_name} />
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControl fullWidth >
                                        <InputLabel id="gender">Gender</InputLabel>
                                        <Select
                                            labelId="gender"
                                            id="gender-select"
                                            value={values.gender}
                                            label="Gender"
                                            onChange={(event) => setFieldValue("gender", event.target.value)}
                                            disabled={fromStaffDashboard}
                                        >
                                            <MenuItem value={'male'}>Male</MenuItem>
                                            <MenuItem value={'female'}>Female</MenuItem>

                                        </Select>
                                    </FormControl>
                                    {errors?.gender && (
                                        <CustomErrorText errorMessage={errors?.gender} />
                                    )}
                                </Grid>
                            </Box>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Details of Incident Reporter
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                    display: 'flex',
                                    gap: '20px',
                                }}
                            >
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        placeholder='Enter the name'
                                        {...getFieldProps('name')}
                                        error={errors?.first_name ? true : false}
                                        sx={{ flex: 1 }}
                                        disabled={fromStaffDashboard}
                                    />
                                    {errors?.name && (
                                        <CustomErrorText errorMessage={errors?.name} />
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="position"
                                        label="Position"
                                        variant="outlined"
                                        placeholder='Position'
                                        {...getFieldProps('position')}
                                        error={errors?.position ? true : false}
                                        sx={{ flex: 1 }}
                                        disabled={fromStaffDashboard}
                                    />
                                    {errors?.position && (
                                        <CustomErrorText errorMessage={errors?.position} />
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="location-of-incident"
                                        label="Location of Incident"
                                        variant="outlined"
                                        placeholder='Location of Incident'
                                        {...getFieldProps('location_of_incident')}
                                        error={errors?.location_of_incident ? true : false}
                                        sx={{ flex: 1 }}
                                    />
                                    {errors?.location_of_incident && (
                                        <CustomErrorText errorMessage={errors?.location_of_incident} />
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            format="DD-MM-YYYY HH:mm"
                                            label={'Date And Time'}
                                            onChange={(dateTime) => {
                                                setFieldValue("date_and_time", dayjs(dateTime).format('DD-MM-YYYY HH:mm'));
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}

                                                error={errors?.date_and_time ? true : false}
                                            />}
                                        />
                                    </LocalizationProvider>
                                    {errors?.date_and_time && (
                                        <CustomErrorText errorMessage={errors?.date_and_time} />
                                    )}
                                </Grid>
                            </Box>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Intensity Scale Rating (Circle applicable intensity rating)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                }}
                            >
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 5 }}>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl>
                                            <InputLabel id="physical-aggression">Physical Aggression/Self-harm</InputLabel>
                                            <Select
                                                labelId="physical-aggression"
                                                id="physical-aggression-select"
                                                label="Physical Aggression/Self-harm"
                                                onChange={(event) => setFieldValue("physical_aggression", event.target.value)}
                                            >
                                                {PhysicalAggression.map(list => {
                                                    return (
                                                        <MenuItem value={list.label}>{list.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl>
                                            <InputLabel id="verbal-aggression">Verbal Aggression</InputLabel>
                                            <Select
                                                labelId="verbal-aggression"
                                                id="verbal-aggression-select"
                                                label="Verbal Aggression"
                                                onChange={(event) => setFieldValue("verbal_aggression", event.target.value)}
                                            >
                                                {VerbalAggression.map(list => {
                                                    return (
                                                        <MenuItem value={list.label}>{list.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 5 }}>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl>
                                            <InputLabel id="property-damage">Property Damage</InputLabel>
                                            <Select
                                                labelId="property-damage"
                                                id="property-damage-select"
                                                label="Property Damage"
                                                onChange={(event) => setFieldValue("property_damage", event.target.value)}
                                            >
                                                {PropertyDamage.map(list => {
                                                    return (
                                                        <MenuItem value={list.label}>{list.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl>
                                            <InputLabel id="what-happened">What happened before?</InputLabel>
                                            <Select
                                                labelId="what-happened"
                                                id="what-happened-select"
                                                label="What happened before?"
                                                onChange={(event) => setFieldValue("happened_before", event.target.value)}
                                            >
                                                {HappenedBefore.map(list => {
                                                    return (
                                                        <MenuItem value={list.label}>{list.label}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 5 }}>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="inappropriate-behaviour"
                                            label="What inappropriate behaviour happened?"
                                            variant="outlined"
                                            multiline
                                            rows={10}
                                            {...getFieldProps('inappropriate_behaviour')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="happened-after"
                                            label="What happened after?"
                                            variant="outlined"
                                            multiline
                                            rows={10}
                                            {...getFieldProps('happened_after')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        id="notes"
                                        label="Notes"
                                        variant="outlined"
                                        multiline
                                        rows={10}
                                        {...getFieldProps('notes')}
                                    />
                                </Grid>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        border: "2px solid #6ECA35",
                                        ":hover": {
                                            border: "2px solid #6ECA35",
                                        }
                                    }}
                                    type='submit'
                                >
                                    {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                </Button>
                            </Box>
                        </Form>
                    </Grid >
                )}
            />
        </>
    )
}

export default IncidentForm
