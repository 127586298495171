import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SearchAndFilters from '../../../components/search_filters';


const createData = (id, service_code, service_status, service_name, client_name, contact, address, gender, service_location, amount, approver_name) => {
    return {
        id,
        service_code,
        service_status,
        service_name,
        client_name,
        contact,
        address, gender, service_location, amount, approver_name
    };
}

const rows = [
    createData(1, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(2, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(3, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(4, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(5, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(6, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(7, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(8, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
    createData(9, 'ERT3234', "Pending", "Provider travel - non-labor costs", "Falkan", "0254898756", "abc address", "Male", "East Aus", 350, "Max Jallili"),
];

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ID',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Service Code',
        numeric: true,
        disablePadding: false,
        label: 'Service Code',
    },
    {
        id: 'Service Status',
        numeric: true,
        disablePadding: false,
        label: 'Service Status',
    },
    {
        id: 'Service Name',
        numeric: true,
        disablePadding: false,
        label: 'Service Name',
    },
    {
        id: 'Client Name',
        numeric: true,
        disablePadding: false,
        label: 'Client Name',
    },
    {
        id: 'Contact',
        numeric: true,
        disablePadding: false,
        label: 'Contact',
    },
    {
        id: 'Address',
        numeric: true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'Gender',
        numeric: true,
        disablePadding: false,
        label: 'Gender',
    },
    {
        id: 'Service Location',
        numeric: true,
        disablePadding: false,
        label: 'Service Location',
    },
    {
        id: 'Amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'Approver Name',
        numeric: true,
        disablePadding: false,
        label: 'Approver Name',
    },
    {
        id: 'Options',
        numeric: true,
        disablePadding: false,
        label: 'Options',
    }
];

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        style={{ minWidth: headCell.label?.length >= 10 ? 180 : 100 }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold" }}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Reject">
                        <Button variant='outlined' label="approve" startIcon={<EditIcon />}>
                            Edit
                        </Button>
                    </Tooltip>
                    <Tooltip title="Approved">
                        <Button variant='outlined' label="approve" startIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Tooltip>
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
export const ReimbursementGrid = ({ screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Code:</span> {v.service_code}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Status:</span> {v.service_status}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Name:</span> {v.service_name}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Client Name:</span> {v.client_name}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Contact:</span> {v.contact}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Address:</span> {v.address}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Gender:</span> {v.gender}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service Location:</span> {v.service_location}
                        </Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}>
                            <span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Amount:</span> {v.amount}
                        </Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}