import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Collapse, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
// import { AddNewOrgnization } from '../form_screens/admin_forms';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import ServiceForms from '../form_screens/services_form';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { SwapStaffReassign } from '../finance-dashboard/screen/swap_reassign';
import { MyMeetings } from '../service_dashboard/screen/meetings';
import RollerShadesClosedIcon from '@mui/icons-material/RollerShadesClosed';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { HeaderToolbar } from '../../components/header/header_toolbar';
import { UserManagement } from './screens/management';
// import GppMaybeIcon from '@mui/icons-material/GppMaybe';
// import { StaffWarnings } from './screens/staff_warnings';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import { ManageLovs } from './screens/manage_lovs';
import ManageOrganization from './screens/manage_organization';
import { useSelector } from 'react-redux';
import OrganizationTable from './screens/organizations/organization-table';

const drawerWidth = 70;

const AdminDashboard = (props) => {
    const navigate = useNavigate();

    const { window } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const location = useLocation();

    const userPermissions = useSelector((state) => state._permission_reducer.userPermission);
    const userType = useSelector((state) => state._permission_reducer.usersType);

    const [options, setOptions] = React.useState([
        {
            option: 'Organization',
            icon: <DomainAddIcon fontSize={"large"} />,
            active: true,
            screen: <OrganizationTable />
        },
        {
            option: "Add Service Request",
            icon: <MedicationLiquidIcon fontSize={"large"} />,
            active: false,
            screen: <ServiceForms />
        },
        // {
        //     option: 'Swap Service Request',
        //     icon: <SwapHorizontalCircleIcon fontSize={"large"} />,
        //     active: false,
        //     screen: <SwapStaffReassign />
        // },
        // {
        //     option: "Staff Meetings (Performance Meeting)",
        //     icon: <RollerShadesClosedIcon fontSize={"large"} />,
        //     active: false,
        //     screen: <MyMeetings />
        // },
        {
            option: "User Management",
            icon: <ManageAccountsIcon fontSize={"large"} />,
            active: false,
            screen: <UserManagement />
        },
    ]);

    React.useEffect(() => {
        if (location.state) {
            handleMenuItem("", location.state.activeOption)
        }
    }, [location]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const checkPermission = (moduleName) => {
        if (userType === "Admin") return true;

        return userPermissions?.some(
            (permission) =>
                permission.attributes?.module_name === moduleName &&
                permission.attributes?.read
        );
    };

    React.useEffect(() => {
        if (options) {
            const permissibleOptions = options.filter((val) => checkPermission(val.option));

            if (permissibleOptions.length > 0) {
                const firstOption = permissibleOptions[0].option;

                setOptions((prevOptions) =>
                    prevOptions.map((val) => ({
                        ...val,
                        active: val.option === firstOption, // Set active true for the first permissible option
                    }))
                );
            }
        }
    }, [userPermissions]);

    const drawer = (
        <div style={{ overflow: 'hidden', }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 50, objectFit: 'fit' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => {
                    const hasPermission = checkPermission(v.option); // Check permission for the module
                    if (!hasPermission) return null; // Skip rendering if no permission
                    
                    return (
                        <ListItem key={i} disablePadding sx={{ backgroundColor: v.active ? "#013447" : "#ffffff", }}>
                            <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                                <Tooltip title={v.option} placement="right">
                                    <ListItemIcon style={{ color: v.active ? "#ffffff" : "#013447", }}>
                                        {v.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.find((e) => e.active);

    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                elevation={0}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab.option}
                    </Typography>
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }} >
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"

                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    mt: "3.5%",
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(90vh)",
                }}
            >
                {activeTab.screen}
            </Box>
        </Box>
    );
}

AdminDashboard.propTypes = {
    window: PropTypes.func,
};

export default AdminDashboard;