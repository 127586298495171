import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text';
import { CreateFeedback } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';
import { FeedbackFormSchema } from './feedback-form-schema';

const FeedbackType = [
    { label: 'Compliment' },
    { label: 'Suggestions' },
];

const SourceOfFeedback = [
    { label: 'Client' },
    { label: 'Staff' },
    { label: 'External Body' },
    { label: 'Other' },
];

const FeedbackAreas = [
    { label: 'Management/Staff' },
    { label: 'Care' },
    { label: 'Lifestyle/Activities' },
    { label: 'Environment' },
    { label: 'Documentation' },
    { label: 'Maintenance' },
    { label: 'Food' },
    { label: 'Assets' },
    { label: 'Other' },
]

const initialValues = {
    first_name: null,
    last_name: null,
    email: null,
    contact: null,
    feedback_description: null,
    feedback_type: null,
    source_of_feedback: null,
    feedback_areas: null
}

const FeedBackReport = () => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [createFeedback, loading] = CreateFeedback();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {

        try {
            const updatedValues = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                contact: values.contact,
                feedback_description_comments: values.feedback_description,
                feedback_type: values.feedback_type,
                source_of_feedback: values.source_of_feedback,
                areas_you_are_providing_feedback: values.feedback_areas
            };

            const response = await createFeedback({ variables: updatedValues });

            if (response?.data) {
                setSnackOpen(true);
                resetForm();

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message="Thank you for your feedback! Your input has been recorded"
                title="Success"
                severity="success"
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Formik
                initialValues={initialValues}
                validationSchema={FeedbackFormSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                children={({ errors, getFieldProps, setFieldValue, values }) => (
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
                            marginTop: { xs: "2%", sm: "2%", lg: "1%" }
                        }}
                    >
                        <Form
                            style={{
                                width: '100%',
                                padding: '0px 55px',
                            }}>
                            <Grid item={true} xs={12} sm={12} lg={12} sx={{ mx: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                <Grid sx={{ my: 2, display: 'flex', flexWrap: 'wrap', }}>
                                    <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 20 }}>
                                        Feedback
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                }}
                            >
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="feedback-type">Feedback Type</InputLabel>
                                            <Select
                                                labelId="feedback-type"
                                                id="feedback-type-select"
                                                value={values.feedback_type}
                                                label="Feedback Type"
                                                onChange={(event) => setFieldValue("feedback_type", event.target.value)}
                                            >
                                                {FeedbackType.map((val => (
                                                    <MenuItem value={val.label}>{val.label}</MenuItem>
                                                )))}
                                            </Select>
                                        </FormControl>
                                        {errors?.feedback_type && (
                                            <CustomErrorText errorMessage={errors?.feedback_type} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="source-of-feedback">Source of Feedback</InputLabel>
                                            <Select
                                                labelId="source-of-feedback"
                                                id="source-of-feedback-select"
                                                value={values.source_of_feedback}
                                                label="Source of Feedback"
                                                onChange={(event) => setFieldValue("source_of_feedback", event.target.value)}
                                            >
                                                {SourceOfFeedback.map((val => (
                                                    <MenuItem value={val.label}>{val.label}</MenuItem>
                                                )))}
                                            </Select>
                                        </FormControl>
                                        {errors?.source_of_feedback && (
                                            <CustomErrorText errorMessage={errors?.source_of_feedback} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="feedback-areas">Feedback Areas</InputLabel>
                                            <Select
                                                labelId="feedback-areas"
                                                id="feedback-areas-select"
                                                value={values.feedback_areas}
                                                label="Feedback Areas"
                                                onChange={(event) => setFieldValue("feedback_areas", event.target.value)}
                                            >
                                                {FeedbackAreas.map((val => (
                                                    <MenuItem value={val.label}>{val.label}</MenuItem>
                                                )))}
                                            </Select>
                                        </FormControl>
                                        {errors?.feedback_areas && (
                                            <CustomErrorText errorMessage={errors?.feedback_areas} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="first-name"
                                            label="First Name"
                                            variant="outlined"
                                            placeholder='Enter the first name'
                                            {...getFieldProps('first_name')}
                                            error={errors?.first_name ? true : false}
                                            sx={{ flex: 1 }}
                                        />
                                        {errors?.first_name && (
                                            <CustomErrorText errorMessage={errors?.first_name} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="last-name"
                                            label="Last Name"
                                            variant="outlined"
                                            placeholder='Enter the last name'
                                            {...getFieldProps('last_name')}
                                            error={errors?.last_name ? true : false}
                                            sx={{ flex: 1 }}
                                        />
                                        {errors?.last_name && (
                                            <CustomErrorText errorMessage={errors?.last_name} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="contact"
                                            label="Contact"
                                            variant="outlined"
                                            placeholder='Contact'
                                            {...getFieldProps('contact')}
                                            error={errors?.contact ? true : false}
                                            sx={{ flex: 1 }}
                                        />
                                        {errors?.contact && (
                                            <CustomErrorText errorMessage={errors?.contact} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            placeholder='Enter the email'
                                            {...getFieldProps('email')}
                                            error={errors?.email ? true : false}
                                            sx={{ flex: 1 }}
                                        />
                                        {errors?.email && (
                                            <CustomErrorText errorMessage={errors?.email} />
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}></Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}></Grid>
                                </Grid>
                                <Grid sx={{ display: 'flex', gap: '20px', marginBottom: 2 }}>                                    
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            id="feedback-description"
                                            label="Feedback Description / Comments"
                                            variant="outlined"
                                            placeholder='Feedback Description / Comments'
                                            {...getFieldProps('feedback_description')}
                                            error={errors?.feedback_description ? true : false}
                                            sx={{ flex: 1 }}
                                            multiline
                                            rows={5}
                                        />
                                        {errors?.feedback_description && (
                                            <CustomErrorText errorMessage={errors?.feedback_description} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        border: "2px solid #6ECA35",
                                        ":hover": {
                                            border: "2px solid #6ECA35",
                                        }
                                    }}
                                    type='submit'
                                >
                                    {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                </Button>
                            </Box>
                        </Form>
                    </Grid >
                )}
            />
        </>
    )
}

export default FeedBackReport
