import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';

const ShiftTimerClock = ({
  isClockedIn,
  isClockedOut,

  shiftClockedInTime,
  shiftClockedOutTime,  
  shiftDate,
  shiftEndTime
}) => {
  const [timeRemaining, setTimeRemaining] = useState(null);  
  const [shiftClockTime, setShiftClockTime] = useState(new Date());

  useEffect(() => {
    let timer;

    if (isClockedIn && !isClockedOut) {
      const startTimestamp = new Date(shiftClockedInTime).getTime();
      const shiftEndTimestamp = new Date(`${shiftDate}T${shiftEndTime}`).getTime();

      timer = setInterval(() => {
        const currentTimestamp = new Date().getTime();
        setShiftClockTime(currentTimestamp - startTimestamp);

        // Calculate remaining time until the shift ends
        const remainingTime = shiftEndTimestamp - currentTimestamp;
        setTimeRemaining(remainingTime > 0 ? remainingTime : 0); // Avoid negative values
      }, 1000); // Update every 1 second
    }
    else if (!isClockedIn && isClockedOut) {
      const startTimestamp = new Date(shiftClockedInTime).getTime();
      const endTimestamp = new Date(shiftClockedOutTime).getTime();

      setShiftClockTime(endTimestamp - startTimestamp);
    }

    return () => clearInterval(timer);
  }, [isClockedIn, shiftClockedInTime, shiftDate, shiftEndTime]);

  const formatElapsedTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60));
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatTimeRemaining = (time) => {
    if (time === null) return 'Calculating...';

    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60));

    if (time <= 0) {
      return 'Shift has ended';
    }

    let result = 'Shift ends in ';
    if (hours > 0) result += `${hours} Hour${hours > 1 ? 's' : ''}`;
    if (hours > 0 && minutes > 0) result += ' and ';
    if (minutes > 0) result += `${minutes} Min${minutes > 1 ? 's' : ''}`;
    if (seconds > 0) result += ` ${seconds} Sec${seconds > 1 ? 's' : ''}`;
    return result;
  };

  return (
    <Box>
      {
        isClockedIn && (
          <Box>
            <Box sx={{ marginBottom: '10px' }}>
              <Typography variant="h6">Elapsed Time: {formatElapsedTime(shiftClockTime)}</Typography>              
              <Typography variant="body2">{formatTimeRemaining(timeRemaining)}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">Clocked In: {dayjs(shiftClockedInTime).format("HH:mm:ss")}</Typography>
            </Box>
          </Box>
        )
      }
      {
        isClockedOut && (
          <Box>
            <Box sx={{ marginBottom: '10px' }}>
              <Typography variant="h6">Elapsed Time: {formatElapsedTime(shiftClockTime)}</Typography>
              <Typography variant="body2">Shift Ended</Typography>
            </Box>
            <Box>
              <Typography variant="body2">Clocked In: {dayjs(shiftClockedInTime).format("HH:mm:ss")}</Typography>
              <Typography variant="body2">Clocked Out: {dayjs(shiftClockedOutTime).format("HH:mm:ss")}</Typography>
            </Box>
          </Box>
        )
      }
    </Box>
  );

};

export default ShiftTimerClock;