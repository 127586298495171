import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userPermission: null,
    usersType: null
};

export const PermissionReducer = createSlice({
    name: 'userPermission',
    initialState,
    reducers: {
        setUsersType: (state, action) => {
            state.usersType = action.payload;
        },
        setUserPermission: (state, action) => {
            state.userPermission = action.payload;
        },
    },
});

export const { setUserPermission, setUsersType } = PermissionReducer.actions;

export default PermissionReducer.reducer;