import { Grid } from "@mui/material";
import React from "react";
import dayjs from 'dayjs';

export const Footer = () => {

    return (
        <Grid className="footer" sx={{ bgcolor: "#013447" }}>
            < div className="footer-icon" >
                <span> All Rights Reserved.<a href="/terms-and-condition">Terms of Use & Privacy Notice</a> </span>
            </div >
            <div className="footer-text">
                <span>
                    Email: <a href="#">hello@cennasupports.com</a>
                </span>
            </div>
            <div className="footer-text">
                <span>
                    Copyright &#169; {dayjs().year()} CennaSupports.
                </span>
            </div>
        </Grid >
    )
}