import { Box, Grid, TextField, CircularProgress, Button, Typography, FormControl, Slide, Alert, AlertTitle, Snackbar } from '@mui/material'
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Formik } from 'formik'
import React, { useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text'
import { CreateStaffAdjustHour } from '../../../services/finance_service'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
const calculateOverTime = (schedule_start_time, schedule_end_time, clock_in_time, clock_out_time) => {

    const scheduleStartTime = dayjs(schedule_start_time, "HH:mm:ss");
    const scheduleEndTime = dayjs(schedule_end_time, "HH:mm:ss");
    const clockInTime = dayjs(clock_in_time, "HH:mm:ss");
    const clockOutTime = dayjs(clock_out_time, "HH:mm:ss");

    // Calculate durations in minutes
    const scheduledMinutes = scheduleEndTime.diff(scheduleStartTime, "minute");
    const workedMinutes = clockOutTime.diff(clockInTime, "minute");

    dayjs.extend(duration);

    // Calculate overtime
    const overtimeMinutes = workedMinutes - scheduledMinutes;

    const time = dayjs.duration(overtimeMinutes, 'minutes');

    // Format to hours and minutes
    const hours = Math.floor(time.asHours());
    const remainingMinutes = time.minutes();
    return overtimeMinutes > 0 ? `${hours} hour(s) and ${remainingMinutes} minute(s)` : `No overtime`;
}

const OvertimeForm = ({ staffId, shiftClockedInTime, shiftClockedOutTime, scheduledShiftStartTime, scheduledShiftEndTime, shiftDate, shiftID, setChildModalOpen, serviceRequestId }) => {

    const overTimeText = calculateOverTime(scheduledShiftStartTime, scheduledShiftEndTime, shiftClockedInTime, shiftClockedOutTime);   
    
    const [CreateStaffAdjustHourFunc, loading] = CreateStaffAdjustHour();

    // Snackbar code
    const SlideTransition = (props) => {
        return <Slide {...props} direction="left" />;
    }
    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
            autoHideDuration: 5000
        });

        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const submitOverTimeForm = async (values) => {
        const obj = {
            service_shift: shiftID,
            staff_id: staffId,
            reason_for_overtime: values.reasonForOvertime,
            date_of_overtime: values.dateOfOvertime.format("YYYY-MM-DD"),
            start_schedule_time: values.scheduledStartTime.format("HH:mm:ss.SSS"),
            end_schedule_time: values.scheduledEndTime.format("HH:mm:ss.SSS"),
            clock_in_time: dayjs(values.clockInTime).format("HH:mm:ss.SSS"),
            clock_out_time: dayjs(values.clockOutTime).format("HH:mm:ss.SSS"),
            service_request_id: serviceRequestId,
        };

        const response = await CreateStaffAdjustHourFunc({
            variables: {
                data: obj,
            }
        })

        if (response?.data.createStaffAdjustHour.data.id) {
            handleSnacksApper("Overtime Form", "Overtime Form has been submitted", "success");
            setChildModalOpen(false);
        }
    }

    const validationSchema = Yup.object().shape({
        dateOfOvertime: Yup.date().required('Date of Overtime is required'),
        scheduledStartTime: Yup.date().required('Scheduled Start Time is required'),
        scheduledEndTime: Yup.date().required('Scheduled End Time is required'),
        clockInTime: Yup.date().required('Clock In Time is required'),
        clockOutTime: Yup.date().required('Clock Out Time is required'),
        reasonForOvertime: Yup.string().required('Reason for Overtime is required'),
    });

    return (
        <Box>
            <Box>
                <Formik
                    initialValues={{
                        dateOfOvertime: dayjs(shiftDate, "YYYY-MM-DD"),
                        scheduledStartTime: dayjs(scheduledShiftStartTime, 'HH:mm:ss.SSS'),
                        scheduledEndTime: dayjs(scheduledShiftEndTime, 'HH:mm:ss.SSS'),
                        clockInTime: shiftClockedInTime,
                        clockOutTime: shiftClockedOutTime,
                        reasonForOvertime: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={submitOverTimeForm}
                >
                    {({ values, handleSubmit, setFieldValue, errors }) => {
                        return <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    flexDirection: 'column',
                                    marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
                                    marginTop: { xs: "2%", sm: "2%", lg: "1%" }
                                }}
                            >
                                <Grid item xs={12} sm={12} lg={12}
                                    sx={{
                                        mx: 'auto',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        marginBottom: '15px'
                                    }}>
                                    <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24, textAlign: 'center' }}>
                                        Overtime Form
                                    </Typography>
                                </Grid>
                                <Box
                                    sx={{
                                        border: "1px solid #6ECA35",
                                        borderRadius: 3,
                                        bgcolor: "#fff",
                                        p: 3,
                                        display: 'flex',
                                        gap: '20px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={12}>
                                                <Typography color={"primary"} sx={{ fontSize: 18, }}>
                                                    Overtime Duration
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontSize: 14, }}>
                                                    {overTimeText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={12}>
                                                <Typography color={"primary"} sx={{ fontSize: 18, }}>
                                                    Overtime Details
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Date of Overtime"
                                                        value={values.dateOfOvertime}
                                                        onChange={(newValue) => setFieldValue('dateOfOvertime', newValue)}
                                                        disabled={true}
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.dateOfOvertime && <CustomErrorText errorMessage={errors.dateOfOvertime} />}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="Scheduled Start Time"
                                                        value={values.scheduledStartTime}
                                                        onChange={(newValue) => setFieldValue('scheduledStartTime', newValue)}
                                                        disabled={true}
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.scheduledStartTime && <CustomErrorText errorMessage={errors.scheduledStartTime} />}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="Scheduled End Time"
                                                        value={values.scheduledEndTime}
                                                        onChange={(newValue) => setFieldValue('scheduledEndTime', newValue)}
                                                        disabled={true}
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.scheduledEndTime && <CustomErrorText errorMessage={errors.scheduledEndTime} />}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="Clock In Time"
                                                        value={values.clockInTime}
                                                        onChange={(newValue) => setFieldValue('clockInTime', newValue)}
                                                        disabled={true}
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.clockInTime && <CustomErrorText errorMessage={errors.clockInTime} />}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="Clock Out Time"
                                                        value={values.clockOutTime}
                                                        onChange={(newValue) => setFieldValue('clockOutTime', newValue)}
                                                        disabled={true}
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.clockOutTime && <CustomErrorText errorMessage={errors.clockOutTime} />}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box>
                                        <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Reason for Overtime"
                                                    multiline
                                                    rows={3}
                                                    value={values.reasonForOvertime}
                                                    onChange={(e) => setFieldValue('reasonForOvertime', e.target.value)}
                                                    fullWidth
                                                />
                                                <Box sx={{ marginTop: '5px' }}>
                                                    {errors.reasonForOvertime && <CustomErrorText errorMessage={errors.reasonForOvertime} />}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            bgcolor: "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            textTransform: "capitalize",
                                            color: "#000000",
                                            border: "2px solid #6ECA35",
                                            ":hover": {
                                                border: "2px solid #6ECA35",
                                            }
                                        }}
                                        type='submit'
                                    >
                                        {!loading ? 'Submit Reason' : <CircularProgress color="secondary" size={26} />}
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    }}
                </Formik>
            </Box>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default OvertimeForm;