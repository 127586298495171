import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import APIGQL from "./graphql";

export const ClientDetailList = () => {
    const { data, loading } = useQuery(APIGQL.ApiGetClientDetailList, {
        fetchPolicy: "cache-and-network"
    })
    const clientData = data?.clientDetails?.data;
    return [clientData, loading];
}

export const ClientDetailsRelationlData = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiClientDetailsRelationlData, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const ClientDetailsById = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetClientDetailById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const MedicalInformationById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetMedicalInformationById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const OnboardMedicalHistoryDetailById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetOnboardMedicalHistoryDetailById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const ReferringDoctorInformationById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiReferringDoctorInformationById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const ClientQuestionAnswerRefById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiClientQuestionAnswerRefById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const ReferringDoctorInformationsById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetReferringDoctorInformationsById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const AdmissionInformationsById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetAdmissionInformationsById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const IndividualRiskAssessmentById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetIndividualRiskAssessment, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const HomeRiskAssessmentById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetHomeRiskAssessment, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const SafetyPlanById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetSafetyPlanById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const ClientGoalsAndCareDetailsById = () => {
    const [getById, { loading }] = useLazyQuery(APIGQL.ApiGetClientGoalsAndCareDetailsById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading];
}

export const HistoryOfClientService = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiHistoryOfClientService, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const ContactInfoById = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetContactInfoById, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const EmergencyContactDetailsByFilter = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetEmergencyContactDetailsByFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const AdministrativeNotesByFilter = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetAdministrativeNotesByFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const ServiceInformations = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetServiceInformations, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const PreferenceForStaffWorkers = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetPreferenceForStaffWorkers, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const ClientGoalsByFilter = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetClientGoalsByFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const CheckListAlertsNotificationsByFilter = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetCheckListAlertsNotificationsByFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const PlanReviewByFilter = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetPlanReviewByFilter, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}

export const ClientAnswerLovsReferences = () => {
    const [getById, { loading, error }] = useLazyQuery(APIGQL.ApiGetClientAnswerLovsReferences, {
        fetchPolicy: "cache-and-network"
    })
    return [getById, loading, error];
}


// // // /// =========================Mutation ==================================================

export const CreateClientDetail = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateClientDetail);
    return [createClient, loading, error]
}

export const CreateClientAnswerLovsReference = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateClientAnswerLovsReference);
    return [createClient, loading, error]
}

export const CreateContactDetailForClient = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateContactDetailForClient);
    return [createClient, loading, error]
}

export const CreateEmergencyContactDetailForClient = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateEmergencyContactDetailForClient);
    return [createClient, loading, error]
}

export const CreateAdministrativeNote = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateAdministrativeNote);
    return [createClient, loading, error]
}

export const CreateServiceInformation = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateServiceInformation);
    return [createClient, loading, error]
}

export const CreatePreferenceForStaffWorker = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreatePreferenceForStaffWorker);
    return [createClient, loading, error]
}

export const CreateClientNote = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateClientNote);
    return [createClient, loading, error]
}

export const CreateClientGoal = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateClientGoal);
    return [createClient, loading, error]
}

export const CreateCheckListAlertsNotification = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateCheckListAlertsNotification);
    return [createClient, loading, error]
}

export const CreatePlanReview = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreatePlanReview);
    return [createClient, loading, error]
}

export const CreatePlanSupportCategory = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreatePlanSupportCategory);
    return [createClient, loading, error]
}

export const CreateMedicationsForClient = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateMedicationsForClient);
    return [createClient, loading, error]
}


export const CreateOnboardMedicalHistoryDetail = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateOnboardMedicalHistoryDetail);
    return [createClient, loading, error]
}

export const CreateReferringDoctorInformation = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateReferringDoctorInformation);
    return [createClient, loading, error]
}

export const CreateAdmissionInformation = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateAdmissionInformation);
    return [createClient, loading, error]
}

export const CreateClientQuestionAnswerRef = () => {
    const [createClient, { loading, error }] = useMutation(APIGQL.ApiCreateClientQuestionAnswerRef);
    return [createClient, loading, error]
}

export const SendOnboardingLinkEmail = () => {
    const [createGuardianFormEmailList, { loading, error }] = useMutation(APIGQL.ApiSendOnboardingLinkEmail);
    return [createGuardianFormEmailList, loading, error]
}

export const SendStaffOnboardingLinkEmail = () => {
    const [createStaffFormEmailList, { loading, error }] = useMutation(APIGQL.ApiStaffOnboardingLinkEmail);
    return [createStaffFormEmailList, loading, error]
}
