import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from "../screens/authentication/login";
import Dashboard from "../screens/dashboard";
import ClientDashboard from "../screens/client_dashboard";
import { Footer } from "../components/footer";
import StaffDashboard from "../screens/staff_dashboard";
import RegistrationDashboard from "../screens/register_dashboard";
import AssetsDasboard from "../screens/assets_dashboard";
import ReportsDasboard from "../screens/reports_dashboard";
import SchedulerDashboard from "../screens/sheduler_dashboard";
import ServiceDashboard from "../screens/service_dashboard";
import AdminDashboard from "../screens/admin_dashboard";
import FinanceDashboard from "../screens/finance-dashboard";
import AuditDasboard from "../screens/audit_dashboard";
import ProfileDashboard from "../screens/profile_dashboard";
import GuardianForm from "../screens/guardian-form/screen/GuardianForm";
import ThankyouPageForGuardianForm from "../screens/guardian-form/screen/ThankyouPageForGuardianForm";
import ProtectedRoutes from "../routes/protected_routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserPermission, setUsersType } from "../middleware/reducers/permission_reducer";
import { FilterUserById } from "../services/auth_service";
import { userStateManagement } from "../middleware/reducers/user";
import StaffOnboardingForm from "../screens/form_screens/staff_form/screen/staff_onboarding_form";
import TermsAndCondition from "../screens/terms_condition";

const Navigation = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    const dispatch = useDispatch();
    const _userInfo = useSelector((state) => state._authentication.auth_user);

    const [apiGetUserInfo, loadings, error] = FilterUserById();

    const getMyDetails = async () => {
        try {
           
            const response = await apiGetUserInfo({
                "variables": {
                    "id": user?.id
                }
            })
            if (response?.data?.usersPermissionsUser?.data) {
                const final_data = response?.data?.usersPermissionsUser?.data;
                dispatch(userStateManagement(final_data))
            }
        } catch (error) {
            console.log("error")
        }
    }

    React.useEffect(() => {
        if (!_userInfo && user) {
            getMyDetails()
        }
        if (_userInfo) {
            dispatch(setUserPermission(_userInfo?.attributes?.feature_group_management_id?.data?.attributes?.module_permissions?.data));
            dispatch(setUsersType(_userInfo?.attributes?.user_type?.data?.attributes?.user_type))
        }
    }, [_userInfo])
    
    return (
        <BrowserRouter>
            <Routes >
                <Route exact path="/" element={<Login />} />
                <Route path="/my-profile" element={<ProtectedRoutes><ProfileDashboard /> </ProtectedRoutes>} />
                <Route path="/home" element={<ProtectedRoutes> <Dashboard /> </ProtectedRoutes>} />
                <Route path="/client-dashboard" element={<ProtectedRoutes><ClientDashboard /> </ProtectedRoutes>} />
                <Route path="/staff-dashboard" element={<ProtectedRoutes><StaffDashboard /> </ProtectedRoutes>} />
                <Route path="/registration-dashboard" element={<ProtectedRoutes><RegistrationDashboard /> </ProtectedRoutes>} />
                <Route path="/assets-dashboard" element={<ProtectedRoutes><AssetsDasboard /> </ProtectedRoutes>} />
                <Route path="/reports-dashboard" element={<ProtectedRoutes><ReportsDasboard /> </ProtectedRoutes>} />
                <Route path="/scheduler-dashboard" element={<ProtectedRoutes><SchedulerDashboard /> </ProtectedRoutes>} />
                <Route path="/service-dashboard" element={<ProtectedRoutes><ServiceDashboard /> </ProtectedRoutes>} />
                <Route path="/admin-dashboard" element={<ProtectedRoutes><AdminDashboard /> </ProtectedRoutes>} />
                <Route path="/finance-dashboard" element={<ProtectedRoutes><FinanceDashboard /> </ProtectedRoutes>} />
                <Route path="/audit-dashboard" element={<ProtectedRoutes><AuditDasboard /> </ProtectedRoutes>} />
                <Route path="/guardian-form" element={<GuardianForm />} />
                <Route path="/staff-onboarding" element={<StaffOnboardingForm />} />
                <Route path="/thankyou" element={<ThankyouPageForGuardianForm />} />
                <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                {/* <Route path="/staff-details-view" element={<StaffDetailsViewV2 />} /> */}
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default Navigation;
