import { Autocomplete, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Modal, Slide, Stack, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text'
import CustomSnackBar from '../../../components/custom_snack_bar'
import { GetAllClientGroupManagement, GetAllFeatureGroupManagement, GetAllUsers } from '../../../services/admin_service'
import { AdminManagementSchema } from './schema'
import { CreateSubAdmin } from '../../../services/staff_service'

const SUB_ADMIN = 4;
const AUTHENTICATED_USER = 1;

const initialValues = {
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    feature_group_management_id: null,
    client_group_management_id: null,
    status: true
}

const AdminManagement = ({ modal, setModal }) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [usersEmail, setUsersEmail] = useState(null);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');

    const generateUsername = () => `User${Math.random().toString(36).substring(2, 10)}`;

    const [FeatureGroupManagementData] = GetAllFeatureGroupManagement();
    const [ClientGroupManagementData] = GetAllClientGroupManagement();
    const [AllUsersData] = GetAllUsers();

    const [createSubAdmin, loading] = CreateSubAdmin();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {
        try {
            if (usersEmail.includes(values.email)) {
                setSnackSeverity('error')
                setSnackMessage('Email already exists. Please choose a unique email');
                setSnackOpen(true);
            }
            else {
                //TODO 
                const updatedPayload = {
                    ...values,
                    username: generateUsername(),
                    role: AUTHENTICATED_USER,
                    user_type: SUB_ADMIN
                }

                const result = await createSubAdmin({
                    variables: updatedPayload
                });

                if (result) {
                    setSnackSeverity('success')
                    setSnackMessage('Sub-admin created successfully!')
                    setSnackOpen(true);
                    resetForm();
                    setModal(false)
                }
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
    }

    useEffect(() => {
        if (AllUsersData) {
            setUsersEmail(AllUsersData?.usersPermissionsUsers?.data?.map(val => (val.attributes.email)))
        }
    }, [AllUsersData])

    useEffect(() => {
        if (ClientGroupManagementData?.clientGroupManagements?.data) {
            const formattedData = ClientGroupManagementData.clientGroupManagements.data.map(val => ({
                value: val.id,
                title: val.attributes.name
            }));
            setClientList(formattedData);
        }

        if (FeatureGroupManagementData?.featureGroupManagements?.data) {
            const formattedData = FeatureGroupManagementData.featureGroupManagements.data.map(val => ({
                value: val.id,
                title: val.attributes.name
            }));
            setFeatureList(formattedData);
        }
    }, [ClientGroupManagementData, FeatureGroupManagementData]);

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={AdminManagementSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                    children={({ errors, getFieldProps, setFieldValue }) => (
                        <Form>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                maxWidth: "calc(70%)",
                                maxHeight: "calc(90%)",
                                minWidth: "calc(60%)",
                                boxShadow: 12,
                                borderRadius: 1,
                                p: 4,
                                overflowY: "auto"
                            }}>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize", mb: 2 }}>
                                        Admin Management
                                    </Typography>
                                </Box>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        placeholder='Enter the first name'
                                        fullWidth
                                        {...getFieldProps('first_name')}
                                        error={errors?.first_name ? true : false}
                                    />
                                    {errors?.first_name && (
                                        <CustomErrorText errorMessage={errors?.first_name} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        placeholder='Enter the last name'
                                        fullWidth
                                        {...getFieldProps('last_name')}
                                        error={errors?.last_name ? true : false}
                                    />
                                    {errors?.last_name && (
                                        <CustomErrorText errorMessage={errors?.last_name} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        placeholder='Enter the email'
                                        fullWidth
                                        {...getFieldProps('email')}
                                        error={errors?.email ? true : false}
                                    />
                                    {errors?.email && (
                                        <CustomErrorText errorMessage={errors?.email} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        variant="outlined"
                                        placeholder='Enter the password'
                                        fullWidth
                                        {...getFieldProps('password')}
                                        error={errors?.password ? true : false}
                                    />
                                    {errors?.password && (
                                        <CustomErrorText errorMessage={errors?.password} />
                                    )}
                                </Grid>
                                {featureList &&
                                    <Grid sx={{ mb: 3 }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                fullWidth
                                                options={featureList}
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("feature_group_management_id", newValue ? newValue.value : '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Search Feature Groups`}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        {errors?.feature_group_management_id && (
                                            <CustomErrorText errorMessage={errors?.feature_group_management_id} />
                                        )}
                                    </Grid>
                                }
                                {clientList &&
                                    <Grid sx={{ mb: 3 }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                fullWidth
                                                options={clientList}
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("client_group_management_id", newValue ? newValue.value : '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Search Client Groups`}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        {errors?.client_group_management_id && (
                                            <CustomErrorText errorMessage={errors?.client_group_management_id} />
                                        )}
                                    </Grid>
                                }
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": {
                                                    border: "2px solid #6ECA35",
                                                }
                                            }}
                                            type='submit'
                                        >
                                            {!loading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                        </Button>
                                        <Button
                                            onClick={() => setModal(false)}
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35", }
                                            }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )}
                />
            </Modal>
        </>
    )
}

export default AdminManagement
