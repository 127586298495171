import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { GetStaffAdjustHour } from '../../../services/finance_service';
import dayjs from 'dayjs';
import DataGridComp from '../../../components/common/DataGridComp';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50
    },
    {
        field: 'service_request_name',
        headerName: 'Service Request Name',
        flex: 1,
    },
    {
        field: 'date_of_overtime',
        headerName: 'Date Of Overtime',
        width: 200,
    },
    {
        field: 'start_schedule_time',
        headerName: 'Start Schedule Time',
        width: 200,
    },
    {
        field: 'end_schedule_time',
        headerName: 'End Schedule Time',
        width: 200,
    },
    {
        field: 'clock_in_time',
        headerName: 'Clock In Time',
        width: 200,
    },
    {
        field: 'clock_out_time',
        headerName: 'Clock Out Time',
        width: 200,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 100,
    },

];
const MyOvertimeRequests = ({ staffID }) => {

    const [rows, setRows] = useState([]);


    // GraphQL Queries
    const [GetStaffAdjustHourFunc, GetStaffAdjustHourFuncLoading, GetStaffAdjustHourFuncError] = GetStaffAdjustHour()

    const getIncidentReportsByStaffId = async () => {

        const response = await GetStaffAdjustHourFunc({
            "variables": {
                "filters": {
                    "staff_id": {
                        "id": {
                            "eq": staffID,
                        }
                    }
                }
            },
        });        

        if (response?.data.staffAdjustHours.data.length > 0) {
            const rows = response?.data.staffAdjustHours.data.map((complaint, index) => {
                const { clock_in_time, clock_out_time, end_schedule_time, start_schedule_time, status, service_request_id, date_of_overtime } = complaint.attributes;
                return {
                    id: index + 1,
                    date_of_overtime: dayjs(date_of_overtime).format("DD/MM/YYYY"),
                    clock_in_time: dayjs(`${date_of_overtime}T${clock_in_time}`).format("HH:mm A"),
                    clock_out_time: dayjs(`${date_of_overtime}T${clock_out_time}`).format("HH:mm A"),
                    end_schedule_time: dayjs(`${date_of_overtime}T${end_schedule_time}`).format("HH:mm A"),
                    start_schedule_time: dayjs(`${date_of_overtime}T${start_schedule_time}`).format("HH:mm A"),
                    status: status,
                    service_request_name: service_request_id.data.attributes.charge_band_detail_id.data.attributes.charge_band_name,
                }
            })
            setRows(rows)
        }
    }

    useEffect(() => {
        getIncidentReportsByStaffId();
    }, [])

    if (staffID === null) {
        return <div>Loading...</div>;
    }

    return (
        <Box
            sx={{
                padding: '25px',
            }}
        >
            <Grid container sx={{
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Grid item xs={11}>
                    <DataGridComp
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default MyOvertimeRequests;