import * as React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';

const ModulesAccordion = ({ setFieldValue, values }) => {
    const handlePermissionChange = (e, moduleIndex, permissionIndex, permissionKey) => {
        const isChecked = e.target.checked;

        // Update the selected permission
        setFieldValue(`module.${moduleIndex}.permissions.${permissionIndex}.${permissionKey}`, isChecked);

        // If "write" or "archive" is selected, ensure "read" is also selected
        if ((permissionKey === "write" || permissionKey === "archive") && isChecked) {
            const readIndex = values.module[moduleIndex].permissions.findIndex(
                (perm) => Object.keys(perm)[0] === "read"
            );
            if (readIndex !== -1) {
                setFieldValue(`module.${moduleIndex}.permissions.${readIndex}.read`, true);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            {values?.module?.map((val, index) => (
                <Grid item xs={6} key={index}>
                    <Box
                        sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            p: 2,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h6" sx={{ borderBottom: '1px solid #ddd', pb: 1, mb: 2 }}>
                            {val.name}
                        </Typography>
                        <FormGroup>
                            {val.permissions.map((permission, lowerIndex) => {
                                const permissionKey = Object.keys(permission)[0];
                                return (
                                    <FormControlLabel
                                        key={lowerIndex}
                                        control={
                                            <Checkbox
                                                checked={permission[permissionKey]}
                                                onChange={(e) =>
                                                    handlePermissionChange(e, index, lowerIndex, permissionKey)
                                                }
                                            />
                                        }
                                        label={permissionKey.toUpperCase()}
                                        sx={{
                                            marginRight: 3,
                                        }}
                                    />
                                );
                            })}
                        </FormGroup>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default ModulesAccordion;
