import { Box, Grid, TextField, CircularProgress, Button, Typography, Snackbar, Alert, AlertTitle, Slide } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Formik } from 'formik';
import React, { useState } from 'react';
import CustomErrorText from '../../../components/custom-error-text';
import DragAndDrop from '../../../components/common/DragAndDrop.jsx'
import { CreateReimbursementRequest } from '../../../services/finance_service';
import { CreateDocumentLink } from "../../../services/staff_service";

import * as Yup from 'yup';
import dayjs from 'dayjs';
import { API, AUTH_TOKEN } from "../../../config/constant.js";
import axios from "axios";

const Reimbursement = ({ shiftDate, setChildModalOpen, shiftID, staffId }) => {
    const [CreateReimbursementRequestFunc, loading] = CreateReimbursementRequest();
    const [createDocumentLink, createDocumentLinkLoading, error] = CreateDocumentLink();

    const SlideTransition = (props) => <Slide {...props} direction="left" />;

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled",
    });

    const [fileError, setFileError] = useState();

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
            autoHideDuration: 5000,
        });

        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity,
        });
    };

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const validationSchema = Yup.object().shape({
        shiftDate: Yup.date().required('Visit Date is required'),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .positive('Amount must be a positive number')
            .required('Amount is required'),
        purpose: Yup.string().required('Purpose / Notes are required'),
        receipt: Yup.mixed().required('Please upload a receipt to proceed'),
    });

    const submitReimbursementForm = async (values) => {
        const formData = new FormData();
        formData.append('files', values.receipt);
        const response = await axios.post(
            `${API}/upload`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${AUTH_TOKEN}`, // Add Authorization header
                },
            }
        );

        if (response?.data) {
            const { name, url, mime } = response?.data[0];
            const createDocumentLinkResponse = await createDocumentLink({
                variables: {
                    file_name: name,
                    file_type: mime,
                    file_url: url,
                    staff_detail: staffId,
                }
            })
            if (createDocumentLinkResponse?.data.createDocumentLink.data.id) {
                let docId = createDocumentLinkResponse?.data.createDocumentLink.data.id;
                const data = {
                    visit_date: values.shiftDate.format("YYYY-MM-DD"),
                    amount: values.amount,
                    purpose_note: values.purpose,
                    staff_id: staffId,
                    document_link: docId,
                    service_shift_id: shiftID,
                };
                const response = await CreateReimbursementRequestFunc({
                    variables: {
                        data: data,
                    }
                })

                if (response?.data.createReimbursement.data.id) {
                    handleSnacksApper("Reimbursement Request", "Reimbursement request submitted successfully and sent for review.", "success");
                    setChildModalOpen(false);
                }
            }
        }
    };

    return (
        <Box>
            <Formik
                initialValues={{
                    shiftDate: dayjs(shiftDate, "YYYY-MM-DD"),
                    amount: '',
                    purpose: '',
                    receipt: null,
                }}
                validationSchema={validationSchema}
                onSubmit={submitReimbursementForm}
            >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                                flexDirection: 'column',
                                marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
                                marginTop: { xs: "2%", sm: "2%", lg: "1%" },
                            }}
                        >
                            <Grid item xs={12} sm={12} lg={12}
                                sx={{
                                    mx: 'auto',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    marginBottom: '15px',
                                }}>
                                <Typography color={"primary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24, textAlign: 'center' }}>
                                    Reimbursement Form
                                </Typography>
                            </Grid>
                            <Box
                                sx={{
                                    border: "1px solid #6ECA35",
                                    borderRadius: 3,
                                    bgcolor: "#fff",
                                    p: 3,
                                    display: 'flex',
                                    gap: '20px',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box>
                                    <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Visit Date"
                                                    value={values.shiftDate}
                                                    onChange={(newValue) => setFieldValue('shiftDate', newValue)}
                                                    disabled={true}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <Box sx={{ marginTop: '5px' }}>
                                                {errors.shiftDate && <CustomErrorText errorMessage={errors.shiftDate} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Amount"
                                                type="number"
                                                value={values.amount}
                                                onChange={(e) => setFieldValue('amount', e.target.value)}
                                                fullWidth
                                            />
                                            <Box sx={{ marginTop: '5px' }}>
                                                {errors.amount && <CustomErrorText errorMessage={errors.amount} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Purpose / Notes"
                                                multiline
                                                rows={3}
                                                value={values.purpose}
                                                onChange={(e) => setFieldValue('purpose', e.target.value)}
                                                fullWidth
                                            />
                                            <Box sx={{ marginTop: '5px' }}>
                                                {errors.purpose && <CustomErrorText errorMessage={errors.purpose} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container sx={{ display: 'flex', gap: '10px', flexWrap: 'nowrap' }}>
                                        <Grid item xs={12}>
                                            <DragAndDrop
                                                setFileData={(file) => setFieldValue('receipt', file)}
                                                errorMessage={"Please select a valid PDF, JPG, or PNG file."}
                                                allowedFilesText={"(Only PDF, JPG, or PNG files will be accepted)"}
                                                acceptedFileTypes={{
                                                    'application/pdf': ['.pdf'],
                                                    'image/jpeg': ['.jpg', '.jpeg'],
                                                    'image/png': ['.png'],
                                                }}
                                                setFileError={setFileError}
                                            />
                                            <Box sx={{ marginTop: '5px' }}>
                                                {errors.receipt && <CustomErrorText errorMessage={errors.receipt} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        border: "2px solid #6ECA35",
                                        ":hover": {
                                            border: "2px solid #6ECA35",
                                        },
                                    }}
                                    type='submit'
                                >
                                    {!loading ? 'Submit' : <CircularProgress color="secondary" size={26} />}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                )}
            </Formik>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Reimbursement;
