import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { GetComplaintsByStaffId } from '../../../services/staff_service';
import dayjs from 'dayjs';
import DataGridComp from '../../../components/common/DataGridComp';

const ViewComplaints = ({ staffID }) => {

    const [rows, setRows] = useState([]);
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
        },
        {
            field: 'telephone',
            headerName: 'Telephone',
            width: 100,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },       
        {
            field: 'i_am_a',
            headerName: 'I am a',
            flex: 1,
        },
        {
            field: 'are_you_making_this_complaint',
            headerName: 'Are you making this complaint',
            flex: 1,
        },
        {
            field: 'help_required',
            headerName: 'Help Required',
            flex: 1,
        },
        {
            field: 'complaints_detail',
            headerName: 'Complaint Detail',
            flex: 1,
        },
    ];


    // GraphQL Queries
    const [getComplaintsByStaffIdFunc, getIncidentReportsByStaffIdLoading, getIncidentReportsByStaffIdError] = GetComplaintsByStaffId()

    const getIncidentReportsByStaffId = async () => {

        const response = await getComplaintsByStaffIdFunc({
            "variables": {
                "filters": {
                    "staff_id": {
                        "id": {
                            "eq": staffID,
                        }
                    }
                }
            },
        });        

        if (response?.data.complaintRequests.data.length > 0) {
            const rows = response?.data.complaintRequests.data.map((complaint, index) => {           
                const { complaint_date, first_name, last_name, telephone, email, i_am_a, are_you_making_this_complaint, help_required, complaints_detail } = complaint.attributes;
                return {
                    id: index + 1,
                    complaint_date: complaint_date,
                    name: `${first_name} ${last_name}`,
                    telephone: telephone,
                    email: email,
                    i_am_a: i_am_a,
                    are_you_making_this_complaint: are_you_making_this_complaint,
                    help_required: help_required,
                    complaints_detail: complaints_detail,
                }
            })            
            setRows(rows)
        }
    }

    useEffect(() => {
        getIncidentReportsByStaffId();
    }, [])

    if (staffID === null) {
        return <div>Loading...</div>;
    }

    return (
        <Box
            sx={{
                padding: '25px',
            }}
        >
            <Grid container sx={{
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Grid item xs={11}>
                    <DataGridComp
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewComplaints;