// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Calendar.module.css */

/* Style FullCalendar events */
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: 100%;
}

:root {    
    --fc-button-bg-color: #35be00;
    --fc-button-border-color: #35be00;
    --fc-button-hover-bg-color: #2da000;
    --fc-button-hover-border-color: #35be00;
    --fc-button-active-bg-color: #2da000;
    --fc-button-active-border-color: #2da000;
    --fc-today-bg-color:#3eda0052;
}`, "",{"version":3,"sources":["webpack://./src/App/components/common/calendar.module.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,8BAA8B;AAC9B;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,iCAAiC;IACjC,mCAAmC;IACnC,uCAAuC;IACvC,oCAAoC;IACpC,wCAAwC;IACxC,6BAA6B;AACjC","sourcesContent":["/* Calendar.module.css */\n\n/* Style FullCalendar events */\n:global(.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard) {\n    height: 100%;\n}\n\n:global(:root) {    \n    --fc-button-bg-color: #35be00;\n    --fc-button-border-color: #35be00;\n    --fc-button-hover-bg-color: #2da000;\n    --fc-button-hover-border-color: #35be00;\n    --fc-button-active-bg-color: #2da000;\n    --fc-button-active-border-color: #2da000;\n    --fc-today-bg-color:#3eda0052;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
