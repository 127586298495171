import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Rating, Select, Slide, Snackbar, Stack, TextField, Tooltip, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector, useDispatch } from "react-redux";
import { createdId, DynamicStaffOnboard } from "../../../../middleware/reducers/staff_onboarding_reducer";
import _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateContactDetailForStaff, CreateDocumentsProfessionalList, CreateEmergencyContactDetailForStaff, CreateNewStaff, CreateNewUserForLogin, CreateStaffAnswerLovsReference, CreateStaffPaymentDetail, CreateStaffQuestionSingleAnswerRef, UploadNewDocument, CreateDocumentLink, GetDocumentProfessionalLists } from "../../../../services/staff_service";
import { CreateSubAdmin } from '../../../../services/staff_service'
import moment from "moment";
import { UpdateStaffDetail } from "../../../../services/admin_service";
import { calculateAge } from "../../../../components/functions"
import { setLoadingState } from "../../../../middleware/reducers/loading_state_reducer";
import { setCategoryStep } from "../../../../middleware/reducers/category_step_reducer";

import { API, AUTH_TOKEN } from "../../../../config/constant.js";
import axios from "axios";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const DependentInput = ({ data, match, handleChangeValueInputs }) => {
  const filter = data?.find((e) => e.title.includes(match))
  return (
    <TextField
      type={filter.type}
      key={filter.title + filter.id}
      id={filter.title}
      htmlFor={filter.title}
      required={filter.required}
      multiline={filter.multiline}
      label={filter.title}
      value={filter.value}
      sx={{ m: 1, width: filter.width }}
      onChange={(e) => handleChangeValueInputs(e, filter.id)}
    />
  )
}

const AUTHENTICATED_USER = 1;
const STAFF_USER_TYPE = 1;

export const DynamicInputsStaff = ({ categoryTitle, inputsList, inputType, questionList, documents, schadsAwards, duplicateInputField, setError }) => {

  const width = () => {
    if (window.screen.width > 1600) {
      return window.screen.width / 5.2
    }
    if (window.screen.width > 600 && window.screen.width < 1600) {
      return window.screen.width / 4
    } else {
      return window.screen.width / 1.3
    }
  }

  const [screenWidth, setScreenWidth] = useState(width);

  useEffect(() => {
    if (window.screen) {
      setScreenWidth(width);
    }
  }, [window.screen]);

  const [selectedSchadAward, setSelectedSchadAward] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [documentNames, setDocumentNames] = useState(null);
  const [listOfCategory, setListOfCategory] = useState([]);
  const [listOfDocsName, setListOfDocsName] = useState(null);
  const [listOfSelectedDoc, setListOfSelectedDoc] = useState([]);
  const [dynamicInputs, setDynamicInputs] = useState(null);
  const [dynamicQuestions, setDynamicQuestions] = useState(null);
  const dispatch = useDispatch()
  const _saving_staff_list = useSelector((state) => state._staff_onboarding_module.dynamic_staff_onboard);
  const _database_id = useSelector((state) => state._staff_onboarding_module.created_id);
  const _category_step = useSelector((state) => state._category_step_state.category_step);

  // /// /// ==================================
  const [apiCreateNewStaff, loading_staff, error_staff] = CreateNewStaff(); // create new staff
  const [apiCreateNewLogin, loading_login, error_login] = CreateNewUserForLogin() // create new login user
  const [createSubAdminFunc, CreateSubAdminLoading_login, CreateSubAdminError_login] = CreateSubAdmin() // create new login user
  const [apiCreateStaffAnsLov, loading_answer, error_answer] = CreateStaffAnswerLovsReference(); // create drop down value
  const [apiCreateStaffContact, loading_contact, error_contact] = CreateContactDetailForStaff(); // create contact details
  const [apiCreateStaffEmergency, loading_emergency, error_emergency] = CreateEmergencyContactDetailForStaff(); // create emergency details
  const [apiCreateStaffStaffQuestion, loading_question, error_question] = CreateStaffQuestionSingleAnswerRef(); // create staff question
  const [apiCreatePaymentData, loading_payment, error_payment] = CreateStaffPaymentDetail();
  const [apiUploadNewDocs, loading_doc, error_doc] = UploadNewDocument();
  const [apiDocumentsList, loading_doc_list, error_doc_list] = CreateDocumentsProfessionalList();
  const [createDocumentLink, loading, error] = CreateDocumentLink();
  const [updateStaffDetails, loading_update, error_update] = UpdateStaffDetail()
  const [getDocumentProfessionalLists, getDocumentProfessionalLists_loading] = GetDocumentProfessionalLists();


  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });

  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  useEffect(() => {
    if (documents) {
      const finalizedAarray = [];
      const useData = documents.audit_documents?.data;
      const id_permit = {
        title_type: "ID / Permit",
        document_list: []
      };
      const training = {
        title_type: "Training",
        document_list: []
      };
      const certificates = {
        title_type: "Certificates",
        document_list: []
      };
      const miscellaneous = {
        title_type: "Miscellaneous",
        document_list: {}
      };
      for (const key in useData) {
        const title = useData[key]?.attributes?.audit_module?.data?.attributes?.title;
        const active = useData[key]?.attributes?.audit_module?.data?.attributes?.active;
        const titleCategory = useData[key]?.attributes?.audit_category?.data?.attributes?.title;
        const activeCategory = useData[key]?.attributes?.audit_category?.data?.attributes?.active;
        const titleType = useData[key]?.attributes?.audit_type?.data?.attributes?.title;
        const activeType = useData[key]?.attributes?.audit_type?.data?.attributes?.active;
        const docTitle = useData[key]?.attributes?.audit_document_name?.data?.attributes?.title;
        const docActive = useData[key]?.attributes?.audit_document_name?.data?.attributes?.active;
        let finalObj = {
          module: title,
          categoryTitle: titleCategory,
          type: titleType,
          document_name: docTitle
        }
        if (titleType?.includes("ID / Permit")) {
          id_permit.document_list.push(finalObj)
        }
        if (titleType?.includes("Training")) {
          training.document_list.push(finalObj)
        }
        if (titleType?.includes("Certificates")) {
          certificates.document_list.push(finalObj)
        }
        if (titleType?.includes("Miscellaneous")) {
          miscellaneous.document_list = finalObj;
        }
      };
      finalizedAarray.push(id_permit)
      finalizedAarray.push(training)
      finalizedAarray.push(certificates)
      finalizedAarray.push(miscellaneous)
      const categoryArray = []
      for (const key in finalizedAarray) {
        categoryArray.push({
          title: finalizedAarray[key].title_type
        });
      }
      setListOfCategory(categoryArray)
      setListOfDocsName(finalizedAarray)
    }
  }, [documents])

  useEffect(() => {
    if (selectedDocType) {
      const filter = listOfDocsName?.find((e) => e.title_type === selectedDocType);
      if (filter) {
        setDocumentNames(filter)
      }
    }
  }, [selectedDocType])

  useEffect(() => {
    if (inputsList?.length) {
      const copy = [];
      const filteredDuplicateInputList = duplicateInputField.map(inputVal => (inputVal?.attributes?.title));
      let required = null;
      let active = null;
      let multiline = null;
      let disabled = null;

      for (const key in inputsList) {
        if (filteredDuplicateInputList.includes(inputsList[key]?.attributes?.input_name)) {
          const filteredInputList = duplicateInputField?.filter(inputVal => inputVal?.attributes?.title === inputsList[key]?.attributes?.input_name)[0];

          disabled = filteredInputList.attributes?.disabled;
          required = filteredInputList.attributes?.required;
          active = filteredInputList.attributes?.active;
          multiline = filteredInputList.attributes?.multiline;
        }
        else {
          disabled = inputsList[key].attributes?.disabled;
          required = inputsList[key].attributes?.required;
          active = inputsList[key].attributes?.active;
          multiline = inputsList[key].attributes?.multiline;
        }

        const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
        const label = inputsList[key].attributes?.input_name;
        const have_child_option = inputsList[key].attributes?.have_child_option;
        const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;
        const placeholder = inputsList[key].attributes?.placeholder;

        if (active) {
          let obj = {
            title: label,
            value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : "",
            width: screenWidth,
            type: type,
            required: required,
            multiline: multiline,
            error: null,
            id: key,
            disabled: disabled,
            placeholder: placeholder
          }
          if (have_child_option) {
            obj.options = drop_down_options;
            obj.value = drop_down_options[0].attributes.title;
          }
          copy.push(obj)
        }
      }
      setDynamicInputs(copy);
      handleStateValueWithDynamicForm(copy)
    }
    if (questionList?.length) {
      const copy = [];
      for (const key in questionList) {
        let object = {}
        object.title = questionList[key]?.attributes?.question;
        object.active = questionList[key]?.attributes?.active;
        object.width = screenWidth;
        object.multiline = false;
        object.error = null;
        object.id = key;
        const answer_type = questionList[key]?.attributes?.answer_type[0];
        if (answer_type?.__typename?.includes("Text")) {
          object.type = "Text";
          object.value = answer_type?.details_value;
          object.required = false;
          object.multiline = object.title.includes("Comments") ? true : false
        }
        if (answer_type?.__typename?.includes("Single")) {
          object.type = "Select";
          object.value = "No";
          object.required = false;
          object.options = answer_type?.drop_down_option_lovs?.data
        }
        copy.push(object)
      }
      setDynamicQuestions(copy)
      handleStateValueWithDynamicQuestions(copy)
    }
  }, [])

  const handleChangeValueInputs = (e, index) => {

    try {
      const copy = [...dynamicInputs];

      if (copy[index].title === 'Date of Birth') {
        const DOB = calculateDOB(e.target.value);

        copy[index + 1].value = DOB;
      }
      copy[index].value = e.target.value;

      if (copy[index].error !== null) {
        copy[index].error = null;
      }
      setDynamicInputs(copy);
    } catch (error) {
      console.error("error", error);
    }
  }

  const calculateDOB = (dateOfBirth) => {
    const today = moment();
    const birthDate = moment(dateOfBirth, "YYYY-MM-DD");
    let age = today.diff(birthDate, "years");

    // Adjust if the current date hasn't reached the birth date this year
    if (today.isBefore(birthDate.add(age, 'years'))) {
      age--;
    }
    return age;
  }

  const dispatchData = async (data) => {
    try {
      const copy_category = categoryTitle?.toLowerCase().split(' ').join('_');
      const copy_saving_staff_list = { ..._saving_staff_list };
      copy_saving_staff_list[copy_category] = data;
      dispatch(DynamicStaffOnboard(copy_saving_staff_list));
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleSnacksApper = (title, message, severity) => {
    setSnack({
      ...snacks,
      open: true,
    });
    // / // // // display message
    setErrorSnackMessage({
      ...errorSnackMessage,
      title,
      message,
      severity
    })
  }
  const convertDataIntoSimplifyObjects = (data) => {
    let inputs = {};
    let selectedOptions = {};
    for (const key in data) {
      if (!data[key]?.options) {
        inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
      }
      if (data[key]?.options) {
        selectedOptions[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = {
          title: data[key].value,
          id: data[key]?.options.find((e) => e.attributes.title === data[key].value)?.id
        }
      }
    }
    return { inputs, selectedOptions }
  }
  const savingDataByCategoryInDB = async (data) => {
    try {
      let staffDetail;
      const fCategory = _category_step.trim();
      if (fCategory === "General Information (Staff)") {
        dispatch(setLoadingState('loading'))
        const medic_card = data?.find((e) => e.title.includes("Medicare Card"))?.value;
        const driving_number = data?.find((e) => e.title.includes("Driving License"))?.value;
        const validate_DOB = data?.find((e) => e.title.includes("Date of Birth"))?.value || "2000-05-05";
        const validate_RED = data?.find((e) => e.title.includes("NDIS Expire Date"))?.value || "2035-05-05";
        // /// // createing object for query
        const object = {
          first_name: data?.find((e) => e.title.includes("First Name"))?.value,
          last_name: data?.find((e) => e.title.includes("Last Name"))?.value,
          date_of_birth: validate_DOB,
          ndis_clearance_number: data?.find((e) => e.title.includes("NDIS Clearance Number"))?.value,
          registration_expiry_date: validate_RED,
          police_check: data?.find((e) => e.title.includes("Police Check"))?.value,
          language_spoken: data?.find((e) => e.title.includes("Language Spoken"))?.value,
          religion: data?.find((e) => e.title.includes("Religion"))?.value,
          email: data?.find((e) => e.title.includes("Email"))?.value,
          age: String(calculateAge(validate_DOB)),
          medicare_number: data?.find((e) => e.title.includes("Medicare Number"))?.value,

          // handles true / false
          do_you_have_driving_license: data?.find((e) => e.title.includes("Do you have driving license?"))?.value === "Yes" ? true : false,
        }

        // validate field is not empty
        if (object.do_you_have_driving_license) {
          if (driving_number) {
            object.driving_license = driving_number
          } else {
            throw { message: "Driving license must be 6 or 10 digits long", code: 141 }
          }
        }

        const response = await apiCreateNewStaff({
          variables: object
        })

        // // // handle response for basic info
        if (response?.data) {
          staffDetail = response?.data?.createStaffDetail?.data;
          const id = response?.data?.createStaffDetail?.data.id;
          // // // // saving id and email in to state
          dispatch(createdId({ id: staffDetail.id, email: staffDetail?.attributes?.email }))
          // // // // filtering lovs values 
          const list = data?.filter((e) => e.options && !e.title.includes("?"))
          if (list) {
            let count = 0;
            for (const key1 in list) {
              const objectLov = {
                staff_detail_id: staffDetail.id,
                module: "Staff Onboard",
                module_Id: Number(id),
                category: fCategory,
                input_name: list[key1]?.title,
                selected_option_value: list[key1]?.value
              }
              // // // seding data to database with mutation
              const lovRespose = await apiCreateStaffAnsLov({
                variables: objectLov
              })
              if (lovRespose?.data) {
                count++
              }
            }
            if (count === list.length) {              
              const responseRegister = await createSubAdminFunc({
                variables: {
                  "username": object.first_name + object.last_name,
                  "email": object.email,
                  "password": "Admin!234",
                  "user_type": STAFF_USER_TYPE,
                  "role": AUTHENTICATED_USER,
                }
              });
              if (responseRegister?.data) {
                // / // // // display message
                dispatch(setLoadingState('completed'));
                dispatchData(data);
                handleSnacksApper("Staff Onboarding", fCategory + " has been created, client id is: " + staffDetail?.id, "success")
              }
            }
          }
        }
      }
      if (fCategory === "Contact Information" && _database_id?.id) {
        dispatch(setLoadingState('loading'))
        // /// // creating object for query
        const object = {
          email: data?.find((e) => e.title.includes("Email"))?.value,
          home_phone: data?.find((e) => e.title.includes("Home Phone"))?.value,
          mobile_phone: data?.find((e) => e.title.includes("Mobile Phone"))?.value,
          work_phone: data?.find((e) => e.title.includes("Work Phone"))?.value,
          address: data?.find((e) => e.title.includes("Address"))?.value,
          post_code: data?.find((e) => e.title.includes("Post Code"))?.value,
          staff_detail_id: _database_id?.id,
        }
        // // // seding data to database with mutation
        const response = await apiCreateStaffContact({
          variables: object
        })
        // / // // handle response for basic info
        if (response?.data) {
          const id = response?.data?.createContactDetail?.data?.id;
          const list = data?.filter((e) => e.title.includes("Country") || e.title.includes("State") || e.title.includes("Town / Suburb"))
          if (list) {
            let count = 0;
            for (const key1 in list) {
              // // // creating object for mutation
              const objectLov = {
                staff_detail_id: _database_id?.id,
                module: "Staff Onboard",
                module_Id: Number(id),
                category: fCategory,
                input_name: list[key1]?.title,
                selected_option_value: list[key1]?.value
              }
              // // // seding data to database with mutation
              const lovRespose = await apiCreateStaffAnsLov({
                variables: objectLov
              })
              if (lovRespose?.data) {
                count++
              }
            }
            if (count === list.length) {
              /// // // // handle messages
              dispatch(setLoadingState('completed'))
              dispatchData(data);
              handleSnacksApper("Staff Onboarding", fCategory + " has been created, id is: " + _database_id?.id || id, "success")
            }
          }
        }
      }
      if (fCategory === "Emergency Contact Information" && _database_id?.id) {
        dispatch(setLoadingState('loading'))
        const simplify = convertDataIntoSimplifyObjects(data);
        simplify.inputs.staff_detail_id = _database_id?.id;
        // // // // seding data to database with mutation
        const response = await apiCreateStaffEmergency({
          variables: simplify.inputs
        })
        // / // // handle response for basic info
        if (response?.data) {
          const id = response?.data?.createEmergencyContactDetail?.data?.id;
          /// // // // handle messages
          dispatch(setLoadingState('completed'));
          dispatchData(data);
          handleSnacksApper("Staff Onboarding", fCategory + " has been created, id is: " + _database_id?.id || id, "success")
        }
      }
      if (fCategory === "Other Information" && _database_id?.id) {
        dispatch(setLoadingState('loading'))
        let count = 0;
        const simplify = convertDataIntoSimplifyObjects(data);

        const response = await updateStaffDetails({
          variables: {
            "id": _database_id?.id,
            "data": simplify.inputs
          }
        });

        if (response) {
          const filteredData = data.filter(item => item.title !== "Allergy Detail");

          for (const key1 in filteredData) {
            const filter = filteredData[key1]?.options?.find((e) => e.attributes.title === filteredData[key1]?.value);
            const objectQuestion = {
              "staff_detail_id": _database_id?.id,
              "module": "Staff Onboarding",
              "category": fCategory,
              "question_title": filteredData[key1]?.title,
              "answer_type": [
                {
                  "__typename": "ComponentAnswersSingleAnswer",
                  "__component": "answers.single-answer",
                  "active": true,
                  "drop_down_option_lovs": filter?.id
                }
              ]
            }
            // // // // // seding data to database with mutation
            const lovRespose = await apiCreateStaffStaffQuestion({
              variables: objectQuestion
            })
            if (lovRespose?.data) {
              count++
            }
          }
          if ((count + 1) === data.length) {
            /// // // // handle messages
            dispatch(setLoadingState('completed'));
            dispatchData(data);
            handleSnacksApper("Staff Onboarding", fCategory + " has been created, id is: " + _database_id?.id, "success")
          }
        }

      }
      if (fCategory === "Payment Details" && _database_id?.id) {
        dispatch(setLoadingState('loading'))
        const reason_notes = data?.find((e) => e.title.includes("Reason / Notes"))?.value;
        const object = {
          account_name: data?.find((e) => e.title.includes("Account Name"))?.value,
          bsb_number: data?.find((e) => e.title.includes("BSB Number"))?.value,
          account_number: data?.find((e) => e.title.includes("Account Number"))?.value,
          superannuation_name: data?.find((e) => e.title.includes("Superannuation Name"))?.value,
          member_number: data?.find((e) => e.title.includes("Member Number"))?.value,
          tax_file_number: data?.find((e) => e.title.includes("Tax File Number"))?.value,
          tax_free_threshold: data?.find((e) => e.title.includes("Do you want to claim a tax free threshold?"))?.value === "Yes" ? true : false,
          staff_detail_id: _database_id?.id,
        }
        if (!object.tax_free_threshold) {
          if (reason_notes) {
            object.reason_notes = reason_notes;
          } else {
            throw { message: "Please define the reason.!", code: 141 }
          }
        }
        // // // seding data to database with mutation
        const response = await apiCreatePaymentData({
          variables: object
        })
        // / // // handle response for basic info
        if (response?.data) {
          const id = response?.data?.createStaffPaymentDetail?.data?.id;
          dispatch(setLoadingState('completed'));
          dispatchData(data);
          handleSnacksApper("Staff Onboarding", fCategory + " has been created, id is: " + _database_id?.id || id, "success")
        }
      }
      if (fCategory === "Hourly Rate" && _database_id?.id) {
        dispatch(setLoadingState('loading'))
        // Filter schadsAwards once
        const selectedAward = schadsAwards.find(schadsAward => schadsAward.level === selectedSchadAward);

        // Simplify data transformation with reduce       
        const simplify = Array.isArray(data) && data.length > 0
          ? data.reduce((acc, val) => {
            const key = `${val.day_type?.replaceAll(' ', '_').toLowerCase()}_rate`;
            acc[key] = val.total_hourly_rate.toString();
            return acc;
          }, {})
          : {};


        // Add schads_awards to simplify
        if (selectedAward) {
          simplify.schads_awards = selectedAward.schad_award_id;
        }

        // Update staff details

        const response = await updateStaffDetails({
          variables: {
            id: _database_id?.id,
            data: simplify,
          },
        });
        if (response?.data) {
          dispatch(setLoadingState('completed'));
          dispatchData(data);
          handleSnacksApper("Staff Onboarding", fCategory + " has been Updated");
        }
      }
      if (fCategory === "Upload Documents" && _database_id?.id) {
        // dispatch(setLoadingState('loading'))

        if (data) {
          let count = 0;
          for (const key in data) {
            if (!data[key].attributes.document_link) {
              const formData = new FormData();
              formData.append('files', data[key].attributes.file);
              const response = await axios.post(
                `${API}/upload`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${AUTH_TOKEN}`, // Add Authorization header
                  },
                  onUploadProgress: (progressEvent) => {
                    // Track upload progress here
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  },
                }
              );

              if (response?.data) {
                const { name, url, mime } = response?.data[0];
                const createDocumentLinkResponse = await createDocumentLink({
                  variables: {
                    file_name: name,
                    file_type: mime,
                    file_url: url,
                    staff_detail: _database_id?.id,
                  }
                })
                let docId = createDocumentLinkResponse?.data.createDocumentLink.data.id;

                const object = {
                  module: data[key].attributes.module,
                  type: data[key].attributes.type,
                  document_name: data[key].attributes.document_name,
                  staff_details_id: _database_id?.id,
                  expiry_date: data[key].attributes.expiry_date,
                  active: true,
                  documents_description: data[key].attributes.description,
                  document_link: docId
                }
                const responseDoc = await apiDocumentsList({
                  variables: object
                })
                if (responseDoc?.data) {
                  count++;
                }
                if (count === data.length) {
                  // display message
                  dispatch(setLoadingState('completed'))
                  getUploadedDocumentsByStaffId();
                  handleSnacksApper("Staff Onboarding", fCategory + " has been created")
                }
              }
            }
          }
        }
      }
      if (!staffDetail && !_database_id) {
        throw { message: "Cannot proceed. general information is missing." }
      }
    } catch (error) {
      let message = "";
      if (error?.message || error_staff || error_answer || error_contact || error_emergency || error_question || error_update) {
        const errors = error_staff || error_answer || error_contact || error_emergency || error_question || error_update;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        if (error?.message) {
          message = error?.message;
        }
        dispatch(setLoadingState('error'))
        handleSnacksApper(categoryTitle?.toUpperCase(), message, "error")
      }
    }
  }


  const handleStateValueWithDynamicForm = (data) => {
    try {
      const copy = [...data];
      const copy_category = categoryTitle?.toLowerCase().split(' ').join('_');
      const copy_saving_staff_list = { ..._saving_staff_list };
      const _dataCategory = copy_saving_staff_list[copy_category];
      if (_dataCategory) {
        for (const key in copy) {
          copy[key].value = _dataCategory[key].value
        }
        setDynamicInputs(copy);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleStateValueWithDynamicQuestions = (data) => {
    try {
      const copy = [...data];
      const copy_category = categoryTitle?.toLowerCase().split(' ').join('_');
      const copy_saving_staff_list = { ..._saving_staff_list };
      const _dataCategory = copy_saving_staff_list[copy_category];
      if (_dataCategory) {
        for (const key in copy) {
          const findData = _dataCategory.find((e) => e.title === copy[key].title)
          if (findData) {
            copy[key].value = findData.value;
          }
        }
        setDynamicQuestions(copy)
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const createDocumentList = (file) => {
    try {
      const listOfDocs = [...listOfSelectedDoc]
      const copy = [...dynamicInputs];
      let object = {
        attributes: {
          module: selectedDoc?.module,
          category: selectedDoc?.category,
          type: selectedDoc?.type,
          document_name: selectedDoc?.document_name,
          file: file,
          document_link: null
        }
      }
      for (const key in copy) {
        object.attributes[copy[key].title?.toLowerCase().split(' ').join('_')] = copy[key].value;
        // // // // do empty dynamic inputs
        copy[key].value = "";
      }
      setDynamicInputs(copy);
      listOfDocs.push(object);
      setSelectedDoc(null);
      setSelectedDocType("");
      setListOfSelectedDoc(listOfDocs);
    } catch (error) {
      console.error("error", error)
    }
  }

  useEffect(() => {
    if (_category_step &&
      !Object.keys(_saving_staff_list || '').includes(_category_step?.toLowerCase().split('/ ').join('')?.split(' ').join('_'))
    ) {
      if (dynamicInputs && !dynamicQuestions) {
        const copy = [...dynamicInputs];
        let findError = false;
        const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
        for (const key in copy) {
          if (
            (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
            (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
            (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
            (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10)) ||
            (copy[key].type.toLowerCase() === "time" && copy[key].required && (copy[key].value === ""))
          ) {
            copy[key].error = "Required";
            findError = true;
            setDynamicInputs(copy);
            dispatch(setLoadingState('error'))
          }
        }

        if (findError === false) {
          const fCategory = _category_step.trim();
          if (fCategory === "Upload Documents") {
            setError(false);
            const fCopy = _.cloneDeep(listOfSelectedDoc);
            savingDataByCategoryInDB(fCopy)
          }
          else {
            setError(false);
            const fCopy = _.cloneDeep(dynamicInputs);
            savingDataByCategoryInDB(fCopy)
          }
        }
        else {
          dispatch(setCategoryStep(null));
        }

      }
      if (dynamicQuestions && !dynamicInputs) {
        const fCopy = _.cloneDeep(dynamicQuestions);
        savingDataByCategoryInDB(fCopy)
      }
      if (dynamicQuestions && dynamicInputs) {
        const copy = [...dynamicInputs];
        let findError = false;
        const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
        for (const key in copy) {
          if (
            (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
            (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
            (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
            (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10))
          ) {
            copy[key].error = "Required";
            findError = true;
            setDynamicInputs(copy);
            dispatch(setLoadingState('error'))
          }
        }

        if (findError === false) {
          setError(false);
          const fCopy = _.cloneDeep([...dynamicInputs, ...dynamicQuestions]);
          savingDataByCategoryInDB(fCopy)
        }
        else {
          dispatch(setCategoryStep(null));
        }
      }

      if (selectedSchadAward) {
        savingDataByCategoryInDB(schadsAwards?.filter(val => (val.level === selectedSchadAward))[0]?.rates_per_hour)
      }
    }

  }, [_category_step])

  const getUploadedDocumentsByStaffId = async () => {
    const getDocumentProfessionalListsResp = await getDocumentProfessionalLists({
      variables: {
        "filters": {
          "staff_details_id": {
            "id": {
              "eq": _database_id?.id,
            }
          }
        }
      }
    });
    setListOfSelectedDoc(getDocumentProfessionalListsResp?.data?.documentsProfessionalLists?.data)
  }

  useEffect(() => {
    if (categoryTitle === "Upload Documents") {
      getUploadedDocumentsByStaffId();
    }

  }, [categoryTitle])

  return (
    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
      {loading_staff || loading_login || loading_answer || loading_contact || loading_emergency || loading_question || loading_payment || loading_doc || loading_doc_list || loading_update ? (
        <Box sx={{
          background: "rgba(0,0,0,0.3)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress color="primary" size={35} />
        </Box>
      ) : null}

      <List sx={{ width: '95%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
        {listOfSelectedDoc?.map((value, index) => {
          return (
            <ListItem
              sx={{ m: 1, }}
              key={value?.document_name + index}
              component={Paper}
              elevation={1}
              secondaryAction={
                <Stack direction={"row"} spacing={2} sx={{ alignItems: 'center' }}>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: "gray",
                    }}
                  >
                    {value?.attributes.module}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: "gray",
                    }}
                  >
                    {value?.attributes.category}
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: "gray",
                    }}
                  >
                    {value?.attributes.type}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      const copy = [...listOfSelectedDoc];
                      const list = copy?.filter((e) => e.document_name !== value?.attributes.document_name);
                      setListOfSelectedDoc(list)
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemIcon>
                {index + 1}
              </ListItemIcon>
              <ListItemText
                primary={value?.attributes.document_name}
                secondary={
                  <>
                    {value?.attributes.expiry_date && (
                      <Typography color={"seconadary.text1"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        Expiry Date: {value?.attributes.expiry_date}
                      </Typography>
                    )}
                    {value?.attributes.description && (
                      <Typography color={"seconadary.text1"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        Description: {value?.attributes.description}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          )
        })}
      </List>

      {/* document inputs with fields */}
      {listOfCategory?.length ? (
        <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
          <TextField
            select={true}
            label={"Please Select Document Type"}
            id={"Please Select Type"}
            htmlFor={"Please Select Type"}
            key={"Please Select Type 09"}
            required={true}
            sx={{ m: 1, width: screenWidth }}
            value={selectedDocType}
            onChange={(e) => {
              setSelectedDocType(e.target.value)
              setSelectedDoc(null)
            }}
          >
            {listOfCategory?.map((option) => (
              <MenuItem key={option?.title} value={option?.title}>
                {option?.title}
              </MenuItem>
            ))}
          </TextField>
          {selectedDocType && selectedDocType?.toLowerCase() !== "miscellaneous" && (
            <TextField
              select={true}
              label={"Please Select Document Name"}
              id={"Please Select Name"}
              htmlFor={"Please Select Name"}
              key={"Please Select Name 09"}
              required={true}
              sx={{ m: 1, width: screenWidth }}
              value={selectedDoc?.document_name}
              onChange={(e) => {
                const find = documentNames?.document_list?.find((en) => en.document_name === e.target.value);
                if (find) {
                  setSelectedDoc(find);
                }
              }}
            >
              {Array.isArray(documentNames?.document_list) && documentNames?.document_list?.map((option) => {
                return (
                  <MenuItem key={option.document_name} value={option.document_name}>
                    {option.document_name}
                  </MenuItem>
                )
              })}
            </TextField>
          )}
          {selectedDocType?.toLowerCase() === "miscellaneous" && (
            <TextField
              type={"text"}
              key={"Please write document name 09"}
              id={"Please write document name"}
              htmlFor={"Please write document name"}
              required={true}
              multiline={false}
              label={"Please write document name"}
              value={selectedDoc?.document_name}
              sx={{ m: 1, width: screenWidth }}
              onChange={(e) => {
                const copy = { ...documentNames?.document_list }
                copy.document_name = e.target.value
                setSelectedDoc(copy)
              }}
            />
          )}
          {selectedDoc && (
            <Button
              color={"primary"}
              key={"09"}
              sx={{
                m: 1,
                width: screenWidth,
                padding: "12px 12px",
                fontSize: "1.1em",
                bgcolor: "#ffffff",
                fontFamily: "Roboto-Medium",
                textTransform: "capitalize",
                color: "#000000",
                border: "2px solid #6ECA35",
                ":hover": {
                  border: "2px solid #6ECA35",
                }
              }}
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon color="primary" />}
            >
              {selectedDoc?.document_name?.length > 30 ? selectedDoc?.document_name?.slice(0, 30) + "..." : selectedDoc?.document_name}
              <VisuallyHiddenInput
                webkitdirectory
                key={"document-name-09"}
                type="file"
                onChange={(e) => {
                  createDocumentList(e.target.files[0])
                }}
              />
            </Button>
          )}
        </Box>
      ) : null}

      {/* inputs and select type */}
      {categoryTitle === 'Hourly Rate' && (
        <>
          <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', mb: 3 }}>
            <TextField
              select={true}
              label={"Select Schads Awards"}
              id={"Schads Awards"}
              htmlFor={"Schads Awards"}
              key={"Schads Awards"}
              required={true}
              sx={{ m: 1, width: '50%' }}
              value={selectedSchadAward}
              onChange={(e) => setSelectedSchadAward(e.target.value)}
            >
              {schadsAwards?.map(val => {
                return (
                  <MenuItem value={val.level}>{val.level}</MenuItem>
                )
              })}
            </TextField>
          </Box>
          <Box>
            {schadsAwards?.filter(val => (val.level === selectedSchadAward)).map((val, index) => {
              return (
                val.rates_per_hour?.map(value => {
                  return (
                    <TextField
                      type={'number'}
                      key={value.day_type + index}
                      required={value.required}
                      label={`${value.day_type} Rates`}
                      disabled={true}
                      value={value.total_hourly_rate?.toFixed(2) ?? ''}
                      sx={{ m: 1 }}
                    />
                  )
                })
              )
            })}
          </Box>
        </>
      )}

      <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
        {dynamicInputs?.map((value, index) => {
          if ((value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") && !value.title?.includes("Reason") && !value.title?.includes("Card") && !value.title?.includes("Allergy Detail") && !value.title?.includes("Driving License")) {
            return (
              <TextField
                type={value.type}
                key={value.title + index}
                id={value.title}
                htmlFor={value.title}
                required={value.required}
                multiline={value.multiline}
                label={value.title}
                disabled={value.disabled}
                placeholder={value.placeholder}
                value={value.value}
                sx={{ m: 1, width: value.width }}
                helperText={value.error ? value.error : ""}
                error={value.error ? true : false}
                onChange={(e) => handleChangeValueInputs(e, index)}
              />
            )
          }
          if (value?.type?.toLowerCase() === "select") {
            return (
              <TextField
                select={true}
                label={value.title}
                id={value.title}
                htmlFor={value.title}
                key={value.title + index}
                required={value.required}
                sx={{ m: 1, width: value.width }}
                value={value.value}
                helperText={value.error ? value.error : ""}
                error={value.error ? true : false}
                onChange={(e) => handleChangeValueInputs(e, index)}
              >
                {value.options.map((option) => (
                  <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                    {option?.attributes?.title}
                  </MenuItem>
                ))}
              </TextField>
            )
          }
        })}
        {/* Question answer */}
        {dynamicQuestions?.map((v, index) => {
          if (v?.active && v?.type?.includes("Text")) {
            return (
              <TextField
                type={v?.type?.toLowerCase()}
                key={v.title + index}
                id={v.title}
                htmlFor={v.title}
                required={v.required}
                multiline={v.multiline}
                label={v.title}
                value={v.value}
                sx={{ m: 1, width: v.width }}
                onChange={(e) => {
                  const copy = [...dynamicQuestions];
                  copy[index].value = e.target.value;
                  setDynamicQuestions(copy);
                }}
              />
            )
          }
          if (v?.active && v?.type?.includes("Select")) {
            return (
              <>
                <TextField
                  select={true}
                  label={v.title}
                  key={v.title + index}
                  id={v.title}
                  htmlFor={v.title}
                  required={v.required}
                  sx={{ m: 1, width: v.width }}
                  value={v.value}
                  onChange={(e) => {
                    const copy = [...dynamicQuestions]
                    copy[index].value = e.target.value;
                    setDynamicQuestions(copy);
                  }}
                >
                  {v?.options.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
                {(v.title?.includes("medical card") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Card"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("driving license") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Driving"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("claim a tax") && v.value === "No") && (<DependentInput data={dynamicInputs} match={"Reason"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("allergies") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Allergy Detail"} handleChangeValueInputs={handleChangeValueInputs} />)}
              </>
            )
          }
        })}
        <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "4%" }}>
          <Stack direction={"row"} spacing={2}>
            {/* {categoryTitle?.includes("Working Days (Start Time & End Time)") ? (
                            <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"} onClick={() => {
                                    savingDataAsPerCategory()
                                }}
                                    disabled={loading_staff || loading_update || loading_contact || loading_emergency || loading_question || loading_payment}
                                    variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                    {loading_staff || loading_update || loading_contact || loading_emergency || loading_question || loading_payment ? <CircularProgress color="secondary" size={26} /> : null}
                                </Button>
                            </Tooltip>
                            ) : null} */}
          </Stack>
        </Box>
      </Box>

      <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{ width: '100%' }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}