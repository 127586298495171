import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Collapse, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Person2Icon from '@mui/icons-material/Person2';
import BallotIcon from '@mui/icons-material/Ballot';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useLocation, useNavigate } from 'react-router-dom';
import ListOfClients from '../../components/list_of_clients';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import NewClientManagement from '../new_client_screen';
import { HeaderToolbar } from '../../components/header/header_toolbar';
import { clientDetailsId } from '../../middleware/reducers/history_reducers';
import { useDispatch, useSelector } from 'react-redux';

const drawerWidth = 70;

const ClientDashboard = (props) => {
    const navigate = useNavigate()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const _history = useSelector((state) => state._history.client_details_id);
    const [userSelected, setUserSelected] = React.useState(null);
    const dispatch = useDispatch()
    const userPermissions = useSelector((state) => state._permission_reducer.userPermission);
    const userType = useSelector((state) => state._permission_reducer.usersType);

    React.useEffect(() => {
        setUserSelected(_history)
    }, [_history]);

    const [options, setOptions] = React.useState([
        {
            option: "Clients",
            icon: <Person2Icon fontSize={"large"} />,
            active: true,
            screen: <ListOfClients />
        },
        {
            option: 'Client Onboarding',
            icon: <PersonAddRoundedIcon fontSize={"large"} />,
            active: false,
            screen: <NewClientManagement />
        },
    ]);

    React.useEffect(() => {
        if (location.state) {
            handleMenuItem("", location.state.activeOption)
        }
    }, [location]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleMenuItem = (option, i) => {
        const copy = [...options];
        for (const key in copy) {
            if (key === String(i)) {
                copy[key].active = true
            } else {
                copy[key].active = false
            }
        }
        setOptions(copy);
    };

    const checkPermission = (moduleName) => {
        if (userType === "Admin") return true;

        return userPermissions?.some(
            (permission) =>
                permission.attributes?.module_name === moduleName &&
                permission.attributes?.read
        );
    };

    React.useEffect(() => {
        if (options && !location.state) {
            const permissibleOptions = options.filter((val) => checkPermission(val.option));

            if (permissibleOptions.length > 0) {
                const firstOption = permissibleOptions[0].option;

                setOptions((prevOptions) =>
                    prevOptions.map((val) => ({
                        ...val,
                        active: val.option === firstOption, // Set active true for the first permissible option
                    }))
                );
            }
        }
    }, [userPermissions, location]);

    const drawer = (
        <div style={{ overflow: 'hidden', }}>
            <Toolbar onClick={() => navigate(-1)} sx={{ justifyContent: 'center' }}>
                <img alt="Logo" style={{ width: 40, objectFit: 'fitt' }} src="/assets/icons/logo-without-text.png" />
            </Toolbar>
            <Divider />
            <List sx={{ pt: 0 }}>
                {options.map((v, i) => {
                    const hasPermission = checkPermission(v.option); // Check permission for the module
                    if (!hasPermission) return null; // Skip rendering if no permission

                    return (
                        <ListItem
                            key={i}
                            disablePadding
                            sx={{ backgroundColor: v.active ? "#013447" : "#ffffff" }}
                        >
                            <ListItemButton onClick={() => handleMenuItem(v.option, i)}>
                                <Tooltip title={v.option} placement="right">
                                    <ListItemIcon
                                        style={{ color: v.active ? "#ffffff" : "#013447" }}
                                    >
                                        {v.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;
    const activeTab = options.find((e) => e.active)
    return (
        <Box sx={{ display: 'flex', overflow: "hidden", bgcolor: "#F5F7F6" }}>
            <CssBaseline />
            <AppBar
                color="primaryHeader"
                elevation={0}
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon color='primary' />
                    </IconButton>
                    <Typography color={"secondary"} sx={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                        {activeTab.option}
                    </Typography>
                    {userSelected && (
                        <Box sx={{ flexGrow: 1, justifyContent: "center", display: { xs: 'none', md: 'flex', lg: 'flex' }, }}>
                            <Stack direction={"row"} spacing={2}>
                                {/* <Button color={"primary"} variant="outlined" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "0.9em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Export as PDF
                                </Button>
                                <Button color={"primary"} variant="outlined" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "0.9em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Export as CSV
                                </Button> */}

                            </Stack>
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: 'none', md: 'flex', lg: 'flex' }, }}>
                        {userSelected && (
                            <Box sx={{ display: "flex", alignItems: "center", }}>
                                <Button onClick={() => { dispatch(clientDetailsId(null)) }} color={"primary"} variant="outlined" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "0.9em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Back to Dashboard
                                </Button>
                            </Box>
                        )}
                        <HeaderToolbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    mt: "3.5%",
                    flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, overflowY: "scroll", height: "calc(90vh)",
                }}
            >
                {activeTab.screen}
            </Box>
        </Box >
    );
}

ClientDashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
};

export default ClientDashboard;