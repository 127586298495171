import { Box, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import SpinnerBackdrop from './SpinnerBackdrop';
import DeleteIcon from '@mui/icons-material/Delete';

function DragAndDrop({ setFileData, setFileError, showSpinner, errorMessage, acceptedFileTypes, allowedFilesText }) {
  const [files, setFiles] = useState([]);
  const [error, setErrorMessage] = useState(null);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMessage(null); // Reset error message
    setFileError(false);
    if (rejectedFiles.length > 0) {
      setErrorMessage(errorMessage);
      setFileError(true);
      return;
    }

    const file = acceptedFiles[0];
    setFiles([file]); // You could allow multiple files here if needed
    setFileData(file);
  };

  const handleDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName)); // Remove the file from state
    setFileData(null); // Clear the file data
    setFileError(false); // Reset any file-related errors
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    onDropRejected: () => {
      setErrorMessage(errorMessage);
      setFileError(true);
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '1px dashed',
        borderRadius: '10px',
      }}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p><u>Click</u> or Drag and Drop to Upload Files</p>
          <em>{allowedFilesText}</em>
        </div>
      </Box>

      <SpinnerBackdrop
        showSpinner={showSpinner}
      />

      {error && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ margin: '10px 0px' }}>
        {files.map(file => (
          <Box key={file.name} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 1,
            paddingLeft: '10px',
            border: '1px solid #6eca35',
            borderRadius: '5px',
          }}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {file.name}
            </Typography>
            <IconButton
              color="error"
              onClick={() => handleDelete(file.name)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default DragAndDrop;
