import React, { useEffect, useState } from "react";
import { Box, Button, Chip, CircularProgress, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled } from "@mui/material";
import ListItems from "../list_items";
import SearchAndFilters from "../search_filters";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StaffDetailsWithSteps } from "../../screens/view_details";
import { useDispatch, useSelector } from "react-redux";
import { staffDetailsId } from "../../middleware/reducers/history_reducers";
import { RegisterStaffList } from "../pagination_view";
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { GetStaffDetailsWithId, StaffDetailsList } from "../../services/staff_service";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ListOfStaff = () => {
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
    });

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 3.8
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.9
        } else {
            return window.screen.width / 1.4
        }
    };
    const dispatch = useDispatch()

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [open, setOpen] = useState(false);
    const [openShift, setOpenShift] = useState(false);

    const handleOpenShift = () => {
        setOpenShift(true);
    };
    const handleShiftClose = () => {
        setOpenShift(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [list, setList] = useState([
        {
            id: 1,
            name: "Saba Salman",
            profileImg: "Leanna-B_thumb.jpg",
            desg: "Full Time Staff Worker",
            description: "coordinate patient care, educate patients and the public about various health conditions",
            mobileNumber: "036589741",
            gender: "Female",
            jobType: "Care",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "staff",
            active: true
        },
        {
            id: 2,
            name: "Usman",
            profileImg: "doctor2.jpg",
            desg: "Full Time Staff Worker",
            description: "coordinate patient care, educate patients and the public about various health conditions",
            mobileNumber: "036589741",
            gender: "Male",
            jobType: "Nurse",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "staff",
            active: false
        },
        {
            id: 3,
            name: "Uzra",
            profileImg: "Leanna-B_thumb.jpg",
            desg: "Full Time Staff Worker",
            description: "coordinate patient care, educate patients and the public about various health conditions",
            mobileNumber: "036589741",
            gender: "Female",
            jobType: "Doctor",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "staff",
            active: true
        },
        {
            id: 4,
            name: "Rizwan",
            profileImg: "doctor2.jpg",
            desg: "Full Time Staff Worker",
            description: "coordinate patient care, educate patients and the public about various health conditions",
            mobileNumber: "036589741",
            gender: "Male",
            jobType: "Care",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "staff",
            active: true
        },
        {
            id: 5,
            name: "Bilal",
            profileImg: "doctor2.jpg",
            desg: "Full Time Staff Worker",
            description: "coordinate patient care, educate patients and the public about various health conditions",
            mobileNumber: "036589741",
            gender: "Male",
            jobType: "Nurse",
            DOB: "12/21/2000",
            address: "abc address",
            email: "admin@cennasupports.com",
            type: "staff",
            active: true
        },
    ]);

    const _filters = useSelector((state) => state._search.filters);
    const _history = useSelector((state) => state._history.staff_details_id);
    const [userSelected, setUserSelected] = useState(null);
    const [listOfStaff, loading] = StaffDetailsList();
    const [getStaffDetailsByID, loading_details] = GetStaffDetailsWithId()
    const [staffList, setStaffList] = useState(null)
    const [listOfStaffDetailsByID, setListOfStaffDetailsByID] = useState([]);
    const [loadingInstant, setLoadingInstant] = useState(false);

    useEffect(() => {
        if (loading) {
            setLoadingInstant(true)
        }
    }, [loading, loading_details])

    useEffect(() => {
        if (listOfStaff?.length) {
            setStaffList(listOfStaff)
            if (listOfStaff?.length) {
                getDataByPromise()
            }
        }
    }, [listOfStaff]);

    const getDataByPromise = async () => {
        const copy = [...listOfStaffDetailsByID];
        try {
            for (const key in listOfStaff) {
                const respons = await getSelectedDetailsById(listOfStaff[key]?.id);
                copy.push({ ...respons, staff_id: listOfStaff[key]?.id })
            }
        } catch (error) {
            console.log(error)
        }
        setListOfStaffDetailsByID(copy)
        setLoadingInstant(false)
    }

    const getSelectedDetailsById = async (id) => {
        try {
            const respons = await getStaffDetailsByID({
                variables: {
                    "filters": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "filter": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                }
            })
            if (respons?.data) {
                const data = respons?.data;
                return data;
            }
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setUserSelected(_history)
    }, [_history]);

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [displayMessage, setDisplayMessage] = useState("");
    const [archive, setArchive] = useState(false);
    const [viewExpose, setViewExpose] = useState("grid");
    const [attachment, setAttachment] = useState([])

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const [availableShift, setAvailableShift] = useState([
        {
            title: "Available Shift",
            value: "Please Select Option",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Please Select Option",
                    selected: true
                },
                {
                    title: "Assist with hygiene, medication, and physical therapy",
                    selected: false
                },
                {
                    title: "Dinner, evening walk, and bedtime routine",
                    selected: false
                },
                {
                    title: "Overnight monitoring and morning hygiene",
                    selected: false
                },
            ]

        },
        {
            title: "Group Name",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Shift Date",
            value: "2024-08-08",
            width: screenWidth,
            type: "date",
            required: true,
            multiline: false
        },
        {
            title: "Shift Time",
            value: "10:10",
            width: screenWidth,
            type: "time",
            required: true,
            multiline: false
        },
        {
            title: "Shift Location",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
        {
            title: "Shift Message",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ])

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'Name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'Gender',
            numeric: false,
            disablePadding: true,
            label: 'Gender',
        },
        {
            id: 'Age',
            numeric: false,
            disablePadding: true,
            label: 'Age',
        },
        {
            id: 'Is Carer',
            numeric: false,
            disablePadding: true,
            label: 'Is Carer',
        },
        // {
        //     id: 'Primary Phone',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Primary Phone',
        // },
        // {
        //     id: 'Secondary Phone',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Secondary Phone',
        // },
        {
            id: 'Primary Email',
            numeric: false,
            disablePadding: true,
            label: 'Primary Email',
        },
        {
            id: 'Formatted Address',
            numeric: false,
            disablePadding: true,
            label: 'Formatted Address',
        },
        // {
        //     id: 'Start Date',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Start Date',
        // },
        // {
        //     id: 'Emergency Contact Name',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Emergency Contact Name',
        // },
        // {
        //     id: 'Emergency Contact Primary Number',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Emergency Contact Primary Number',
        // },
        {
            id: 'Email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        }, {
            id: 'Tax File Number',
            numeric: false,
            disablePadding: true,
            label: 'Tax File Number',
        },
        {
            id: 'Tax Resident',
            numeric: false,
            disablePadding: true,
            label: 'Tax Resident',
        },
        {
            id: 'Tax Claim Threshold',
            numeric: false,
            disablePadding: true,
            label: 'Tax Claim Threshold',
        },
        {
            id: 'Super Fund Name',
            numeric: false,
            disablePadding: true,
            label: 'Super Fund Name',
        },
        {
            id: 'Super Fund Number',
            numeric: false,
            disablePadding: true,
            label: 'Super Fund Number',
        },
        {
            id: 'WagesBsb',
            numeric: false,
            disablePadding: true,
            label: 'WagesBsb',
        },
        {
            id: 'Wages Account',
            numeric: false,
            disablePadding: true,
            label: 'Wages Account',
        },
        {
            id: 'Wasges Account Name',
            numeric: false,
            disablePadding: true,
            label: 'Wasges Account Name',
        },
        {
            id: 'Contract Type Name',
            numeric: false,
            disablePadding: true,
            label: 'Contract Type Name',
        },
        {
            id: 'Pay Band Name',
            numeric: false,
            disablePadding: true,
            label: 'Pay Band Name',
        },
        {
            id: 'Is Active',
            numeric: false,
            disablePadding: true,
            label: 'Is Active',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: true,
            label: 'Options',
        }
    ];

    const staffData = (id, Name, GenderName, Age, IsCarer, PrimaryPhone, SecondaryPhone, PrimaryEmail, FormattedAddress, EmergencyContactName, EmergencyContactPrimaryNumber, Email, TaxFileNumber, TaxResident, TaxClaimThreshold, SuperFundName, SuperFundNumber, WagesBsb, WagesAccount, WasgesAccountName, ContractTypeName, PayBandName, IsActive) => {
        return {
            id,
            Name,
            GenderName,
            Age,
            IsCarer,
            PrimaryPhone,
            SecondaryPhone,
            PrimaryEmail,
            FormattedAddress,
            EmergencyContactName,
            EmergencyContactPrimaryNumber,
            Email,
            TaxFileNumber,
            TaxResident,
            TaxClaimThreshold,
            SuperFundName,
            SuperFundNumber,
            WagesBsb,
            WagesAccount,
            WasgesAccountName,
            ContractTypeName,
            PayBandName,
            IsActive,
        };
    };

    const rows = [
        staffData(1, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(2, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(3, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(4, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
        staffData(5, "Adam Ministrator", "Male", "28", "Good", "0365985698", "0325741258", "adam32@gmail.com", "abc street, zxc house", "abas", "0325487325", "myemial@gmail.com", "RE689", "nbgrt", "grtet", "SuperFundName", "SuperFundNumber", "WagesBsb", "Acf gre", "ABc Hgr", "Implied", "paypal", "true"),
    ];

    return (
        <>
            {userSelected ? (
                <StaffDetailsWithSteps id={userSelected} />
            ) : (
                <Grid container spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid item={true} xs={11} md={11} lg={11.5} sx={{ marginTop: { xs: "13%", lg: "0%" }, display: "flex", flexDirection: { xs: "column", md: "row", lg: "row" } }}>
                        <Grid item={true} xs={11} md={4} lg={4} sx={{ display: "flex", justifyContent: { lg: "flex-start", xs: "center" }, alignItems: "center" }}>
                            <Stack direction="row" spacing={2}>
                                {/* <Button disabled={staffList?.length ? false : true} onClick={() => {
                                    handleOpen();
                                    setDisplayMessage("Sent Bulk Email");
                                }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Bulk Email
                                </Button>
                                <Button disabled={staffList?.length ? false : true} onClick={() => {
                                    handleOpen();
                                    setDisplayMessage("Bulk Messages");
                                }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Bulk Message
                                </Button>
                                <Button disabled={staffList?.length ? false : true} onClick={() => {
                                    handleOpenShift();
                                    setDisplayMessage("Shift Advertisement");
                                }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Shift Advertisement
                                </Button> */}
                            </Stack>
                        </Grid>
                        <Grid item={true} xs={11} md={7} lg={7} >
                            {/* <SearchAndFilters topMargin={2} inputWidth={6} /> */}
                        </Grid>
                        <Grid item={true} xs={11} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={viewExpose}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton disabled={staffList?.length ? false : true} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="grid"><GridViewOutlinedIcon /></ToggleButton>
                                <ToggleButton disabled={staffList?.length ? false : true} sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", width: 60, height: 45, color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", }, }} color={"secondary"} value="list"><ReorderOutlinedIcon /></ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    {loadingInstant ? (
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress color="secondary" size={50} />
                        </Box>
                    ) : (
                        <>
                            {viewExpose === "grid" ?
                                <Grid item={true} xs={12} md={12} lg={12}>
                                    <ListItems list={staffList} details={listOfStaffDetailsByID} setUserSelected={setUserSelected} />
                                </Grid>
                                :
                                <Grid item={true} xs={12} md={12} lg={11.5}>
                                    <RegisterStaffList rows={rows} headCells={headCells} screenWidth={screenWidth} />
                                </Grid>
                            }
                        </>
                    )}
                </Grid>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            {displayMessage}
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2, px: 2 }}>
                        <Box sx={{ my: 2, display: "flex", flexWrap: 'wrap', }}>
                            <Box sx={{ pr: 2 }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                    To:
                                </Typography>
                            </Box>
                            <Box>
                                <Chip label="All Staff" onDelete={handleDelete} />
                            </Box>
                            <Box sx={{ pl: 2 }}>
                                <Button component="label" variant="contained" startIcon={<AttachmentIcon />}>
                                    <VisuallyHiddenInput type="file" onChange={(e) => {
                                        const copy = [...attachment];
                                        copy.push(e.target.files[0]);
                                        setAttachment(copy)
                                    }} />
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                                {attachment?.map((v, i) => {
                                    return (
                                        <ListItem
                                            key={v.name + i}
                                            components={Paper}
                                            elevation={1}
                                            secondaryAction={
                                                <Stack direction={"row"} spacing={2}>
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            }
                                        >
                                            <ListItemIcon>
                                                <AttachmentIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={v.name}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>
                        <Box sx={{ my: 2, }}>
                            <TextField
                                key={"Subject09"}
                                multiline={false}
                                type={"text"}
                                label={"Subject"}
                                value={subject}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                    setSubject(e.target.value)
                                }}
                            />
                        </Box>
                        <Box sx={{ my: 2, }}>
                            <TextField
                                key={"Description09"}
                                label="Description"
                                multiline={true}
                                rows={4}
                                sx={{ width: "100%" }}
                                value={description}
                                onClick={(e) => {
                                    setDescription(e.target.value)
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ my: 2, px: 2, display: "flex", justifyContent: "flex-end", }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button onClick={handleClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                            <Button onClick={handleClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Save
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            {/*  shift advertisement*/}
            <Modal
                open={openShift}
                onClose={handleShiftClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                            {displayMessage}
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2, px: 2 }}>
                        <Box sx={{ my: 2, display: "flex" }}>
                            <Box sx={{ width: "5%" }}>
                                <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                    To:
                                </Typography>
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                <Chip label="All Staff" onDelete={handleDelete} />
                            </Box>
                        </Box>
                        {availableShift?.map((v, i) => {
                            if (v.type === "drop_down") {
                                return (
                                    <TextField
                                        key={v.title + i}
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...availableShift];
                                            copy[i].value = e.target.value;
                                            setAvailableShift(copy);
                                        }}
                                    >
                                        {v?.options?.map((nV, nI) => (
                                            <MenuItem key={nV.title + nI} value={nV.title}>
                                                {nV.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )
                            } else {
                                return (
                                    <TextField
                                        key={v.title + i}
                                        type={v.type}
                                        label={v.title}
                                        multiline={v.multiline}
                                        required={v.required}
                                        htmlFor={v.title}
                                        rows={v.title.includes("Message") ? 4 : 1}
                                        value={v.value}
                                        sx={{ m: 1, width: v.title.includes("Location") || v.title.includes("Message") ? "calc(97.5%)" : v.width }}
                                        onChange={(e) => {
                                            const copy = [...availableShift];
                                            copy[i].value = e.target.value;
                                            setAvailableShift(copy);
                                        }}
                                    />
                                )
                            }
                        })}
                    </Box>
                    <Box sx={{ my: 2, px: 4, display: "flex", justifyContent: "flex-end", }}>
                        <Stack direction={"row"} spacing={2}>
                            <Button onClick={handleShiftClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Cancel
                            </Button>
                            <Button onClick={handleShiftClose} variant="contained" sx={{ fontFamily: "Roboto-Medium", textTransform: "capitalize" }}>
                                Save
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ListOfStaff;