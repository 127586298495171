import styled from "@emotion/styled";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VerticalLinearStepper } from '../../../../components/staff_onboarding_steps/staff_onboarding_steps';
import { DynamicInputsStaff } from './dynamic_Inputs';
import { DesignFrontendModules, DynamicFrontendContent } from '../../../../services/design_frontend';
import WorkDaysAndTimeSlots from "./onboarding_workdays_and_timeslots";

const StyledPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));

const StaffOnboardingForm = () => {
    const [steps, setSteps] = useState([]);
    const [error, setError] = useState(false);

    const [dynamicDesignData, loading] = DynamicFrontendContent();
    const dynamicModules = DesignFrontendModules();

    useEffect(() => {
        try {
            if (dynamicModules?.length && dynamicDesignData?.length) {
                const copy = [...steps];
                for (const key in dynamicModules) {
                    if (dynamicModules[key]?.attributes?.active && dynamicModules[key]?.attributes?.category === "Staff") {
                        const title1 = dynamicModules[key]?.attributes?.title;
                        const category = dynamicDesignData.filter((e) => e.attributes?.design_frontend_module_id?.data?.attributes?.title === title1)
                        for (const key in category) {
                            const active = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.active
                            const title = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.title;

                            if (active) {
                                const inputType = category[key]?.attributes?.Input_type;
                                const inputsList = category[key]?.attributes?.design_frontend_input_names?.data;
                                const questionList = category[key]?.attributes?.question_list_ids?.data;
                                const documents = category[key]?.attributes?.Documents;
                                const duplicateInputField = category[key]?.attributes?.category_input_field_mappings?.data;

                                copy.push({
                                    label: title,
                                    screen: <DynamicInputsStaff
                                        categoryTitle={title}
                                        documents={documents}
                                        inputType={inputType}
                                        inputsList={inputsList}
                                        questionList={questionList}
                                        duplicateInputField={duplicateInputField}
                                        setError={setError}
                                    />
                                })
                            }
                        }
                    }
                }
                copy.push({
                    label: 'Working Days (Start Time & End Time)',
                    screen: <WorkDaysAndTimeSlots />
                });
                setSteps(copy.filter(val => val.label !== "Hourly Rate"))
            }
        } catch (error) {
            console.error(error)
        }
    }, [dynamicModules, dynamicDesignData])

    return (
        <Grid container sx={{
            justifyContent: "center",
            marginTop: { xs: "10%", sm: "2%", lg: "1%" },
            marginBottom: { xs: "15%", sm: "2%", lg: "2%" },
            overflowY: "hidden"
        }} >
            <Box>
                <img style={{ width: 230, height: 55, }} alt="Logo" src="/assets/icons/logo-landscap.png" />
            </Box>
            {loading ? (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : null}
            {steps?.length ? (
                <Grid item={true} xs={12} sm={12} lg={11}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ py: 1, mt: 1, }}>
                        <StyledPaper
                            sx={{
                                my: 1,
                                mx: 'auto',
                                border: "1px solid #6ECA35",
                                borderRadius: 3
                            }}
                            elevation={0}
                        >
                            <VerticalLinearStepper steps={steps} error={error} isOnboardingForm={true} />
                        </StyledPaper>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    )
}

export default StaffOnboardingForm
