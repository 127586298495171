import { Alert, AlertTitle, Avatar, Box, Button, Checkbox, Collapse, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Slide, Snackbar, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderIcon from '@mui/icons-material/Folder';
import { useDispatch, useSelector } from 'react-redux';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { StaffDetailsWithRelationalDataId, ServicePlansFilter, FilterVisitDaysByServiceId, FilterVisitShiftCheckListByServiceId, FilterClientCheckListByClientDetailId, FilterStaffShiftCheckListByVisitDayId, CreateStaffShiftCheckList, UpdateStaffShiftCheckList, ContactInfoById, UpdateServiceShift, FilterShiftsByVisitDayId, GetStaffWithId, GetStaffAnswerLovsReferences } from '../../../services/staff_service';
import { GetServiceRequests } from '../../../services/service_request.jsx';
import { ClientAnswerLovsReferences } from '../../../services/client_service.jsx'

import { IMAGE } from '../../../config/constant';
import { FilterUserById } from '../../../services/auth_service';
import dayjs from 'dayjs';
import { IconButton, ArrowBackIcon } from '../../../components/common/Icons.js';
import VisitCheckListForm from './components/my_dashboard_v1/VisitCheckListForm.jsx';
import NotificationModal from '../../../components/common/NotificationModal.jsx';
import ShiftTimerClock from './components/my_dashboard_v1/ShiftTimerClock.jsx';
import CurrentShiftSectionTimerClock from './components/my_dashboard_v1/CurrentShiftSectionTimerClock.jsx';
import IncidentForm from '../../form_screens/register_form/incident-form.jsx';
import OvertimeForm from '../../form_screens/register_form/OvertimeForm.jsx';
import Reimbursement from '../../form_screens/register_form/Reimbursement.jsx';
import KilometerClaimForm from '../../form_screens/register_form/KilometerClaimForm.jsx';
import ComplaintForm from '../../form_screens/register_form/complaint-form.jsx';
import FeedBackReport from '../../reports_dashboard/screen/feedback.jsx';

const generateDayTimes = (dateString) => {
    const date = new Date(dateString);
    const dayTimes = [];

    // Set start and end times for the day
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59, 999);

    while (startTime <= endTime) {
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const dateString = startTime.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format

        dayTimes.push({
            time: `${formattedHours}:${formattedMinutes} ${ampm}`,
            date: dateString,
            meeting: []
        });

        startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return dayTimes;
}

const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}

export const MyServiceDashboard = () => {

    const [userInfo, setUserInfo] = useState(null)
    const [apiGetUserInfo, loadings, error] = FilterUserById();

    const getMyDetails = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"))
            const response = await apiGetUserInfo({
                "variables": {
                    "id": user?.id
                }
            })
            if (response?.data?.usersPermissionsUser?.data) {
                setUserInfo(response?.data?.usersPermissionsUser?.data)
            }

            const fetchStaffDetails = await GetStaffWithIdFunc({
                variables: {
                    filters: {
                        email: {
                            eq: user?.email
                        }
                    }
                },
            })

            if (fetchStaffDetails?.data.staffDetails.data.length > 0) {
                let staffId = fetchStaffDetails?.data.staffDetails.data[0].id;

                const fetchStaffAnswerLovsReferences = await GetStaffAnswerLovsReferencesFunc({
                    variables: {
                        filters: {
                            staff_detail_id: {
                                id: {
                                    eq: staffId
                                }
                            },
                            input_name: {
                                eq: 'Designation'
                            }
                        }
                    },
                })

                if (fetchStaffAnswerLovsReferences?.data.staffAnswerLovsReferences.data.length > 0) {
                    let staffDesignation = fetchStaffAnswerLovsReferences?.data.staffAnswerLovsReferences.data[0].attributes.selected_option_value
                    setStaffDesignation(staffDesignation)
                    setStaffDetailsData(fetchStaffDetails?.data.staffDetails.data[0]);
                }
            }

        } catch (error) {
            console.error("error")
        }
    }

    useEffect(() => {
        getMyDetails()
    }, [])

    const [activeTab, setActiveTab] = useState("general");
    const [serviceTab, setServiceTab] = useState("ongoing");
    const [incidentsTab, setIncidentsTab] = useState("incidents");
    const [claimsTab, setClaimsTab] = useState("reimbusement");
    const [meetingTab, setMeetingTab] = useState("meeting");
    // const _history = useSelector((state) => state._history.staff_details_id);    

    const [active, setActive] = useState(true);
    const [fetchStaffDataById, loading] = StaffDetailsWithRelationalDataId();
    const [fetchServicePlanDataById, fetchServicePlanDataByIdLoading,] = ServicePlansFilter();
    const [FilterVisitDaysByServiceIdFunc, FilterVisitDaysByServiceIdLoading, FilterVisitDaysByServiceIdError] = FilterVisitDaysByServiceId();
    const [FilterShiftsByVisitDayIdFunc, FilterShiftsByVisitDayIdLoading, FilterShiftsByVisitDayIdError] = FilterShiftsByVisitDayId();
    const [VisitShiftCheckListByServiceIdFunc, VisitShiftCheckListByServiceIdLoading, VisitShiftCheckListByServiceIdError] = FilterVisitShiftCheckListByServiceId();

    const [CreateStaffShiftCheckListFunc, createStaffShiftCheckListLoading, createStaffShiftCheckListError] = CreateStaffShiftCheckList();
    const [UpdateStaffShiftCheckListFunc, UpdateStaffShiftCheckListLoading, UpdateStaffShiftCheckListError] = UpdateStaffShiftCheckList();
    const [UpdateServiceShiftFunc, UpdateServiceShiftLoading, UpdateServiceShiftError] = UpdateServiceShift();

    const [FilterClientCheckListByClientDetailIdFunc, ClientCheckListByClientDetailIdLoading, ClientCheckListByClientDetailIdError] = FilterClientCheckListByClientDetailId();
    const [FilterStaffShiftCheckListByVisitDayIdFunc, FilterStaffShiftCheckListByVisitDayIdLoading, FilterStaffShiftCheckListByVisitDayIdError] = FilterStaffShiftCheckListByVisitDayId();
    const [GetServiceRequestsFunc, GetServiceRequestsLoading, GetServiceRequestsError] = GetServiceRequests();
    const [ContactInfoByIdFunc, ContactInfoByIdLoading, ContactInfoByIdError] = ContactInfoById();
    const [ClientAnswerLovsReferencesFunc, ClientAnswerLovsReferencesLoading, ClientAnswerLovsReferencesError] = ClientAnswerLovsReferences();
    const [GetStaffWithIdFunc, GetStaffWithIdLoading, GetStaffWithIdError] = GetStaffWithId();
    const [GetStaffAnswerLovsReferencesFunc, GetStaffAnswerLovsReferencesLoading, GetStaffAnswerLovsReferencesError] = GetStaffAnswerLovsReferences();
    const [staffDetailsData, setStaffDetailsData] = useState(null);
    const [staffDesignation, setStaffDesignation] = useState(null);
    const [workingDaysList, setWorkingDaysList] = useState(null);
    const [showClockInModal, setShowClockInModal] = useState(false);

    const initCollapse = (data) => {
        return data.map((item) => ({
            ...item,
            collapse: false
        }));
    }

    // const getFetchStaffDataById = async (id) => {
    //     try {
    //         const response = await fetchStaffDataById({
    //             variables: {
    //                 "filter": {
    //                     "staff_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 },
    //                 "id": id,
    //                 "filterDay": {
    //                     "staff_detail_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = response?.data;
    //             setStaffDetailsData(copy);
    //             setWorkingDaysList(initCollapse(copy?.workingDays?.data))
    //         } else {
    //             throw "Somthing mising in query / network issue"
    //         }
    //     } catch (error) {
    //         console.error("error error", error)
    //     }
    // }

    // const getFetchWorkDayDataById = async (id) => {
    //     try {
    //         const response = await fetchWorkDayDataById({
    //             variables: {
    //                 "filter": {
    //                     "staff_id": {
    //                         "id": {
    //                             "eq": id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             const copy = response?.data?.workingDays?.data;
    //             const fCopy = initCollapse(copy);
    //             setWorkingDaysList(fCopy);
    //         } else {
    //             throw "Somthing mising in query / network issue"
    //         }
    //     } catch (error) {
    //         console.log("error error", error)
    //     }
    // }

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const meetingDate = new Date().getDate() + " " + months[new Date().getMonth()] + " " + new Date().getFullYear() + ", " + weekdays[new Date().getDay()]

    // const [selectedDate, setSelectedDate] = useState("2024-07-19");
    // const [dayTimesMeet, setDayTimesMeet] = useState(null)
    const [servicePlans, setServicePlans] = useState([]);
    // const [shifts, setServiceShifts] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedVisitDayShiftCheckList, setSelectedVisitDayShiftCheckList] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null);
    const [shiftChecklistQuestions, setShiftChecklistQuestions] = useState(null);
    const [clientContactDetail, setClientContactDetail] = useState(null);
    const [currentSectionShift, setCurrentSectionShift] = useState(null);
    // const [days, setDays] = useState([
    //     {
    //         title: "Monday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: true
    //     },
    //     {
    //         title: "Tuesday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     },
    //     {
    //         title: "Wednesday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     },
    //     {
    //         title: "Thursday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     },
    //     {
    //         title: "Friday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     },
    //     {
    //         title: "Saturday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     },
    //     {
    //         title: "Sunday",
    //         availability: true,
    //         time_slots: [
    //             {
    //                 start_time: "13:00",
    //                 end_time: "15:30"
    //             },
    //             {
    //                 start_time: "18:00",
    //                 end_time: "22:30"
    //             },
    //         ],
    //         availabilityOption: [
    //             {
    //                 title: "Unavailable",
    //                 value: false
    //             },
    //             {
    //                 title: "Whole Day",
    //                 value: false
    //             }
    //         ],
    //         collapse: false
    //     }
    // ]);

    // const [service, setService] = useState([
    //     {
    //         title: "Assistance with self care activity",
    //         service_type: "Every 3 Days",
    //         status: "Ongoing",
    //         pay_travel: "Yes",
    //         funding_source: "NDIS",
    //         billable: "Yes",
    //         collapse: true,
    //         visit_day: [
    //             {
    //                 date: "10 june 2024",
    //                 day: "Monday",
    //                 start_time: "13:00",
    //                 end_time: "15:30",
    //                 collapse: true,
    //                 visit_shift_checklist: [
    //                     {
    //                         title: "Shopping",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: true,
    //                     },
    //                     {
    //                         title: "Gardening",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Singing",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Running",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Cooking",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                 ],
    //             },
    //             {
    //                 date: "14 june 2024",
    //                 day: "Monday",
    //                 start_time: "13:00",
    //                 end_time: "15:30",
    //                 collapse: false,
    //                 visit_shift_checklist: [
    //                     {
    //                         title: "Shopping",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Gardening",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Singing",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Running",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Cooking",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                 ],
    //             }
    //         ]
    //     },
    //     {
    //         title: "Assistance with self care activity",
    //         service_type: "Every 3 Days",
    //         status: "Ongoing",
    //         pay_travel: "Yes",
    //         funding_source: "NDIS",
    //         billable: "Yes",
    //         collapse: false,
    //         visit_day: [
    //             {
    //                 date: "10 june 2024",
    //                 day: "Monday",
    //                 start_time: "13:00",
    //                 end_time: "15:30",
    //                 collapse: true,
    //                 visit_shift_checklist: [
    //                     {
    //                         title: "Shopping",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: true,
    //                     },
    //                     {
    //                         title: "Gardening",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Singing",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Running",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Cooking",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                 ],
    //             },
    //             {
    //                 date: "14 june 2024",
    //                 day: "Monday",
    //                 start_time: "13:00",
    //                 end_time: "15:30",
    //                 collapse: false,
    //                 visit_shift_checklist: [
    //                     {
    //                         title: "Gardening",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: true,
    //                     },
    //                     {
    //                         title: "Singing",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                     {
    //                         title: "Running",
    //                         comment: "",
    //                         completed: false,
    //                         collapse: false,
    //                     },
    //                 ],
    //             }
    //         ]
    //     }
    // ]);

    const [serviceHistory, setServiceHistory] = useState([
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "closed",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: true,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                }
            ]
        },
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "closed",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: false,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Gardening",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: true,
                        },
                        {
                            title: "Singing",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "asdsa sadsad sadsad sadsad",
                            completed: true,
                            collapse: false,
                        },
                    ],
                }
            ]
        }
    ]);

    const [activeService, setActiveService] = useState(null);
    const [activeServiceCheck, setActiveServiceCheck] = useState(null);
    const [isClockedIn, setIsClockedIn] = useState(false);
    const [isClockedOut, setIsClockedOut] = useState(false);

    const [shiftClockedInTime, setShiftClockedInTime] = useState(null);
    const [shiftClockedOutTime, setShiftClockedOutTime] = useState(null);


    const [shiftDate, setShiftDate] = useState(null);

    const [shiftStartTime, setShiftStartTime] = useState(null);
    const [shiftEndTime, setShiftEndTime] = useState(null);

    const SlideTransition = (props) => {
        return <Slide {...props} direction="left" />;
    }

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    }

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }


    const getVisitDays = async (servicePlan) => {
        try {
            let arr = [];
            for (const element of servicePlan) {

                const serviceRequestId = element?.attributes?.service_request_id?.data?.id;
                const client_detail_id = element?.attributes.service_request_id.data.attributes.client_detail_id.data.id;
                const client_detail = element?.attributes.service_request_id.data.attributes.client_detail_id.data.attributes;

                if (!serviceRequestId) {
                    console.error("Service Request ID is missing");
                    return;
                }

                const response = await FilterVisitDaysByServiceIdFunc({
                    variables: {
                        filter: {
                            service_request_id: {
                                id: {
                                    eq: serviceRequestId,
                                },
                            },
                        },
                    },
                });

                const checkListResponse = await VisitShiftCheckListByServiceIdFunc({
                    variables: {
                        filters: {
                            service_request_id: {
                                id: {
                                    eq: serviceRequestId,
                                },
                            },
                        },
                    },
                });

                const clientCheckListByClientDetailResponse = await FilterClientCheckListByClientDetailIdFunc({
                    variables: {
                        filters: {
                            client_detail_id: {
                                id: {
                                    eq: client_detail_id,
                                },
                            },
                        },
                    },
                });

                if (response?.data?.visitDays?.data &&
                    checkListResponse?.data?.visitShiftCheckLists?.data &&
                    clientCheckListByClientDetailResponse.data?.checkListAlertsNotifications?.data
                ) {

                    // const visitDaysCopy = response.data.visitDays.data;
                    // const { start_date, end_date } = visitDaysCopy[0].attributes;

                    // // Get today's date and the date two days after
                    // const today = dayjs();
                    // const twoDaysAfter = today.add(2, 'day');

                    // const title = element?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_name;

                    // const serviceRequestCheckListCopy = checkListResponse.data.visitShiftCheckLists.data.map((visitShiftCheckList) => {
                    //     return {
                    //         checkListTitle: visitShiftCheckList.attributes.shift_title,
                    //         checkListComment: visitShiftCheckList.attributes.shift_comments,
                    //         checkListId: visitShiftCheckList.id,
                    //         checkListType: 'serviceRequest'
                    //     }
                    // });

                    // const clientCheckListCopy = clientCheckListByClientDetailResponse.data?.checkListAlertsNotifications?.data.map((clientCheckList) => {
                    //     return {
                    //         checkListTitle: clientCheckList.attributes.check_list_title,
                    //         checkListComment: clientCheckList.attributes.check_list_comments,
                    //         checkListId: clientCheckList.id,
                    //         checkListType: 'clientCheckList',
                    //     }
                    // });

                    // setShiftChecklistQuestions([...serviceRequestCheckListCopy, ...clientCheckListCopy])

                    // let shiftsArray = [];

                    // if (dayjs(start_date).isAfter(today) && dayjs(start_date).isBefore(twoDaysAfter)) {
                    //     arr.push({
                    //         id: element.id,
                    //         staff_id: element.attributes.staff_id.data.id,
                    //         title: title || "Unknown Title",
                    //         start_date: start_date,
                    //         end_date: end_date,
                    //         serviceRequestId: serviceRequestId,
                    //         visitDays: shiftsArray,
                    //         client_detail_id: client_detail_id,
                    //         client_detail: client_detail,
                    //     });
                    // }

                    const visitDaysCopy = response.data.visitDays.data;
                    const { start_date, end_date } = visitDaysCopy[0].attributes;

                    // Get today's date and the date two days after
                    const today = dayjs();
                    const twoDaysAfter = today.add(2, 'day');

                    // Get title directly
                    const title = element?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_name || "Unknown Title";

                    // Create checklist arrays in a more concise manner
                    const serviceRequestCheckListCopy = checkListResponse.data.visitShiftCheckLists.data.map(visitShiftCheckList => ({
                        checkListTitle: visitShiftCheckList.attributes.shift_title,
                        checkListComment: visitShiftCheckList.attributes.shift_comments,
                        checkListId: visitShiftCheckList.id,
                        checkListType: 'serviceRequest'
                    }));

                    const clientCheckListCopy = clientCheckListByClientDetailResponse.data?.checkListAlertsNotifications?.data.map(clientCheckList => ({
                        checkListTitle: clientCheckList.attributes.check_list_title,
                        checkListComment: clientCheckList.attributes.check_list_comments,
                        checkListId: clientCheckList.id,
                        checkListType: 'clientCheckList'
                    }));

                    // Combine both checklists
                    setShiftChecklistQuestions([...serviceRequestCheckListCopy, ...clientCheckListCopy]);

                    // Directly push into arr if conditions match
                    if (dayjs(start_date).isSame(today, 'day') || dayjs(start_date).isBetween(today, twoDaysAfter, null, '[]')) {
                        arr.push({
                            id: element.id,
                            staff_id: element.attributes.staff_id.data.id,
                            title,
                            start_date,
                            end_date,
                            serviceRequestId,
                            visitDays: [], // empty array, previously shiftsArray
                            client_detail_id,
                            client_detail
                        });
                    }

                } else {
                    handleSnacksApper(
                        "Error",
                        "Something missing in query / network issue",
                        "error"
                    );
                }
            }
            setServicePlans(arr)
        } catch (error) {
            console.error("Error in getVisitDays:", error);
        }
    };

    const getServicePlansById = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const email = user?.email;

            if (!email) {
                console.error("User email is missing");
                return;
            }

            const response = await fetchServicePlanDataById({
                variables: {
                    filter: {
                        staff_id: {
                            email: {
                                eq: email,
                            },
                        },
                    },
                },
            });

            if (response?.data?.servicePlans?.data) {
                const servicePlans = response.data.servicePlans.data;
                await getVisitDays(servicePlans)
            } else {
                handleSnacksApper(
                    "Error",
                    "Something missing in query / network issue",
                    "error"
                );
            }
        } catch (error) {
            console.error("Error in getServicePlansById:", error);
        }
    };

    // const getUpcomingLatestShift = async () => {

    //     const FilterShiftsByVisitDayIdResponse = await FilterShiftsByVisitDayIdFunc({
    //         variables: {
    //             "filters": {
    //                 "clock_in_status": {
    //                     "not": {
    //                         "eq": "clockedOut"
    //                     }
    //                 }
    //             }
    //         },
    //     });
    //     console.log('FilterShiftsByVisitDayIdResponse 987', FilterShiftsByVisitDayIdResponse?.data.serviceShifts.data);

    //     if (FilterShiftsByVisitDayIdResponse?.data.serviceShifts.data.length > 0) {
    //         let shift = FilterShiftsByVisitDayIdResponse?.data.serviceShifts.data[0]
    //         // let clockInTime = shift.attributes.clock_in_date_time
    //         // setShiftClockedInTime(clockInTime);

    //         let obj = {
    //             id: shift.id,
    //             serviceRequestId: shift.attributes.visit_day_id.data.attributes.service_request_id.data.id,
    //             visitDaysId: shift.attributes.visit_day_id.data.id,
    //             title: shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.charge_band_detail_id.data.attributes.ndis_price_list_id.data.attributes.support_item_name,
    //             start_time: shift.attributes.shift_start,
    //             end_time: shift.attributes.shift_end,
    //             client_detail_id: shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id,
    //             client_detail: shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.attributes,
    //             clock_in_date_time: shift.attributes.clock_in_date_time,
    //             clock_in_status: shift.attributes.clock_in_status,
    //             clock_out_date_time: shift.attributes.clock_out_date_time,
    //             day_time_hours: shift.attributes.day_time_hours,
    //             evening_hours: shift.attributes.evening_hours,
    //             holiday_hours: shift.attributes.holiday_hours,
    //             night_hours: shift.attributes.night_hours,
    //             saturday_hours: shift.attributes.saturday_hours,
    //             shift_comments: shift.attributes.shift_comments,
    //             shift_completed: shift.attributes.shift_completed,
    //             shift_date: shift.attributes.shift_date,
    //             shift_end: shift.attributes.shift_end,
    //             shift_end_flag: shift.attributes.shift_end_flag,
    //             shift_start: shift.attributes.shift_start,
    //             shift_start_flag: shift.attributes.shift_start_flag,
    //             shift_status: shift.attributes.shift_status,
    //             sleepover_hours: shift.attributes.sleepover_hours,
    //             total_shift_hours: shift.attributes.total_shift_hours,
    //         }

    //         setCurrentSectionShift(obj);
    //     }
    // }

    const fetchInitialData = async () => {
        await getServicePlansById();
        // await getUpcomingLatestShift();
    };

    const handleCollapse = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }

    const handleActiveService = (index, data, setData) => {
        const copy = { ...data };
        for (const key in copy.visit_day) {
            copy.visit_day[key].collapse = false;
        }
        copy.visit_day[index].collapse = !copy.visit_day[index].collapse;
        setActiveServiceCheck(copy.visit_day[index].visit_shift_checklist)
        setData(copy)
    }

    const formatTime = (time) => {
        const milliseconds = time % 1000;
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / 1000 / 60 / 60));
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}.${Math.floor(milliseconds / 10).toString().padStart(2, '0')}`;
    };

    // useEffect(() => {
    //     if (_history) {
    //         getFetchStaffDataById(_history);
    //     }
    // }, [_history])

    // useEffect(() => {
    //     const objectOfTimes = generateDayTimes(selectedDate);
    //     const copy = [...objectOfTimes];
    //     copy[30].meeting.push({ meeting_time: "3:00 PM" })
    //     copy[31].meeting.push({ meeting_time: "3:30 PM" })
    //     copy[32].meeting.push({ meeting_time: "4:00 PM" })
    //     copy[33].meeting.push({ meeting_time: "4:30 PM" })
    //     setDayTimesMeet(copy);
    // }, [])

    useEffect(() => {
        fetchInitialData();
    }, []);

    // useEffect(() => {
    //     const active = service.find((e) => e.collapse === true);
    //     for (const key in active.visit_day) {
    //         if (active.visit_day[key].collapse) {
    //             setActiveServiceCheck(active.visit_day[key].visit_shift_checklist)
    //         }
    //     }
    //     setActiveService(active)
    // }, [service])

    // const gender = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "gender")
    // const designation = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "designation")
    // const religion = staffDetailsData?.staffDetail?.data?.attributes.religion
    // const language_spoken = staffDetailsData?.staffDetail?.data?.attributes.language_spoken;

    const generateCurrentSectionShiftObject = (shift, client_detail_id, client_detail, client_gender) => {
        return {
            id: shift.id,
            serviceRequestId: shift.attributes.visit_day_id.data.attributes.service_request_id.data.id,
            visitDaysId: shift.attributes.visit_day_id.data.id,
            title: shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.charge_band_detail_id.data.attributes.ndis_price_list_id.data.attributes.support_item_name,
            start_time: shift.attributes.shift_start,
            end_time: shift.attributes.shift_end,
            client_detail_id: client_detail_id,
            client_detail: client_detail,
            client_gender: client_gender,
            clock_in_date_time: shift.attributes.clock_in_date_time,
            clock_in_status: shift.attributes.clock_in_status,
            clock_out_date_time: shift.attributes.clock_out_date_time,
            day_time_hours: shift.attributes.day_time_hours,
            evening_hours: shift.attributes.evening_hours,
            holiday_hours: shift.attributes.holiday_hours,
            night_hours: shift.attributes.night_hours,
            saturday_hours: shift.attributes.saturday_hours,
            shift_comments: shift.attributes.shift_comments,
            shift_completed: shift.attributes.shift_completed,
            shift_date: shift.attributes.shift_date,
            shift_end: shift.attributes.shift_end,
            shift_end_flag: shift.attributes.shift_end_flag,
            shift_start: shift.attributes.shift_start,
            shift_start_flag: shift.attributes.shift_start_flag,
            shift_status: shift.attributes.shift_status,
            sleepover_hours: shift.attributes.sleepover_hours,
            total_shift_hours: shift.attributes.total_shift_hours,
        }
    };

    const setClockInFunc = async (selectedShiftId) => {
        try {
            const response = await UpdateServiceShiftFunc({
                variables: {
                    id: selectedShiftId,
                    data: {
                        clock_in_status: 'clockedIn',
                        clock_in_date_time: new Date(),
                    },
                },
            });

            if (response?.data.updateServiceShift.data.id) {
                const shift = response?.data.updateServiceShift.data;
                const clockInTime = shift.attributes.clock_in_date_time;
                setShiftClockedInTime(clockInTime);

                const clientAnswerLovsReferencesFuncResponse = await fetchClientGender(
                    shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id
                );

                if (clientAnswerLovsReferencesFuncResponse) {
                    const { client_detail_id, client_detail, client_gender } = clientAnswerLovsReferencesFuncResponse;

                    const obj = generateCurrentSectionShiftObject(shift, client_detail_id, client_detail, client_gender);

                    setCurrentSectionShift(obj);
                    setIsClockedIn(true);
                    setIsClockedOut(false);
                }
            }
        } catch (error) {
            console.error("Error in setClockInFunc:", error);
        }
    };

    const setClockOutFunc = async (selectedShiftId) => {
        try {
            const response = await UpdateServiceShiftFunc({
                variables: {
                    id: selectedShiftId,
                    data: {
                        clock_in_status: 'clockedOut',
                        clock_out_date_time: new Date(),
                        shift_status: "Completed",
                    },
                },
            });

            if (response?.data.updateServiceShift.data.id) {
                const shift = response?.data.updateServiceShift.data;
                const clockOutTime = shift.attributes.clock_out_date_time;
                setShiftClockedOutTime(clockOutTime);

                const clientAnswerLovsReferencesFuncResponse = await fetchClientGender(
                    shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id
                );

                if (clientAnswerLovsReferencesFuncResponse) {
                    const { client_detail_id, client_detail, client_gender } = clientAnswerLovsReferencesFuncResponse;

                    const obj = generateCurrentSectionShiftObject(shift, client_detail_id, client_detail, client_gender);

                    setCurrentSectionShift(obj);
                    setIsClockedIn(false);
                    setIsClockedOut(true);
                }
            }
        } catch (error) {
            console.error("Error in setClockOutFunc:", error);
        }
    };

    const fetchClientGender = async (clientDetailId) => {
        try {
            const response = await ClientAnswerLovsReferencesFunc({
                variables: {
                    filter: {
                        client_detail_id: {
                            id: {
                                eq: clientDetailId,
                            },
                        },
                        input_name: {
                            eq: 'gender',
                        },
                    },
                },
            });

            if (response?.data.clientAnswerLovsReferences.data.length > 0) {
                const client_detail_id = clientDetailId;
                const client_detail = response.data.clientAnswerLovsReferences.data[0].attributes;
                const client_gender = client_detail.selected_option_value;

                return { client_detail_id, client_detail, client_gender };
            }
        } catch (error) {
            console.error("Error in fetchClientGender:", error);
            return null;
        }
    };

    const currSecClockInOutBtnHandler = async () => {
        if (currentSectionShift.clock_in_date_time === null && currentSectionShift.clock_out_date_time === null) {
            await setClockInFunc?.(currentSectionShift.id);
        }

        else if (currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time === null) {
            await setClockOutFunc?.(currentSectionShift.id);
        }
    }

    const clockInOutBtnHandler = async () => {
        if (!isClockedOut) {
            if (isClockedIn) {
                await setClockOutFunc?.(selectedShift.id);
            } else {
                await setClockInFunc?.(selectedShift.id);
            }
        }
    }

    const getCurrSecButtonStyles = (currentSectionShift, isClockedIn) => {
        const { clock_in_date_time, clock_out_date_time } = currentSectionShift;

        const isClockedOut = clock_in_date_time !== null && clock_out_date_time !== null;
        const isCurrentlyClockedIn = clock_in_date_time !== null && clock_out_date_time === null;

        return {
            backgroundColor: isClockedOut ? '#746B6B' : isCurrentlyClockedIn ? 'red' : 'transparent',
            padding: '7px 30px',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: isCurrentlyClockedIn ? 'red' : '#35BE00',
            color: isCurrentlyClockedIn ? '#fff' : isClockedIn ? '#fff' : isClockedOut ? '#fff' : '#35BE00',
            '&:hover': {
                backgroundColor: isClockedOut ? '#746B6B' : isCurrentlyClockedIn ? 'red' : 'transparent',
                borderColor: isCurrentlyClockedIn ? 'red' : '#35BE00',
                color: isClockedOut ? '#fff' : isCurrentlyClockedIn ? '#fff' : '#35BE00',
            },
        };
    };

    const clockInOutBtnStyles = (isClockedIn, isClockedOut) => {
        return {
            backgroundColor: isClockedOut ? '#746B6B' : isClockedIn ? 'red' : 'transparent',
            padding: '7px 30px',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: isClockedIn ? 'red' : '#35BE00',
            color: isClockedOut ? '#fff' : isClockedIn ? '#fff' : '#35BE00',
            '&:hover': {
                backgroundColor: isClockedOut ? '#746B6B' : isClockedIn ? 'red' : 'transparent',
                borderColor: isClockedIn ? 'red' : '#35BE00',
                color: isClockedOut ? '#fff' : isClockedIn ? '#fff' : '#35BE00',
            },
        };
    };

    const ChildModalContent = ({ formName, formID, setChildModalOpen }) => {
        if (formID === 1) {
            return <Box>
                <IncidentForm
                    fromStaffDashboard={true}
                    firstName={selectedShift?.client_detail.first_name}
                    lastName={selectedShift?.client_detail.last_name}
                    staffId={staffDetailsData?.id}
                    staff_name={`${staffDetailsData?.attributes.first_name} ${staffDetailsData?.attributes.last_name}`}
                    gender={selectedShift?.client_gender}
                    staffDesignation={staffDesignation}
                    setChildModalOpen={setChildModalOpen}
                />
            </Box>
        }

        else if (formID === 2) {
            return <Box>
                <ComplaintForm staffId={staffDetailsData?.id}/>
            </Box>
        }

        else if (formID === 3) {
            return <Box>
                <FeedBackReport />
            </Box>
        }

        else if (formID === 4) {
            return <Box>
                <Reimbursement
                    staffId={staffDetailsData?.id}
                    shiftClockedInTime={shiftClockedInTime}
                    shiftClockedOutTime={shiftClockedOutTime}
                    scheduledShiftStartTime={shiftStartTime}
                    scheduledShiftEndTime={shiftEndTime}
                    shiftDate={shiftDate}
                    shiftID={selectedShift.id}
                    setChildModalOpen={setChildModalOpen}
                />
            </Box>
        }

        else if (formID === 5) {
            return <Box>
                <OvertimeForm
                    staffId={staffDetailsData?.id}
                    shiftClockedInTime={shiftClockedInTime}
                    shiftClockedOutTime={shiftClockedOutTime}
                    scheduledShiftStartTime={shiftStartTime}
                    scheduledShiftEndTime={shiftEndTime}
                    shiftDate={shiftDate}
                    shiftID={selectedShift.id}
                    setChildModalOpen={setChildModalOpen}
                    serviceRequestId={selectedPlan.servicePlan.serviceRequestId}
                />
            </Box>
        }

        else if (formID === 6) {
            return <Box>
                <KilometerClaimForm
                    staffId={staffDetailsData?.id}
                    shiftID={selectedShift.id}
                    setChildModalOpen={setChildModalOpen}
                    shiftDate={shiftDate}
                />
            </Box>
        }
    }

    const ChildModal = ({ formName, formID, disabled }) => {
        const [childModalOpen, setChildModalOpen] = useState(false);
        const handleOpen = () => {
            setChildModalOpen(true);
        };

        return (
            <>
                <Button
                    sx={{
                        padding: '10px 46px',
                        borderRadius: '5px',
                        background: '#FFFFFF',
                        border: '1px solid #6ECA35',
                        boxShadow: '0px 2px 4px 0px #00000040',
                        width: '100%',
                        '&.Mui-disabled': {
                            background: '#00000040',
                            border: '0px',
                        },
                    }}

                    disabled={disabled}
                    onClick={handleOpen}
                >{formName}</Button>
                <NotificationModal
                    openModalVariable={childModalOpen}
                    setOpenModalVariable={setChildModalOpen}
                    content={<ChildModalContent formName={formName} formID={formID} setChildModalOpen={setChildModalOpen} />}
                    style={{
                        background: '#F7F7F7',
                        height: '80vh',
                        overflow: 'scroll',
                    }}
                />
            </>
        );
    }

    const clockInModalContent = <Box
        sx={{
            background: '#F7F7F7',
        }}
    >
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                justifyContent: "center",
                marginBottom: "30px",
            }}
        >
            <Typography variant="h5">Shift Details</Typography>
        </Box>

        <Box
            sx={{
                backgroundColor: '#fff',
                padding: '25px',
                gap: '0px',
                borderRadius: '14px',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box>
                    <Typography variant="h6">
                        <ShiftTimerClock
                            isClockedIn={isClockedIn}
                            isClockedOut={isClockedOut}

                            shiftClockedInTime={shiftClockedInTime}
                            shiftClockedOutTime={shiftClockedOutTime}

                            shiftDate={shiftDate}
                            shiftStartTime={shiftStartTime}
                            shiftEndTime={shiftEndTime}
                        />
                    </Typography>
                </Box>
                {(!isClockedIn && !isClockedOut) && <Box>
                    <Typography variant="body1">Clock in to start your shift</Typography>
                </Box>}
            </Box>
            <Box
                sx={{
                    flex: '1',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                {(dayjs(`${shiftDate}T${shiftStartTime}`).isSame(dayjs()) || dayjs().isAfter(dayjs(`${shiftDate}T${shiftStartTime}`))) && <Button
                    sx={clockInOutBtnStyles(isClockedIn, isClockedOut)}
                    onClick={clockInOutBtnHandler}
                >
                    {isClockedOut
                        ? 'Clocked Out'
                        : isClockedIn
                            ? 'Clock Out'
                            : 'Clock In'}
                </Button>}

            </Box>
        </Box>

        <Box
            sx={{
                backgroundColor: '#fff',
                padding: '25px',
                gap: '0px',
                borderRadius: '14px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{
                flex: '1',
            }}>
                <Typography variant="body1">Client Name: {selectedShift?.client_detail?.first_name} {selectedShift?.client_detail?.last_name}</Typography>
                <Typography variant="body1">Address: {clientContactDetail?.address === "" ? "NA" : clientContactDetail?.address}</Typography>
                <Typography variant="body1">Contact: {clientContactDetail?.mobile_phone !== "" ? clientContactDetail?.mobile_phone : clientContactDetail?.home_phone !== "" ? clientContactDetail?.home_phone : "NA"}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '15%',
            }}>
                <img
                    src="/assets/images/profile_picture_placeholder_image.png"
                    alt=""
                    srcset=""
                    style={{
                        width: "100%",
                        height: "auto",
                    }}
                />
            </Box>
        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                backgroundColor: '#fff',
                padding: '25px',
                gap: '0px',
                borderRadius: '14px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'stretch',
            }}
        >
            <Box sx={{
                display: 'flex',
                margin: '0px 0px 30px 0px',
                gap: '35px',
            }}>
                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Incident"}
                        formID={1}
                        disabled={false}
                    />
                </Box>
                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Complaint"}
                        formID={2}
                        disabled={false}
                    />
                </Box>
                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Feedback"}
                        formID={3}
                        disabled={false}
                    />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                margin: '0px 0px 30px 0px',
                gap: '35px',
            }}>
                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Reimbursement"}
                        formID={4}
                        disabled={false}
                    />
                </Box>

                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Overtime"}
                        formID={5}
                        disabled={!isClockedOut}
                    />
                </Box>

                <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <ChildModal
                        formName={"Kilometers Claim"}
                        formID={6}
                        disabled={!isClockedOut}
                    />
                </Box>

            </Box>
        </Box>
        <Box>

        </Box>
    </Box>;

    const handleServicePlanClick = async (servicePlan) => {
        try {
            const response = await FilterVisitDaysByServiceIdFunc({
                variables: {
                    filter: {
                        service_request_id: {
                            id: {
                                eq: servicePlan.serviceRequestId,
                            },
                        },
                    },
                },
            });

            const visitDaysCopy = response.data.visitDays.data;

            let startDate = dayjs();
            let endDate = dayjs("1990-01-01");
            let shiftsArray = [];

            for (const visitDay of visitDaysCopy) {
                const { data: shiftsData } = await FilterShiftsByVisitDayIdFunc({
                    variables: {
                        filters: {
                            visit_day_id: {
                                id: {
                                    eq: visitDay.id,
                                },
                            },
                        },
                    },
                });

                if (shiftsData.serviceShifts.data.length > 0) {
                    const FilterShiftsByVisitDayId = shiftsData.serviceShifts.data;

                    startDate = dayjs(visitDay.attributes.start_date).isBefore(startDate)
                        ? visitDay.attributes.start_date
                        : startDate.format("YYYY-MM-DD");
                    endDate = dayjs(visitDay.attributes.end_date).isAfter(endDate)
                        ? visitDay.attributes.end_date
                        : endDate.format("YYYY-MM-DD");

                    const { data: clientData } = await ClientAnswerLovsReferencesFunc({
                        variables: {
                            filter: {
                                client_detail_id: {
                                    id: {
                                        eq: servicePlan.client_detail_id,
                                    },
                                },
                                input_name: {
                                    eq: "gender",
                                },
                            },
                        },
                    });

                    if (clientData.clientAnswerLovsReferences.data.length > 0) {
                        const clientGender = clientData.clientAnswerLovsReferences.data[0].attributes.selected_option_value;
                        shiftsArray = FilterShiftsByVisitDayId.map((shift) =>
                            generateCurrentSectionShiftObject(
                                shift,
                                servicePlan.client_detail_id,
                                servicePlan.client_detail,
                                clientGender
                            )
                        );

                        const sortedShifts = shiftsArray.sort((a, b) => {
                            const dateComparison = new Date(a.shift_date) - new Date(b.shift_date);
                            if (dateComparison !== 0) {
                                return dateComparison;
                            }

                            const startComparison = a.shift_start.localeCompare(b.shift_start);
                            if (startComparison !== 0) {
                                return startComparison;
                            }

                            return a.shift_end.localeCompare(b.shift_end);
                        });

                        const filteredShift = sortedShifts.find(item => item.clock_in_status !== "clockedOut");
                        setCurrentSectionShift(filteredShift || null);
                    }
                }
            }

            setSelectedPlan({ visitDays: shiftsArray, servicePlan: servicePlan });
        } catch (error) {
            console.error("Error in handleServicePlanClick:", error);
        }
    };

    const handleVisitDayClick = async (visitDay) => {
        try {
            const { data: contactData } = await ContactInfoByIdFunc({
                variables: {
                    filter: {
                        client_detail_id: {
                            id: {
                                eq: visitDay.client_detail_id,
                            },
                        },
                    },
                },
            });

            if (contactData.contactDetails.data.length > 0) {
                setClientContactDetail(contactData.contactDetails.data[0].attributes);
            }

            const { data: shiftsData } = await FilterShiftsByVisitDayIdFunc({
                variables: {
                    filters: {
                        id: {
                            eq: visitDay.id,
                        },
                    },
                },
            });

            if (shiftsData.serviceShifts.data.length > 0) {
                const shift = shiftsData.serviceShifts.data[0];
                const clockInTime = shift.attributes.clock_in_date_time;
                setShiftClockedInTime(clockInTime);

                const { data: clientData } = await ClientAnswerLovsReferencesFunc({
                    variables: {
                        filter: {
                            client_detail_id: {
                                id: {
                                    eq: shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id,
                                },
                            },
                            input_name: {
                                eq: "gender",
                            },
                        },
                    },
                });

                if (clientData.clientAnswerLovsReferences.data.length > 0) {
                    const clientDetailId = shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.id;
                    const clientDetail = shift.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.client_detail_id.data.attributes;
                    const clientGender = clientData.clientAnswerLovsReferences.data[0].attributes.selected_option_value;

                    const obj = generateCurrentSectionShiftObject(shift, clientDetailId, clientDetail, clientGender);
                    updateClockStatus(obj);

                    const checklists = await fetchChecklists(obj, shift.id);

                    setSelectedVisitDayShiftCheckList({
                        shiftCheckList: checklists,
                    });

                    setShiftDate(visitDay.shift_date);
                    setShiftStartTime(visitDay.start_time);
                    setShiftEndTime(visitDay.end_time);
                    setSelectedShift(obj);
                    setShowClockInModal(true);
                }
            }
        } catch (error) {
            console.error("Error in handleVisitDayClick:", error);
        }
    };

    const updateClockStatus = (obj) => {
        if (!obj.clock_in_date_time && !obj.clock_out_date_time) {
            setIsClockedIn(false);
            setIsClockedOut(false);
            setShiftClockedInTime(null);
            setShiftClockedOutTime(null);
        } else if (obj.clock_in_date_time && !obj.clock_out_date_time) {
            setIsClockedIn(true);
            setShiftClockedInTime(obj.clock_in_date_time);
            setIsClockedOut(false);
            setShiftClockedOutTime(null);
        } else if (obj.clock_in_date_time && obj.clock_out_date_time) {
            setIsClockedIn(false);
            setIsClockedOut(true);
            setShiftClockedInTime(obj.clock_in_date_time);
            setShiftClockedOutTime(obj.clock_out_date_time);
        }
    };

    const fetchChecklists = async (obj, shiftId) => {
        const [staffChecklists, serviceRequestChecklists, clientChecklists] = await Promise.all([
            FilterStaffShiftCheckListByVisitDayIdFunc({
                variables: {
                    filters: {
                        service_shift: {
                            id: {
                                eq: shiftId,
                            },
                        },
                    },
                },
            }),
            VisitShiftCheckListByServiceIdFunc({
                variables: {
                    filters: {
                        service_request_id: {
                            id: {
                                eq: obj.serviceRequestId,
                            },
                        },
                    },
                },
            }),
            FilterClientCheckListByClientDetailIdFunc({
                variables: {
                    filters: {
                        client_detail_id: {
                            id: {
                                eq: obj.client_detail_id,
                            },
                        },
                    },
                },
            }),
        ]);

        const staffChecklistItems = staffChecklists?.data?.staffShiftCheckLists?.data.map(item => ({
            id: item.id,
            checkListTitle: item.attributes.shift_title,
            checkListComment: item.attributes.shift_comments,
            checkListType: item.attributes.checkListType,
            visitDayId: obj.id,
        })) || [];

        const serviceRequestChecklistItems = serviceRequestChecklists?.data?.visitShiftCheckLists?.data.filter(item => {
            return !staffChecklistItems.some(staffItem =>
                staffItem.visitDayId === obj.id && staffItem.checkListType === "serviceRequest"
            );
        }).map(item => ({
            checkListTitle: item.attributes.shift_title,
            checkListComment: item.attributes.shift_comments,
            checkListId: item.id,
            checkListType: "serviceRequest",
            visitDayId: obj.id,
        })) || [];

        const clientChecklistItems = clientChecklists?.data?.checkListAlertsNotifications?.data.filter(item => {
            return !staffChecklistItems.some(staffItem =>
                staffItem.visitDayId === obj.id && staffItem.checkListType === "clientCheckList"
            );
        }).map(item => ({
            checkListTitle: item.attributes.check_list_title,
            checkListComment: item.attributes.check_list_comments,
            checkListId: item.id,
            checkListType: "clientCheckList",
            visitDayId: obj.id,
        })) || [];

        return [...staffChecklistItems, ...serviceRequestChecklistItems, ...clientChecklistItems];
    };


    return (
        <Box>
            <Box container sx={{ bgcolor: "#F5F7F6", padding: "25px" }}>
                <NotificationModal
                    openModalVariable={showClockInModal}
                    setOpenModalVariable={setShowClockInModal}
                    content={clockInModalContent}
                    style={{
                        background: '#F7F7F7',
                    }}
                />
                <Box item={true} >
                    {currentSectionShift !== null &&
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            px: 4,
                            py: 2,
                            border: "1px solid #6ECA35",
                            borderRadius: "25px",
                            backgroundColor: "#fff",
                            marginBottom: '25px'
                        }}>

                            <Box sx={{
                                display: "flex",
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                justifyContent: "space-between",
                                flexDirection: "column",
                                px: 4,
                                py: 2,
                            }}>
                                <Box sx={{ marginBottom: "10px", }}>
                                    <p style={{
                                        color: '#3A89CC',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '500',
                                        lineHeight: '18.15px',
                                        textAlign: 'left',
                                    }}>
                                        {
                                            (currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time === null) ?
                                                'Current Status' :
                                                (currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time !== null) ? 'Shift Ended' : 'Upcoming Shift'
                                        }
                                    </p>
                                </Box>
                                <Box>
                                    <p style={{
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '24.2px',
                                        textAlign: 'left',

                                    }}>{(currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time === null) && 'In Progress - '}{currentSectionShift.title}</p>
                                </Box>
                                {currentSectionShift.clock_in_date_time !== null && <Box>
                                    <p style={{
                                        color: '#6ECA35',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '500',
                                        lineHeight: '18.15px',
                                        textAlign: 'left',

                                    }}>
                                        <CurrentShiftSectionTimerClock
                                            shiftDate={currentSectionShift.shift_date}
                                            shiftEndTime={currentSectionShift.shift_end}
                                            clock_in_date_time={currentSectionShift.clock_in_date_time}
                                            clock_out_date_time={currentSectionShift.clock_out_date_time}
                                        />
                                    </p>
                                </Box>}
                            </Box>

                            <Box>
                                {
                                    dayjs(currentSectionShift.shift_date).isSame(dayjs) && <Button
                                        sx={getCurrSecButtonStyles(currentSectionShift, isClockedIn)}
                                        onClick={currSecClockInOutBtnHandler}
                                    >
                                        {currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time !== null
                                            ? 'Clocked Out'
                                            : currentSectionShift.clock_in_date_time !== null && currentSectionShift.clock_out_date_time === null
                                                ? 'Clock Out'
                                                : 'Clock In'}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    }

                    <Box
                        sx={{
                            border: "1px solid #6ECA35",
                            borderRadius: "25px",
                            backgroundColor: "#fff",
                            padding: "25px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                JustifyContent: 'space-between',
                                alignItems: "center",
                                flex: 1,
                                marginBottom: "20px",
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                {selectedPlan !== null &&
                                    <IconButton
                                        sx={{
                                            padding: '0px',
                                        }}
                                    >
                                        <ArrowBackIcon
                                            onClick={() => {
                                                if (selectedPlan !== null && selectedVisitDayShiftCheckList === null) {
                                                    setSelectedPlan(null);
                                                    setCurrentSectionShift(null)
                                                }
                                                else if (selectedPlan !== null && selectedVisitDayShiftCheckList !== null) {
                                                    setSelectedPlan(null);
                                                    setSelectedVisitDayShiftCheckList(null);
                                                    setCurrentSectionShift(null)
                                                }
                                            }}
                                        />
                                    </IconButton>
                                }
                            </Box>
                            <Box sx={{
                                display: "flex",
                            }}>
                                <Box>
                                    <Button>On Going</Button>
                                </Box>
                                <Box>
                                    <Button>History</Button>
                                </Box>
                            </Box>

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '40px',
                            }}
                        >
                            {selectedPlan === null &&
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "stretch",
                                        padding: "25px",
                                        borderRadius: "25px",
                                        backgroundColor: "#A7A6A617",
                                        flex: 1,
                                    }}
                                >
                                    {selectedPlan === null &&
                                        <Box>
                                            <Box>
                                                <p style={{
                                                    color: '#6ECA35',
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '24.2px',
                                                    textAlign: 'left',
                                                    marginBottom: '15px'

                                                }}>Service Information</p>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: '10px',
                                                }}
                                            >
                                                {servicePlans.length > 0 ?
                                                    servicePlans.map((servicePlan, index) => {
                                                        return <Box
                                                            key={index}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                py: 2,
                                                                px: 4,
                                                                backgroundColor: "#fff",
                                                                borderRadius: 4,
                                                                boxShadow: '0px 4px 4px 0px #0000001A',
                                                                marginBottom: '10px',
                                                            }}
                                                            onClick={() => {
                                                                handleServicePlanClick(servicePlan)
                                                            }}
                                                        >
                                                            <Box>
                                                                <Box>
                                                                    <Typography>{servicePlan.title}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            color: '#a5a5a5'
                                                                        }}
                                                                    >
                                                                        {dayjs(servicePlan.start_date).format('D MMM YYYY')} - {dayjs(servicePlan.end_date).format('D MMM YYYY')}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    })
                                                    : <Box sx={{ display: 'flex', marginTop: '50px', justifyContent: 'center', backgroundColor: '#F5F7F6' }}>
                                                        <Typography color={"text.primary"} variant="h5">
                                                            No Service Requests Found
                                                        </Typography>

                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            }
                            {selectedPlan !== null &&
                                <Box
                                    sx={{
                                        flex: '1',
                                        display: 'flex',
                                        gap: '40px',
                                    }}
                                >
                                    <Box
                                        // sx={{
                                        //     display: "flex",
                                        //     flexDirection: "column",
                                        //     justifyContent: "space-between",
                                        //     alignItems: "stretch",
                                        //     px: 4,
                                        //     py: 4,
                                        //     borderRadius: "25px",
                                        //     backgroundColor: "#A7A6A617",
                                        //     flex: 1,
                                        // }}

                                        sx={{
                                            width: '100%',
                                            background: '#A7A6A617',
                                            padding: '25px',
                                            borderRadius: "25px",
                                        }}
                                    >
                                        <Box>
                                            <p style={{
                                                color: '#6ECA35',
                                                fontFamily: 'Inter',
                                                fontSize: '20px',
                                                fontWeight: '500',
                                                lineHeight: '24.2px',
                                                textAlign: 'left',
                                                marginBottom: '15px'

                                            }}>Shifts</p>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: '10px',
                                            }}
                                        >
                                            {selectedPlan.visitDays.map((visitDay, index) => {
                                                let enableClick = dayjs(visitDay.shift_date).startOf('day').isSame(dayjs().startOf('day'));
                                                return <Box
                                                    key={index}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        py: 2,
                                                        px: 4,
                                                        backgroundColor: "#fff",
                                                        borderRadius: 4,
                                                        boxShadow: '0px 4px 4px 0px #0000001A',
                                                        marginBottom: '10px',
                                                    }}
                                                    onClick={() => {
                                                        enableClick && handleVisitDayClick(visitDay)
                                                    }}
                                                >
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                    }}>
                                                        <Box>
                                                            <Typography variant="body1" sx={{ color: '#000', fontSize: '18px' }}>{dayjs(visitDay.shift_date).format('D MMM YYYY')} </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body1" sx={{ color: '#818181' }}>{dayjs(visitDay.shift_date).format('dddd')}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        marginTop: '10px',
                                                    }}>
                                                        <Box>
                                                            <Typography variant="body1"
                                                                sx={{
                                                                    color: '#818181'
                                                                }}>
                                                                Start Time: {dayjs(visitDay.start_time, "HH:mm:ss.SSS").format("HH:mm")}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body1"
                                                                sx={{
                                                                    color: '#818181'
                                                                }}>
                                                                End Time: {dayjs(visitDay.end_time, "HH:mm:ss.SSS").format("HH:mm")}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            })}
                                        </Box>
                                    </Box>
                                    {selectedVisitDayShiftCheckList !== null && isClockedIn &&
                                        <Box
                                            sx={{
                                                width: '100%',
                                                background: '#A7A6A617',
                                                padding: '25px',
                                                borderRadius: "25px",
                                            }}
                                        >
                                            <Box>
                                                <Box>
                                                    <p
                                                        style={{
                                                            color: '#6ECA35',
                                                            fontFamily: 'Inter',
                                                            fontSize: '20px',
                                                            fontWeight: '500',
                                                            lineHeight: '24.2px',
                                                            textAlign: 'left',
                                                            marginBottom: '15px'
                                                        }}
                                                    >Shift Checklist</p>
                                                </Box>
                                                {selectedVisitDayShiftCheckList.shiftCheckList.length > 0 ? (
                                                    selectedVisitDayShiftCheckList.shiftCheckList.map((checkList, index) => {
                                                        return <VisitCheckListForm
                                                            checkList={checkList}
                                                            CreateStaffShiftCheckListFunc={CreateStaffShiftCheckListFunc}
                                                            UpdateStaffShiftCheckListFunc={UpdateStaffShiftCheckListFunc}
                                                            checkListTitle={checkList?.checkListTitle}
                                                            visitDayId={checkList?.visitDayId}
                                                            fetchInitialData={fetchInitialData}
                                                            checkListType={checkList.checkListType}
                                                            id={checkList.id ? checkList.id : 0}
                                                            checkListComment={checkList.checkListComment}
                                                            shiftId={selectedShift.id}
                                                        />
                                                    })
                                                ) : (
                                                    <Box>
                                                        <p
                                                            style={{
                                                                color: "#6ECA35",
                                                                fontFamily: "Inter",
                                                                fontSize: "20px",
                                                                fontWeight: "500",
                                                                lineHeight: "24.2px",
                                                                textAlign: "left",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            No Checklist Found
                                                        </p>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            }
                        </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                General Information
                            </Typography>
                            <IconButton>
                                <BorderColorOutlinedIcon color='primary' />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Date of Birth:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {getAge(staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth)}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Age:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {gender?.attributes?.selected_option_value}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Gender:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {religion}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Religion:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {language_spoken}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Language Spoken:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.contactDetail?.data?.attributes?.home_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.contactDetail?.data?.attributes?.mobile_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {designation?.attributes?.selected_option_value}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Designation:" />
                            </ListItem>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                Emergency Contact
                            </Typography>
                            <IconButton>
                                <BorderColorOutlinedIcon color='primary' />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.first_name}{" "}
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.last_name}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Name:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.relationship}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Relationship:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.home_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.mobile_phone}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_1}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_1:" />
                            </ListItem>
                            <ListItem
                                sx={{ p: 0, m: 0 }}
                                disableGutters
                                secondaryAction={
                                    <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                        {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_2}
                                    </Typography>
                                }
                            >
                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_2:" />
                            </ListItem>
                        </Box>
                    </Box> */}
                </Box>
            </Box>
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}