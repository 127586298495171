import React, { useState } from "react";
import {
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const VisitCheckListForm = ({ checkList, CreateStaffShiftCheckListFunc, UpdateStaffShiftCheckListFunc, checkListTitle, visitDayId, fetchInitialData, checkListType, id, checkListComment, shiftId }) => {

    const [dbId, setDbId] = useState(id)

    const formik = useFormik({
        initialValues: {
            checkList: {
                checked: id !== 0,
                comment: id !== 0 ? checkListComment : "",
            }
        },
        onSubmit: async (values) => {
            let obj = {
                shift_title: checkListTitle,
                shift_comments: values?.checkList.comment,
                service_shift: shiftId,
                checkListType: checkListType,
                publishedAt: new Date(),
            }

            if (dbId === 0) {
                const response = await CreateStaffShiftCheckListFunc({
                    variables: {
                        data: obj
                    }
                });

                if (response.data.createStaffShiftCheckList.data.id) {
                    setDbId(response.data.createStaffShiftCheckList.data.id)
                }
            }

            else if (dbId !== 0) {
                await UpdateStaffShiftCheckListFunc({
                    variables: {
                        data: obj,
                        id: dbId,
                    }
                });
            }
        },
    });

    const handleCheckboxChange = (event) => {
        formik.setFieldValue(`checkList.checked`, event.target.checked);
        if (!event.target.checked) {
            formik.setFieldValue(`checkList.comment`, "");
        }
        formik.submitForm();
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    py: 2,
                    px: 4,
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    boxShadow: "0px 4px 4px 0px #0000001A",
                    marginBottom: "10px",
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={`checkList.checked`}
                                    checked={formik.values.checkList.checked}
                                    onChange={(event) => handleCheckboxChange(event)}
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body1" component="div">
                                        {checkList.checkListTitle}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: "#a5a5a5" }}
                                    >
                                        {checkList.checkListComment}
                                    </Typography>
                                </Box>
                            }
                        />
                        {formik.values.checkList.checked && (
                            <Box>
                                <TextField
                                    id={`checkList.comment`}
                                    name={`checkList.comment`}
                                    value={formik.values.checkList.comment}
                                    onChange={formik.handleChange}
                                    onBlur={formik.submitForm}
                                    multiline
                                    sx={{ width: "100%" }}
                                    placeholder="Add a comment"
                                />
                                {formik.touched.checkList &&
                                    formik.touched.checkList?.comment &&
                                    formik.errors.checkList &&
                                    formik.errors.checkList?.comment && (
                                        <Typography color="error" variant="caption">
                                            {formik.errors.checkList.comment}
                                        </Typography>
                                    )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default VisitCheckListForm;