import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { GetKilometersClaimsByServicePlan } from '../../../services/finance_service';
import dayjs from 'dayjs';
import DataGridComp from '../../../components/common/DataGridComp';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50
    },
    {
        field: 'service_request',
        headerName: 'Service Request',
        flex: 1,
    },
    {
        field: 'date_of_travel',
        headerName: 'Date Of Travel',
        width: 120,
    },
    {
        field: 'start_time',
        headerName: 'Start Time',
        width: 100,
    },
    {
        field: 'end_time',
        headerName: 'End Time',
        width: 100,
    },
    {
        field: 'start_location',
        headerName: 'Start Location',
        width: 250,
    },
    {
        field: 'End Location',
        headerName: 'End Location',
        flex: 1,
    },
    {
        field: 'total_kilometers',
        headerName: 'Total Kilometers',
        width: 150,
        renderCell: (data) => {
            return `${data.row.total_kilometers} ${data.row.total_kilometers > 1 ? 'KMs' : 'KM'}` ;
        },
    },
    {
        field: 'purpose_of_travel',
        headerName: 'Purpose Of Travel',
        flex: 1,
    },
    {
        field: 'vehicle_used',
        headerName: 'Vehicle Used',
        flex: 1,
    },
];

const KilometersClaimList = ({ staffID }) => {

    const [rows, setRows] = useState([]);

    // GraphQL Queries
    const [GetKilometersClaimsByServicePlanFunc, GetKilometersClaimsByServicePlanLoading, GetKilometersClaimsByServicePlanError] = GetKilometersClaimsByServicePlan()

    const getKilometersClaimsByServicePlanByStaffId = async () => {
        const response = await GetKilometersClaimsByServicePlanFunc({
            "variables": {
                "filters": {
                    "staff_id": {
                        "id": {
                            "eq": staffID,
                        }
                    }
                }
            },
        });

        if (response?.data.kilometersClaims.data.length > 0) {
            const rows = response?.data.kilometersClaims.data.map((report, index) => {
                const { date_of_travel, start_time, end_time, start_location, end_location, total_kilometers, purpose_of_travel, vehicle_used, service_shift_id } = report?.attributes
                const charge_band_name = service_shift_id.data.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.charge_band_detail_id.data.attributes.charge_band_name;
                return {
                    id: index + 1,
                    date_of_travel: dayjs(date_of_travel).format("DD/MM/YYYY"),
                    start_time: dayjs(`${date_of_travel}T${start_time}`).format("HH:mm A"),
                    end_time: dayjs(`${date_of_travel}T${end_time}`).format("HH:mm A"),
                    start_location: start_location,
                    end_location: end_location,
                    total_kilometers: total_kilometers,
                    purpose_of_travel: purpose_of_travel,
                    vehicle_used: vehicle_used,
                    service_request: charge_band_name
                }
            })
            setRows(rows)
        }
    }

    useEffect(() => {
        getKilometersClaimsByServicePlanByStaffId();
    }, [])

    if (staffID === null) {
        return <div>Loading...</div>;
    }

    return (
        <Box
            sx={{
                padding: '25px',
            }}
        >
            <Grid container sx={{
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Grid item xs={11}>
                    <DataGridComp
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default KilometersClaimList;