import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material';
import { GetReimbursementsByServicePlan } from '../../../services/finance_service';

import dayjs from 'dayjs';
import DataGridComp from '../../../components/common/DataGridComp';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 150,
    },
    {
        field: 'purpose_note',
        headerName: 'Purpose',
        flex: 1,
    },
    {
        field: 'address_of_payment',
        headerName: 'Address Of Payment',
        flex: 1,
    },
    {
        field: 'service_request',
        headerName: 'Service Request',
        flex: 1,
    },   
    {
        field: 'approved_status',
        headerName: 'Approved',
        width: 150,
    },
];

const ReimbursementList = ({ staffID }) => {

    const [rows, setRows] = useState([]);


    // GraphQL Queries
    const [GetReimbursementsByServicePlanFunc, GetReimbursementsByServicePlanLoading, GetReimbursementsByServicePlanError] = GetReimbursementsByServicePlan()

    const getIncidentReportsByStaffId = async () => {

        const response = await GetReimbursementsByServicePlanFunc({
            "variables": {
                "filters": {
                    "staff_id": {
                        "id": {
                            "eq": staffID,
                        }
                    }
                }
            },
        });        

        if (response?.data.reimbursements.data.length > 0) {
            const rows = response?.data.reimbursements.data.map((complaint, index) => {
                const { amount, purpose_note, address_of_payment, approved_status, service_shift_id, } = complaint.attributes;
                const charge_band_name = service_shift_id.data.attributes.visit_day_id.data.attributes.service_request_id.data.attributes.charge_band_detail_id.data.attributes.charge_band_name
                return {
                    id: index + 1,
                    amount: amount,
                    purpose_note: purpose_note,
                    address_of_payment: address_of_payment,
                    approved_status: approved_status,
                    service_request: charge_band_name,
                }
            })
            setRows(rows)
        }
    }

    useEffect(() => {
        getIncidentReportsByStaffId();
    }, [])

    if (staffID === null) {
        return <div>Loading...</div>;
    }

    return (
        <Box
            sx={{
                padding: '25px',
            }}
        >
            <Grid container sx={{
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Grid item xs={11}>
                    <DataGridComp
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ReimbursementList;