import React, { useEffect, useState } from "react";
import './style.css'
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Paper, Slide, Snackbar, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { AuthService } from "../../services/auth_service";
import { getToken } from "../../config/helpers";


const StyledPaper = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 400,
    border: "3px solid #6ECA35",
    color: theme.palette.text.primary,
    borderRadius: 21,
    backgroundColor: 'transparent'
}));

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}


const LoginComponent = () => {
    const navigate = useNavigate();
    const [displayMessage, setDisplayMessage] = useState(null);
    const [loginInfo, setLoginInfo] = useState([
        {
            title: 'Email',
            placeholder: "Please enter your valid email",
            value: "",
            type: 'email',
            required: true
        },
        {
            title: 'Password',
            placeholder: "Please enter your valid password",
            value: "",
            type: 'password',
            required: true
        }
    ])

    const [errorSnacks, setErrorSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });
    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleClose = () => {
        setErrorSnack({
            ...errorSnacks,
            open: false,
        });
    };

    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const changeInputValues = (value, index) => {
        try {
            const copy = [...loginInfo];
            copy[index].value = value;
            setLoginInfo(copy);
        } catch (error) {
            console.log("changeInputValues", error)
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    }

    const signInFn = () => {
        try {
            const emailValidate = validateEmail(loginInfo[0].value);
            const passwordValidate = validatePassword(loginInfo[1].value);

            if (!emailValidate) {
                throw { type: "email", message: "Please enter valid email address.!" }
            }
            if (!passwordValidate) {
                throw { type: "password", message: "Invalid password! Kindly add a valid password" }
            }
            if (emailValidate && passwordValidate) {
                handleLogin(loginInfo[0].value, loginInfo[1].value);
                setDisplayMessage(null);
            }
        } catch (err) {
            setDisplayMessage(err)
        }
    }

    const handleLogin = async (email, password) => {
        try {
            await AuthService.login(email, password);
            navigate("/home", { replace: true });
            // Redirect or perform other actions after successful login
        } catch (error) {
            // console.error('Login message:', error.response.data.error.message);
            // Handle login error (display error message, etc.)
            setErrorSnack({
                ...errorSnacks,
                open: true,
            });
            //TODO: NEEDS IMPROVEMENT IN BELOW CODE, FOR NOW I AM HANDLING ERROR MESSAGE FROM FRONTEND BUT NEEDS PROPER HANDLING FRON BACKEND
            setErrorSnackMessage({
                ...errorSnackMessage,
                title: error?.response?.data?.error?.status === 500 ? 'Invalid Credentials' :error?.response?.data?.error?.name  ,
                message: error?.response?.data?.error?.status === 500 ? 'Invalid Email or Password' :error?.response?.data?.error?.message  ,
                severity: "error"
            })
        }
    };

    useEffect(() => {
        if (getToken()) {
            navigate('/home')
        }
    }, [getToken])

    return (
        <Box className="background" sx={{ flexGrow: 1, }}>
            <StyledPaper
                className="handle-mobile"
                sx={{
                    my: 8,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <div className="form-heading1">
                    <span style={{ color: "#013447" }}>Log in</span>
                </div>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {loginInfo.map((v, i) => {
                        return (
                            <TextField
                                htmlFor={v.title}
                                id={v.title}
                                style={{ marginBottom: "3%" }}
                                InputLabelProps={{
                                    style: { color: '#000000', fontSize: "1.2em" },
                                }}
                                type={v.type}
                                key={i}
                                required={v.required}
                                label={v.title}
                                onChange={(e) => changeInputValues(e.target.value, i)}
                                value={v.value}
                                placeholder={v.placeholder}
                                sx={{ m: 1, width: '100%' }}
                                helperText={v.type === displayMessage?.type ? displayMessage.message : null}
                                error={v.type === displayMessage?.type ? true : false}
                            />
                        )
                    })}
                </Box>
                {/* <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                color="secondary"
                                checked={checked}
                                onChange={handleChange}
                            />}
                            label="Keep me signed in."
                            style={{ color: '#000000' }}
                        />
                    </FormGroup>
                </Box> */}
                <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Button onClick={() => signInFn()} size="large" variant="contained" color="secondary" style={{ borderRadius: 100 }}>
                        <span style={{ textTransform: 'capitalize', color: '#fff', fontWeight: 600, fontFamily: 'Roboto-Bold' }}>Sign in</span>
                    </Button>
                </Box>
                <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', }}>
                    <Typography className="terms-info">
                        By continuing, you agree to CennaSupports <a href="/terms-and-condition">Terms of Use & Privacy Notice.</a>
                    </Typography>
                </Box>
                {/* <Box sx={{ m: 1, display: 'flex', }}>
                    <div className="seprater">
                        <div className="left-line"></div>
                        <div className="center-txt-line">
                            <span>New to CennaSupports?</span>
                        </div>
                        <div className="right-line"></div>
                    </div>
                </Box>
                <Box sx={{ m: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', }}>
                    <Button size="large" variant="contained" color="primary" style={{ borderRadius: 100 }}>
                        <span style={{ fontSize: '0.9em', textTransform: 'capitalize', color: '#fff', fontWeight: 600, fontFamily: 'Roboto-Bold' }}>
                            Create your CennaSupports account
                        </span>
                    </Button>
                </Box> */}
            </StyledPaper>

            <Snackbar open={errorSnacks.open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={errorSnacks.Transition} anchorOrigin={{ vertical: errorSnacks.vertical, horizontal: errorSnacks.horizontal }}>
                <Alert
                    onClose={handleClose}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default LoginComponent;