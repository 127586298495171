import { Backdrop, Box, Button, CircularProgress, Grid, Modal, Paper, Slide, Stack, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text'
import ModulesAccordion from '../accordion/modules-accordion'
import { CreateFeatureGroup, CreateModulePermission, GetAllFeatureGroupManagement } from '../../../services/admin_service'
import CustomSnackBar from '../../../components/custom_snack_bar'
import { FeatureGroupManagementSchema } from './schema'

const TypesOfPermissions = [
    { read: false },
    { write: false },
    { archive: false }
]

const Permissions = [
    {
        name: 'Clients',
        permissions: TypesOfPermissions
    },
    {
        name: 'Client Onboarding',
        permissions: TypesOfPermissions
    },
    {
        name: "Staff",
        permissions: TypesOfPermissions
    },
    {
        name: "Staff Onboarding",
        permissions: TypesOfPermissions
    },
    {
        name: "Incidents_Complaints",
        permissions: TypesOfPermissions
    },
    {
        name: "Add an Incident",
        permissions: TypesOfPermissions
    },
    {
        name: "Add a Complaint",
        permissions: TypesOfPermissions
    },
    {
        name: "Feedback",
        permissions: TypesOfPermissions
    },
    {
        name: "Schedule",
        permissions: TypesOfPermissions
    },
    {
        name: "Admin",
        permissions: TypesOfPermissions
    },
    {
        name: "Finance",
        permissions: TypesOfPermissions
    },
    {
        name: "Scheduler View",
        permissions: TypesOfPermissions
    },
    {
        name: "Auto Scheduling",
        permissions: TypesOfPermissions
    },
    {
        name: "Organization",
        permissions: TypesOfPermissions
    },
    {
        name: "Add Service Request",
        permissions: TypesOfPermissions
    },
    {
        name: "Swap Service Request",
        permissions: TypesOfPermissions
    },
    {
        name: "Staff Meetings",
        permissions: TypesOfPermissions
    },
    {
        name: "User Management",
        permissions: TypesOfPermissions
    },
    {
        name: "Price List",
        permissions: TypesOfPermissions
    },
    {
        name: "Adjust Hours",
        permissions: TypesOfPermissions
    },
    {
        name: "Payment Processing",
        permissions: TypesOfPermissions
    },
    {
        name: "Invoice Ledger",
        permissions: TypesOfPermissions
    },
];

const FeatureGroupManagementModal = ({ modal, setModal }) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [featureGroupName, setFeatureGroupName] = useState(null);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');

    const [FeatureGroupManagementData, loading_users] = GetAllFeatureGroupManagement();
    const [createFeatureGroup, loading] = CreateFeatureGroup();
    const [createModulePermission, moduleLoading] = CreateModulePermission();

    const initialValues = {
        name: null,
        description: null,
        module: Permissions,
        status: true
    }

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {

        try {
            if (featureGroupName.includes(values.name)) {
                setSnackSeverity('error')
                setSnackMessage('Feature Group Name already exists. Please choose a unique name');
                setSnackOpen(true);
            }
            else {
                const featureGroupManagementPayload = {
                    name: values.name,
                    description: values.description,
                    status: values.status
                }

                const result = await createFeatureGroup({
                    variables: featureGroupManagementPayload
                });

                const modulesPermissionPayload = values.module.map(value => {
                    return (
                        {
                            feature_group_management_id: result.data.createFeatureGroupManagement.data.id,
                            module_name: value.name,
                            //TODO
                            read: value.permissions[0].read,
                            write: value.permissions[1].write,
                            archive: value.permissions[2].archive
                        }
                    )
                });

                modulesPermissionPayload.map(payloadData => (
                    createModulePermission({
                        variables: payloadData
                    })
                ));
                setSnackSeverity('success')
                setSnackMessage('Created Successfully')
                setSnackOpen(true);
                resetForm();
                setModal(false);

            }
        }
        catch (e) {
            console.log(e, 'error')
        }
    }

    useEffect(() => {
        if (!loading_users) {
            setFeatureGroupName(FeatureGroupManagementData?.featureGroupManagements?.data?.map(val => (val.attributes.name)))
        }
    }, [loading_users])

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={FeatureGroupManagementSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                    children={({ errors, getFieldProps, setFieldValue, values }) => (
                        <Form>

                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                maxWidth: "calc(70%)",
                                maxHeight: "calc(90%)",
                                minWidth: "calc(60%)",
                                boxShadow: 12,
                                borderRadius: 1,
                                p: 4,
                                overflowY: "auto"
                            }}>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize", mb: 2 }}>
                                        Feature Group Management
                                    </Typography>
                                </Box>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        placeholder='Enter the name'
                                        fullWidth
                                        {...getFieldProps('name')}
                                        error={errors?.name ? true : false}
                                    />
                                    {errors?.name && (
                                        <CustomErrorText errorMessage={errors?.name} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="description"
                                        label="Description"
                                        variant="outlined"
                                        placeholder='Enter the description'
                                        fullWidth
                                        {...getFieldProps('description')}
                                        error={errors?.description ? true : false}
                                    />
                                    {errors?.description && (
                                        <CustomErrorText errorMessage={errors?.description} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 3,
                                            backgroundColor: "#fff3cd",
                                            border: "1px solid #ffeeba",
                                            borderRadius: 2,
                                            maxWidth: 600,
                                            margin: "auto",
                                            mt: 5,
                                            mb: 5
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#856404",
                                                mb: 1,
                                            }}
                                        >
                                            Notice
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: "#856404",
                                            }}
                                        >
                                            By default, all permissions are set
                                            to <strong>'false'</strong>. Please update the settings to enable
                                            specific permissions.
                                        </Typography>
                                    </Paper>
                                    <ModulesAccordion setFieldValue={setFieldValue} values={values} />
                                </Grid>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": {
                                                    border: "2px solid #6ECA35",
                                                }
                                            }}
                                            type='submit'
                                        >
                                            {(!loading && !moduleLoading) ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                        </Button>
                                        <Button
                                            onClick={() => setModal(false)}
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35", }
                                            }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )}
                />
            </Modal>
        </>
    )
}

export default FeatureGroupManagementModal
