import { Box, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { edit_information } from "../../../middleware/reducers/service_request";
import { OrganizationList, PriceLists } from "../../../services/admin_service";
import moment from "moment";
import { GetChargeBandDetails } from "../../../services/finance_service";

export const Information = ({ screenWidth }) => {
    const [orgnizations, loading_org] = OrganizationList();
    const _client_info = useSelector((state) => state._service_request.information);
    const _client_edit_info = useSelector((state) => state._service_request.editInformation);
    const [priceLists, loading] = PriceLists();
    const [getChargeBands, loading_band, error_band] = GetChargeBandDetails();

    const dispatch = useDispatch();

    const [information, setInformation] = useState([
        {
            title: "First Name",
            value: "",
            placeholder: "",
            width: screenWidth,
            type: "text",
            required: true,
            disabled: true,
        },
        {
            title: "Last Name",
            value: "",
            placeholder: "",
            width: screenWidth,
            type: "text",
            required: true,
            disabled: true,
        },
        {
            title: "Date of Birth",
            value: moment().format("YYYY-MM-DD"),
            type: "date",
            width: screenWidth,
            required: true,
            disabled: true,
        },
        {
            title: "Gender",
            value: "Male",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Male",
                    selected: true
                },
                {
                    title: "Female",
                    selected: false
                },
                {
                    title: "Others",
                    selected: false
                }
            ],
            disabled: true,
        },
        {
            title: "Funding Source",
            value: "Self Funded",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Self Funded",
                    selected: true
                },
                {
                    title: "NDIS Funded",
                    selected: false
                },
                {
                    title: "Organization Funded",
                    selected: false
                },

            ],
            disabled: false,
        },
        {
            title: "Charge Band",
            value: {
                title: "Please select charge band",
                id: "DF1"
            },
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Please select charge band",
                    id: "DF1"
                }
            ]
        },
        // },
        {
            title: "Billable",
            value: "Yes",
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                },
            ]
        },
    ]);

    const [selectOrganization, setSelectOrganization] = useState({
        title: "Organization List",
        value: {
            title: "Please Select Organization",
            id: "D1"
        },
        options: [
            {
                title: "Please Select Organization",
                id: "D1"
            }
        ]
    });

    useEffect(() => {
        // if (priceLists) {
        //     console.log("priceLists", priceLists);
        //     const copy = [...information]
        //     const reStruct = [];
        //     for (const key in priceLists) {
        //         reStruct.push({
        //             title: priceLists[key]?.attributes?.service_name,
        //             id: priceLists[key]?.id
        //         })
        //     }
        //     copy[5].options = reStruct;
        //     setInformation(copy)
        // }
        if (orgnizations?.length) {
            const copy = { ...selectOrganization }
            for (const key in orgnizations) {
                const data = orgnizations[key]?.attributes;
                const find = copy.options.find((e) => e?.id === orgnizations[key]?.id)
                if (!find) {
                    const obj = {
                        title: data?.company_name,
                        id: orgnizations[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            // copy.value = {
            //     title: orgnizations[0]?.attributes?.company_name,
            //     id: orgnizations[0]?.id
            // }
            setSelectOrganization(copy)
        }
    }, [priceLists, orgnizations]);

    const getFilterChargeBands = async () => {
        try {
            const response = await getChargeBands();
            if (response?.data) {
                const data = response.data.chargeBandDetails.data;

                const copy = [...information]
                const reStruct = [{
                    title: "Please select charge band",
                    id: "DF1"
                }];
                for (const key in data) {
                    reStruct.push({
                        title: data[key]?.attributes?.charge_band_name,
                        id: data[key]?.id
                    })
                }
                copy[5].options = reStruct;
                setInformation(copy)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        if (_client_info) {
            const dbData = _client_info;
            getFilterChargeBands()
            const copy = [...information];
            for (const key1 in copy) {
                for (const key in dbData.attributes) {
                    const property = key?.split("_").join(" ");
                    if (copy[key1].title.toLowerCase() === property) {
                        copy[key1].value = dbData.attributes[key]
                    }
                }
                for (const key in dbData.clientAnswerLovsReferences) {
                    const data = dbData.clientAnswerLovsReferences[key].attributes;
                    const property = data.input_name?.split("_").join(" ").toLowerCase();
                    if (copy[key1].title.toLowerCase() === property) {
                        copy[key1].value = data?.selected_option_value;
                    }
                }
            }
            setInformation(copy)
        }
    }, [_client_info]);

    useEffect(() => {
        if (information) {
            const copy = [...information];
            // const findService = priceLists?.find((e) => e?.attributes?.service_name === copy[5].value)
            let obj = {
                first_name: copy[0].value,
                last_name: copy[1].value,
                date_of_birth: copy[2].value,
                gender: copy[3].value,
                funding_source: copy[4].value,
                service_type: copy[5].value,
                // remove_plan_managers: copy[6].value,
                billable: copy[6].value,
                // organization: _client_info?.orgaizations,
                organization_list: selectOrganization.value?.id !== "DF1" ? selectOrganization.value : "",
                pay_travel: "Yes",
            }

            dispatch(edit_information(obj));
        }
    }, [information, selectOrganization])

    useEffect(() => {
        if (_client_edit_info) {
            const copy = [...information];
            for (const key in _client_edit_info) {
                for (const data of copy) {
                    if (data?.title === "Charge Band" && data?.title?.toLowerCase() === key?.split("_").join(" ") && _client_edit_info[key]) {
                        data.value = _client_edit_info[key]
                    }
                    if (data?.title === "Funding Source" && data?.title?.toLowerCase() === key?.split("_").join(" ") && _client_edit_info[key]) {
                        data.value = _client_edit_info[key]
                    }
                }
                if (key === "organization_list" && _client_edit_info[key]) {
                    selectOrganization.value = _client_edit_info[key]
                }
            }
            setInformation(copy)
        }
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {information.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" || v.type === "date" ? (
                                <TextField
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    type={v.type}
                                    htmlFor={v.title}
                                    value={v.value}
                                    disabled={v.disabled}
                                    sx={{ m: 1, width: v.width }}
                                    onChange={(e) => {
                                        const copy = [...information]
                                        copy[i].value = e.target.value;
                                        setInformation(copy);
                                    }}
                                />
                            ) : null}

                            {v.type === "drop_down" ? (
                                <div key={v.title}>
                                    <TextField
                                        select={true}
                                        label={v.title}
                                        htmlFor={v.title}
                                        required={v.required}
                                        sx={{ m: 1, width: v.width }}
                                        disabled={v.disabled}
                                        value={v.title.includes("Charge Band") ? v.value?.title : v.value}
                                        onChange={(e) => {
                                            const copy = [...information];
                                            if (v.title.includes("Charge Band")) {
                                                const find = copy[i]?.options?.find((f) => f.title === e.target.value)
                                                if (find.id !== "DF1") {
                                                    copy[i].value = find;
                                                }
                                            } else {
                                                copy[i].value = e.target.value;
                                            }
                                            setInformation(copy);
                                        }}
                                    >
                                        {v?.options?.map((nV, nI) => (
                                            <MenuItem key={nI} value={nV.title} >
                                                {nV.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {v.value === "Organization Funded" ? (
                                        <>
                                            {/* render organizations */}
                                            <TextField
                                                select={true}
                                                label={selectOrganization.title}
                                                sx={{ m: 1, width: screenWidth, background: "#fff", }}
                                                value={selectOrganization.value.title}
                                                onChange={(e) => {
                                                    const copy = { ...selectOrganization };
                                                    const find = copy?.options?.find((f) => f.title === e.target.value)
                                                    if (find.id !== "D1") {
                                                        copy.value = find;
                                                        setSelectOrganization(copy);
                                                    }
                                                }}
                                            >
                                                {selectOrganization.options?.map((value) => (
                                                    <MenuItem key={value?.title} value={value?.title}>
                                                        <Typography textAlign="left">{value?.title}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    )
                })}
            </Box>
        </>
    )
}
