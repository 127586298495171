import * as Yup from 'yup'

export const IncidentFormSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    gender: Yup.string().required('Gender is required'),
    name: Yup.string().required('Name is required'),
    position: Yup.string().required('Position is required'),
    location_of_incident: Yup.string().required('Location of incident is required'),
    date_and_time: Yup.string().required('Date and Time is required')
});
