import { Autocomplete, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Modal, Slide, Stack, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomErrorText from '../../../components/custom-error-text'
import CustomSnackBar from '../../../components/custom_snack_bar'
import { FeaturClientGroupManagementSchema } from './schema'
import { ClientDetailList } from '../../../services/client_service'
import { CreateClientGroup, GetAllClientGroupManagement } from '../../../services/admin_service'

const initialValues = {
    name: null,
    description: null,
    client_detail_ids: [],
    active: true
}

const ClientGroupManagementModal = ({ modal, setModal }) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [usersList, setUsersList] = useState(null);
    const [clientGroupName, setClientGroupName] = useState(null);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');

    const [lisOfClients] = ClientDetailList();

    const [createClientGroup, clientLoading] = CreateClientGroup();
    const [ClientGroupManagementData] = GetAllClientGroupManagement();

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleCreate = async (values, resetForm) => {
        try {
            if (clientGroupName.includes(values.name)) {
                setSnackSeverity('error')
                setSnackMessage('Client Group Name already exists. Please choose a unique name');
                setSnackOpen(true);
            }
            else {
                const result = await createClientGroup({
                    variables: values
                });
                if (result) {
                    setSnackSeverity('success')
                    setSnackMessage('Created Successfully')
                    setSnackOpen(true);
                    resetForm();
                    setModal(false)
                }
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
    }

    useEffect(() => {
        if (ClientGroupManagementData) {
            setClientGroupName(ClientGroupManagementData?.clientGroupManagements?.data?.map(val => (val.attributes.name)))
        }
    }, [ClientGroupManagementData])

    useEffect(() => {
        setUsersList(
            lisOfClients?.map(val => {
                return ({
                    title: `${val.attributes.first_name} ${val.attributes.last_name}`,
                    value: val.id
                })
            })
        )
    }, [lisOfClients]);

    return (
        <>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={FeaturClientGroupManagementSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => handleCreate(values, resetForm)}
                    children={({ errors, getFieldProps, setFieldValue }) => (
                        <Form>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                maxWidth: "calc(70%)",
                                maxHeight: "calc(90%)",
                                minWidth: "calc(60%)",
                                boxShadow: 12,
                                borderRadius: 1,
                                p: 4,
                                overflowY: "auto"
                            }}>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize", mb: 2 }}>
                                        Client Group Management
                                    </Typography>
                                </Box>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        placeholder='Enter the name'
                                        fullWidth
                                        {...getFieldProps('name')}
                                        error={errors?.name ? true : false}
                                    />
                                    {errors?.name && (
                                        <CustomErrorText errorMessage={errors?.name} />
                                    )}
                                </Grid>
                                <Grid sx={{ mb: 3 }}>
                                    <TextField
                                        id="description"
                                        label="Description"
                                        variant="outlined"
                                        placeholder='Enter the description'

                                        fullWidth
                                        {...getFieldProps('description')}
                                        error={errors?.description ? true : false}
                                    />
                                    {errors?.description && (
                                        <CustomErrorText errorMessage={errors?.description} />
                                    )}
                                </Grid>
                                {usersList &&
                                    <Grid sx={{ mb: 3 }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                multiple
                                                fullWidth
                                                id="client-detail-id-autocomplete"
                                                options={usersList}
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    setFieldValue("client_detail_ids", newValue ? newValue.map(item => item.value) : []);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Search Clients`}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />

                                        </FormControl>
                                        {errors?.client_detail_ids && (
                                            <CustomErrorText errorMessage={errors?.client_detail_ids} />
                                        )}
                                    </Grid>
                                }
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": {
                                                    border: "2px solid #6ECA35",
                                                }
                                            }}
                                            type='submit'
                                        >
                                            {!clientLoading ? 'Create' : <CircularProgress color="secondary" size={26} />}
                                        </Button>
                                        <Button
                                            onClick={() => setModal(false)}
                                            variant="outlined"
                                            sx={{
                                                bgcolor: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                textTransform: "capitalize",
                                                color: "#000000",
                                                border: "2px solid #6ECA35",
                                                ":hover": { border: "2px solid #6ECA35", }
                                            }}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Form>
                    )}
                />
            </Modal>
        </>
    )
}

export default ClientGroupManagementModal
